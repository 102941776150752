import constants from './actionTypes'
import { call, takeLatest, put, all, select } from 'redux-saga/effects'
import {
  getLicenceUsersApi,
  getAllLicenceUsersApi,
  adminBlockUserApi,
  adminUpdateUserEmailApi,
  adminActivateUserApi,
  adminUpdateUserUsernameApi,
  adminUpdateUserRoleApi,
  adminCreateUserApi,
  adminUpdateUserDataApi,
} from '../../services/ApiCalls'
import {
  getLicenceUsersSuccess,
  getAllLicenceUsersSuccess,
  getAllBackendLicenceUsersSuccess,
  getAllFrontEndLicenceUsersSuccess,
  setPaginationBackendUsers,
  setPaginationFrontEndUsers,
  setPaginationAllLicenceUsers,
  adminActivateUserSuccess,
  adminBlockUserSuccess,
  adminCreateUserFail,
  // getLicenceUsers,
  getAllBackendLicenceUsers,
  setNewUser,
  getAllLicenceUsers,
} from './actions'
import {
  setUseNewUser,
  closeModal,
  setBackendUsersMgmStep,
} from '../layout/actions'
import { NotificationManager } from 'react-notifications'

function* getLicenceUsersSaga(action) {
  try {
    const licenceUsers = yield call(getLicenceUsersApi, action.payload)
    yield put(getLicenceUsersSuccess(licenceUsers))
  } catch (e) {
    console.log('error', e)
  }
}

function* getAllLicenceUsersSaga(action) {
  try {
    const allLicenceUsers = yield call(getAllLicenceUsersApi, action.payload)

    const setPaginationObj = {
      currentPage: parseInt(
        allLicenceUsers.headers['x-pagination-current-page']
      ),
      pageCount: parseInt(allLicenceUsers.headers['x-pagination-page-count']),
      perPage: parseInt(allLicenceUsers.headers['x-pagination-per-page']),
      totlCount: parseInt(allLicenceUsers.headers['x-pagination-total-count']),
    }

    yield put(setPaginationAllLicenceUsers(setPaginationObj))
    yield put(getAllLicenceUsersSuccess(allLicenceUsers.data))
  } catch (e) {
    console.log('error', e)
  }
}
// SAGA FOR GETTING ALL LICENCE USERS FOR ADMIN USERS MGM
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_ALL_BACKEND_LICENCE_USERS
// PAYLOAD : FILTER DATA FOR USERS
function* getAllBackendLicenceUsersSaga(action) {
  try {
    // console.log("dosao do getAllBackendLicenceUsersSaga")
    const licenceUsers = yield call(getAllLicenceUsersApi, action.payload)
    const setPaginationObj = {
      currentPage: parseInt(licenceUsers.headers['x-pagination-current-page']),
      pageCount: parseInt(licenceUsers.headers['x-pagination-page-count']),
      perPage: parseInt(licenceUsers.headers['x-pagination-per-page']),
      totlCount: parseInt(licenceUsers.headers['x-pagination-total-count']),
    }
    yield put(setPaginationBackendUsers(setPaginationObj))
    // console.log("licenceUsers.data", licenceUsers.data);
    yield put(getAllBackendLicenceUsersSuccess(licenceUsers.data))
    // console.log("licenceUsers", licenceUsers);
  } catch (e) {
    console.log('error', e)
  }
}

// SAGA FOR GETTING ALL LICENCE USERS FOR ADMIN USERS MGM
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_ALL_FRONT_END_LICENCE_USERS
// PAYLOAD : FILTER DATA FOR USERS
function* getAllFrontEndLicenceUsers(action) {
  try {
    // console.log("dosao do getAllBackendLicenceUsersSaga")
    const licenceUsers = yield call(getAllLicenceUsersApi, action.payload)
    const setPaginationObj = {
      currentPage: parseInt(licenceUsers.headers['x-pagination-current-page']),
      pageCount: parseInt(licenceUsers.headers['x-pagination-page-count']),
      perPage: parseInt(licenceUsers.headers['x-pagination-per-page']),
      totlCount: parseInt(licenceUsers.headers['x-pagination-total-count']),
    }
    yield put(setPaginationFrontEndUsers(setPaginationObj))

    yield put(getAllFrontEndLicenceUsersSuccess(licenceUsers.data))
    // console.log("licenceUsers", licenceUsers);
  } catch (e) {
    console.log('error', e)
  }
}
// SAGA FOR BLOCKING USER BY ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_BLOCK_USER
// PAYLOAD : USER ID THAT NEEDS TO BE BLOCKED
function* adminBlockUserSaga(action) {
  try {
    const blockedUser = yield call(adminBlockUserApi, action.payload)
    // yield put(getAllLicenceUsersSuccess(licenceUsers));
    NotificationManager.success(blockedUser.message, '', 3000)
    yield put(adminBlockUserSuccess())
  } catch (e) {
    NotificationManager.error(e.response.data.message, '', 3000)
  }
}

// SAGA FOR UPDATING USER EMAIL BY ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_CHANGE_USER_EMAIL
// PAYLOAD : USER EMAIL THAT NEEDS TO BE UPDATED
function* adminUpdateUserEmailSaga(action) {
  try {
    const updateEmail = yield call(adminUpdateUserEmailApi, action.payload)
    // yield put(getAllLicenceUsersSuccess(licenceUsers));
    NotificationManager.success(updateEmail.message, '', 3000)

    // console.log("updateEmail", updateEmail);
  } catch (e) {
    console.log('error', e)
    NotificationManager.error(e.response.data.message, '', 3000)
  }
}

// SAGA FOR ACTIVATING USER BY ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_ACTIVATE_USER
// PAYLOAD : USER ID THAT NEEDS TO BE UPDATED
function* adminActivateUserSaga(action) {
  try {
    const updateEmail = yield call(adminActivateUserApi, action.payload)
    // yield put(getAllLicenceUsersSuccess(licenceUsers));
    NotificationManager.success(updateEmail.message, '', 3000)
    yield put(adminActivateUserSuccess())
    // console.log("updateEmail", updateEmail);
  } catch (e) {
    console.log('error', e)
    NotificationManager.error(e.response.data.message, '', 3000)
  }
}

// SAGA FOR UPDATING USER USERNAME BY ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_CHANGE_USER_USERNAME
// PAYLOAD : USER USERNAME THAT NEEDS TO BE UPDATED
function* adminUpdateUserUsernameSaga(action) {
  try {
    const updateUsername = yield call(
      adminUpdateUserUsernameApi,
      action.payload
    )
    NotificationManager.success(updateUsername.message, '', 3000)

    // console.log("updateEmail", updateEmail);
  } catch (e) {
    console.log('error', e)
    NotificationManager.error(e.response.data.message, '', 3000)
  }
}

// SAGA FOR UPDATING USER ROLE BY ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_UPDATE_USER_ROLE
// PAYLOAD : USER USERNAME THAT NEEDS TO BE UPDATED
function* adminUpdateUserRoleSaga(action) {
  try {
    const updateRole = yield call(adminUpdateUserRoleApi, action.payload)
    NotificationManager.success(updateRole.message, '', 3000)

    // console.log("updateEmail", updateEmail);
  } catch (e) {
    console.log('error', e)
    NotificationManager.error(e.response.data.message, '', 3000)
  }
}

// SAGA FOR HANDLING USER CREATION BY THE ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_CREATE_USER
// PAYLOAD : USER DATA THAT ADMIN ENTERD IN THE FORM
function* adminCreateUserSaga(action) {
  try {
    const state = yield select()
    // console.log("state.layout", state.layout);
    if (state.layout.useNewUser) {
      const userPayload = { ...action.payload, return_model: 1 }
      const newUser = yield call(adminCreateUserApi, userPayload)

      yield put(setUseNewUser(false))
      yield put(closeModal('userMgmModal'))
      yield put(setNewUser(newUser))
      yield put(setBackendUsersMgmStep('previewUsers'))
      // console.log("newUser", newUser);
    } else {
      const newUser = yield call(adminCreateUserApi, action.payload)
      const payload = {
        // user_type: "backend-users",
        page: 1,
      }
      // yield put(getAllBackendLicenceUsers(payload));
      yield put(getAllLicenceUsers(payload))
      yield put(setUseNewUser(false))

      // console.log("updateEmail", updateEmail);
      NotificationManager.success(newUser.message, '', 3000)
    }
  } catch (e) {
    console.log('error', e)
    NotificationManager.error(e.response.data.message, '', 3000)
    yield put(adminCreateUserFail())
    yield put(setBackendUsersMgmStep('previewUsers'))
  }
}

// SAGA FOR HANDLING USER DATA UPDATE BY THE ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_UPDATE_USER_DATA
// PAYLOAD : USER DATA THAT ADMIN ENTERD IN THE FORM
function* adminUpdataUserDataSaga(action) {
  try {
    const updateUserData = yield call(adminUpdateUserDataApi, action.payload)
    // console.log("updateUserData", updateUserData);
    if (action.payload.dontFetch) {
    } else {
      const payload = {
        // user_type: "backend-users",
        page: 1,
      }
      // yield put(getAllBackendLicenceUsers(payload));
      yield put(getAllLicenceUsers(payload))
    }
    NotificationManager.success(updateUserData.message, '', 3000)

    // console.log("updateEmail", updateEmail);
  } catch (e) {
    console.log('error', e)
    NotificationManager.error(e.response.data.message, '', 3000)
  }
}

function* usersSaga() {
  yield all([
    takeLatest(constants.GET_LICENCE_USERS, getLicenceUsersSaga),
    takeLatest(constants.GET_ALL_LICENCE_USERS, getAllLicenceUsersSaga),
    takeLatest(
      constants.GET_ALL_BACKEND_LICENCE_USERS,
      getAllBackendLicenceUsersSaga
    ),
    takeLatest(
      constants.GET_ALL_FRONT_END_LICENCE_USERS,
      getAllFrontEndLicenceUsers
    ),
    takeLatest(constants.ADMIN_BLOCK_USER, adminBlockUserSaga),
    takeLatest(constants.ADMIN_CHANGE_USER_EMAIL, adminUpdateUserEmailSaga),
    takeLatest(constants.ADMIN_ACTIVATE_USER, adminActivateUserSaga),
    takeLatest(constants.ADMIN_UPDATE_USER_ROLE, adminUpdateUserRoleSaga),
    takeLatest(constants.ADMIN_CREATE_USER, adminCreateUserSaga),
    takeLatest(constants.ADMIN_UPDATE_USER_DATA, adminUpdataUserDataSaga),
    takeLatest(
      constants.ADMIN_CHANGE_USER_USERNAME,
      adminUpdateUserUsernameSaga
    ),
  ])
}

export default usersSaga
