const FETCH_CONTENT_COMMENTS = 'FETCH_CONTENT_COMMENTS'
const FETCH_CONTENT_COMMENTS_SUCCESS = 'FETCH_CONTENT_COMMENTS_SUCCESS'
const FETCH_CONTENT_COMMENTS_FAIL = 'FETCH_CONTENT_COMMENTS_FAIL'
const POST_CONTENT_COMMENT = 'POST_CONTENT_COMMENT'
const POST_CONTENT_COMMENT_SUCCESS = 'POST_CONTENT_COMMENT_SUCCESS'
const POST_CONTENT_COMMENT_FAIL = 'POST_CONTENT_COMMENT_FAIL'
const SEND_MESSAGE = 'SEND_MESSAGE'
const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
const SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL'
const GET_COMMENTS_ON_MODERATION = 'GET_COMMENTS_ON_MODERATION'
const GET_COMMENTS_ON_MODERATION_SUCCESS = 'GET_COMMENTS_ON_MODERATION_SUCCESS'
const GET_COMMENTS_ON_MODERATION_FAIL = 'GET_COMMENTS_ON_MODERATION_FAIL'
const SET_COMMENTS_ON_MODERATION_PAGINATION =
  'SET_COMMENTS_ON_MODERATION_PAGINATION'
const APPROVE_COMMENT_MODERATION = 'APPROVE_COMMENT_MODERATION'
const APPROVE_COMMENT_MODERATION_SUCCESS = 'APPROVE_COMMENT_MODERATION_SUCCESS'
const APPROVE_COMMENT_MODERATION_FAIL = 'APPROVE_COMMENT_MODERATION_FAIL'

const CHANGE_CHILDS_COMMENTS_STATUS = 'CHANGE_CHILDS_COMMENTS_STATUS'
const CHANGE_CHILDS_COMMENTS_STATUS_SUCCESS =
  'CHANGE_CHILDS_COMMENTS_STATUS_SUCCESS'
const CHANGE_CHILDS_COMMENTS_STATUS_FAIL = 'CHANGE_CHILDS_COMMENTS_STATUS_FAIL'

const ADMIN_DISAPPROVE_COMMENT_MODERATION =
  'ADMIN_DISAPPROVE_COMMENT_MODERATION'
const ADMIN_DISAPPROVE_COMMENT_MODERATION_SUCCESS =
  'ADMIN_DISAPPROVE_COMMENT_MODERATION_SUCCESS'
const ADMIN_DISAPPROVE_COMMENT_MODERATION_FAIL =
  'ADMIN_DISAPPROVE_COMMENT_MODERATION_FAIL'
const REQUEST_FOR_EDIT_COMMENT_MODERATION =
  'REQUEST_FOR_EDIT_COMMENT_MODERATION'
const REQUEST_FOR_EDIT_COMMENT_MODERATION_SUCCESS =
  'REQUEST_FOR_EDIT_COMMENT_MODERATION_SUCCESS'
const REQUEST_FOR_EDIT_COMMENT_MODERATION_FAIL =
  'REQUEST_FOR_EDIT_COMMENT_MODERATION_FAIL'
const ACTIVATE_COMMENT_LOADER = 'ACTIVATE_COMMENT_LOADER'
const CLEAR_UNREAD_MESSAGES = 'CLEAR_UNREAD_MESSAGES'

export default {
  FETCH_CONTENT_COMMENTS,
  FETCH_CONTENT_COMMENTS_SUCCESS,
  FETCH_CONTENT_COMMENTS_FAIL,
  POST_CONTENT_COMMENT,
  POST_CONTENT_COMMENT_SUCCESS,
  POST_CONTENT_COMMENT_FAIL,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAIL,
  GET_COMMENTS_ON_MODERATION,
  GET_COMMENTS_ON_MODERATION_SUCCESS,
  GET_COMMENTS_ON_MODERATION_FAIL,
  SET_COMMENTS_ON_MODERATION_PAGINATION,
  APPROVE_COMMENT_MODERATION,
  APPROVE_COMMENT_MODERATION_SUCCESS,
  APPROVE_COMMENT_MODERATION_FAIL,

  CHANGE_CHILDS_COMMENTS_STATUS,
  CHANGE_CHILDS_COMMENTS_STATUS_SUCCESS,
  CHANGE_CHILDS_COMMENTS_STATUS_FAIL,

  ADMIN_DISAPPROVE_COMMENT_MODERATION,
  ADMIN_DISAPPROVE_COMMENT_MODERATION_SUCCESS,
  ADMIN_DISAPPROVE_COMMENT_MODERATION_FAIL,
  REQUEST_FOR_EDIT_COMMENT_MODERATION,
  REQUEST_FOR_EDIT_COMMENT_MODERATION_SUCCESS,
  REQUEST_FOR_EDIT_COMMENT_MODERATION_FAIL,
  ACTIVATE_COMMENT_LOADER,
  CLEAR_UNREAD_MESSAGES,
}
