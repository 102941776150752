import constants from './actionTypes'
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import {
  getApprovedContentComments,
  postContentComment,
  getLicenceCommentsOnModerationApi,
  approveCommentModerationApi,
  disapproveCommentModerationApi,
  changeChildsCommentsStatusApi,
  sendMessage,
} from '../../services/ApiCalls'
import {
  fetchContentCommentsSuccess,
  fetchContentComments,
  getLicenceCommentsOnModerationSuccess,
  setPaginationForCommentsOnModeration,
  getLicenceCommentsOnModeration,
  changeChildsCommetsStatusSuccess,
  sendMessageSuccess,
  sendMessageFail,
} from './actions'
import { getModerationNumbers } from '../layout/actions'
import { NotificationManager } from 'react-notifications'
import Swal from 'sweetalert2'

function* fetchContentCommentsSaga(action) {
  try {
    const { user_id } = action.payload
    const commentsList = yield call(getApprovedContentComments, {
      user_id,
    })
    yield put(fetchContentCommentsSuccess(commentsList.data))
  } catch (e) {
    console.log(e)
  }
}

function* postContentContentSaga(action) {
  try {
    console.log('AKCIJAAA', action)
    // return
    // console.log(action);
    const comment = yield call(postContentComment, action.payload)
    // yield put(fetchContentComments(action.payload.content_id))
    NotificationManager.success(comment.message, '', 3000)
  } catch (e) {
    NotificationManager.error(e.response.data.message, '', 3000)
  }
}

function* sendMessageSaga(action) {
  const state = yield select()
  try {
    console.log(
      'Payload iz akcijeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee:',
      action.payload
    )
    const comment = yield call(sendMessage, action.payload)
    // yield put(sendMessageSuccess(comment))
    // yield put(fetchContentComments(action.payload))

    // NotificationManager.success(comment.message, '', 3000)

    Swal.fire({
      title: comment.message,
      icon: 'success', // 'success', 'error', 'warning', 'info', itd.
      confirmButtonText: 'OK',
      timer: 4000,
      timerProgressBar: true,
    })
  } catch (e) {
    yield put(sendMessageFail(e))
    console.log('eeeerorrrr iz sage', e)
    if (e.response && e.response.data && e.response.data.message) {
      Swal.fire({
        title: e.response.data.message,
        icon: 'error', // 'success', 'error', 'warning', 'info', itd.
        confirmButtonText: 'OK',
        timer: 4000,
        timerProgressBar: true,
      })
      // NotificationManager.error(e.response.data.message, '', 3000)
    } else {
      Swal.fire({
        title: state.translation.messages.messageContentError[3],
        icon: 'error', // 'success', 'error', 'warning', 'info', itd.
        confirmButtonText: 'OK',
        timer: 4000,
        timerProgressBar: true,
      })
      // NotificationManager.error('Failed to send message', '', 3000)
    }
  }
}

// SAGA FOR GETTING LICENCE COMMENTS ON MODERATION
// RECIVES TYPE FROM ACTION
// TYPE : constants.GET_COMMENTS_ON_MODERATION
function* getCommentsOnModerationSaga(action) {
  try {
    // console.log(action);
    const commentsOnModeration = yield call(
      getLicenceCommentsOnModerationApi,
      action.payload
    )
    const setPaginationObj = {
      currentPage: parseInt(
        commentsOnModeration.headers['x-pagination-current-page']
      ),
      pageCount: parseInt(
        commentsOnModeration.headers['x-pagination-page-count']
      ),
      perPage: parseInt(commentsOnModeration.headers['x-pagination-per-page']),
      totlCount: parseInt(
        commentsOnModeration.headers['x-pagination-total-count']
      ),
    }
    yield put(setPaginationForCommentsOnModeration(setPaginationObj))
    yield put(getLicenceCommentsOnModerationSuccess(commentsOnModeration.data))
  } catch (e) {
    NotificationManager.error(e.response.data.message, '', 3000)
    Swal.fire({
      title: e.response.data.message,
      icon: 'error', // 'success', 'error', 'warning', 'info', itd.
      confirmButtonText: 'OK',
      timer: 4000,
      timerProgressBar: true,
    })
  }
}

// SAGA FOR APPROVING SINGLE COMMENT MODERATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.APPROVE_COMMENT_MODERATION
// PAYLOAD : COMMENT ID THAT NEEDS TO BE APPROVED
// function* approveCommentModerationSaga(action) {
//   try {
//     const approveComment = yield call(
//       approveCommentModerationApi,
//       action.payload
//     )
//     const payload = {
//       page: 1,
//     }
//     yield put(getModerationNumbers())
//     yield put(getLicenceCommentsOnModeration(payload))
//     NotificationManager.success(approveComment.message, '', 3000)
//   } catch (e) {
//     console.log(e.message)
//   }
// }

function* approveCommentModerationSaga(action) {
  try {
    const approveComment = yield call(
      approveCommentModerationApi,
      action.payload
    )
    const payload = {
      page: 1,
    }

    // NotificationManager.success(approveComment.message, '', 3000)
    Swal.fire({
      title: approveComment.message,
      icon: 'success', // 'success', 'error', 'warning', 'info', itd.
      confirmButtonText: 'OK',
      timer: 4000,
      timerProgressBar: true,
    })
  } catch (e) {
    console.log(e.message)
  }
}

function* changeChildsCommetsStatusSaga(action) {
  const state = select()
  try {
    const changeChildsStatusToSeen = yield call(
      changeChildsCommentsStatusApi,
      action.payload
    )

    // NotificationManager.success('poruke pregledane iz sage', '', 3000)
    Swal.fire({
      title: state.translation.messages.messageSeen[1],
      icon: 'success', // 'success', 'error', 'warning', 'info', itd.
      confirmButtonText: 'OK',
      timer: 4000,
      timerProgressBar: true,
    })
  } catch (e) {
    console.log(e.message)
  }
}

// SAGA FOR DISAPPROVING SINGLE COMMENT MODERATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_DISAPPROVE_COMMENT_MODERATION
// PAYLOAD : COMMENT ID THAT NEEDS TO BE DISAPPROVED
function* disapproveCommentModerationSaga(action) {
  try {
    // console.log("dosao do disapproveBlogModerationSaga");
    // // console.log("Dosao do adminUpdateBlogSaga", action.payload);
    // yield put(activateBlogsLoader());
    const disapproveComment = yield call(
      disapproveCommentModerationApi,
      action.payload
    )
    const payload = {
      page: 1,
    }
    yield put(getModerationNumbers())
    yield put(getLicenceCommentsOnModeration(payload))
    NotificationManager.success(disapproveComment.message, '', 3000)
  } catch (e) {
    console.log(e.message)
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* commentsSaga() {
  yield all([
    takeLatest(constants.FETCH_CONTENT_COMMENTS, fetchContentCommentsSaga),
    takeLatest(
      constants.CHANGE_CHILDS_COMMENTS_STATUS,
      changeChildsCommetsStatusSaga
    ),
    takeLatest(constants.POST_CONTENT_COMMENT, postContentContentSaga),
    takeLatest(constants.SEND_MESSAGE, sendMessageSaga),
    takeLatest(
      constants.GET_COMMENTS_ON_MODERATION,
      getCommentsOnModerationSaga
    ),
    takeLatest(
      constants.APPROVE_COMMENT_MODERATION,
      approveCommentModerationSaga
    ),

    takeLatest(
      constants.ADMIN_DISAPPROVE_COMMENT_MODERATION,
      disapproveCommentModerationSaga
    ),
  ])
}

// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default commentsSaga
