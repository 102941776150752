import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ClipLoader } from 'react-spinners';
export default class App extends React.Component {
  //other logic
  render() {
    // const { timeout = timeout } = this.props;
    // state = {timeout = timeout};
    return (
      <div
        // style={{
        //   display: "flex",
        //   height: "50vh",
        //   justifyContent: "center",
        //   alignItems: "center",
        // }}
      >
        {/* <Loader
          type="Oval"
          // color="#00BFFF"
          color="red"
          height={100}
          width={100}
          timeout={3000} //3 secs
          // timeout={timeout ? timeout : 3000} //3 secs
        /> */}
        <ClipLoader color="#CCAD75" />
      </div>
    );
  }
}
