import { all, takeLatest, call, put } from "redux-saga/effects";
import constants from "./actionTypes";
import {
  getSingleArticleApi,
  updateArticleApi,
  getArticlesApi,
  getContentByCategoryIdApi,
  getCategoryBySlugApi,
  getLicenceUserArticlesApi,
} from "../../services/ApiCalls";
import {
  getAuthUserArticlesSuccess,
  getSingleArticleSuccess,
  fetchArticlesSuccess,
  setPagination,
  fetchArticleListByCategorySlugSuccess,
  setPaginationForAuthUserArticles,
} from "./actions";

// SAGA FOR FETCHING ARTICLES
// RECIVES TYPE FROM ACTION
// TYPE : constants.FETCH_ARTICLES
function* fetchArticlesSaga(action) {
  try {
    const articles = yield call(getArticlesApi);
    const setPaginationObj = {
      currentPage: parseInt(articles.headers["x-pagination-current-page"]),
      pageCount: parseInt(articles.headers["x-pagination-page-count"]),
      perPage: parseInt(articles.headers["x-pagination-per-page"]),
      totlCount: parseInt(articles.headers["x-pagination-total-count"]),
    };
    // yield put(setPagination(setPaginationObj));
    yield put(setPaginationForAuthUserArticles(setPaginationObj));

    yield put(fetchArticlesSuccess(articles.data));
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR GETTING SINGLE ARTICLE DATA
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_SINGLE_ARTICLE
// PAYLOAD : ID OF THE ARTICLE FROM THE URL
function* singleArticleSaga(action) {
  try {
    const singleArticle = yield call(getSingleArticleApi, action.payload);
    yield put(getSingleArticleSuccess(singleArticle));
  } catch (e) {
    console.log(e.message, "erorr");
  }
}

// SAGA FOR GETTING LICENCE USER ARTICLES
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_LICENCE_USER_ARTICLES
// PAYLOAD : AUTH USER ID
function* getLicenceUserArticlesSaga(action) {
  try {
    const authUserArticles = yield call(
      getLicenceUserArticlesApi,
      action.payload
    );
    const setPaginationObj = {
      currentPage: parseInt(
        authUserArticles.headers["x-pagination-current-page"]
      ),
      pageCount: parseInt(authUserArticles.headers["x-pagination-page-count"]),
      perPage: parseInt(authUserArticles.headers["x-pagination-per-page"]),
      totlCount: parseInt(authUserArticles.headers["x-pagination-total-count"]),
    };
    yield put(setPaginationForAuthUserArticles(setPaginationObj));

    // console.log("authUserArticles", authUserArticles);
    yield put(getAuthUserArticlesSuccess(authUserArticles.data));
  } catch (e) {
    console.log(e.message, "erorr");
  }
}

// SAGA FOR UPDATING ARTICLE
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.UPDATE_ARTICLE
// PAYLOAD : ARTICLE DATA THAT USER EDITED FROM THE ARTICLE EDIT FORM;
function* updateArticleSaga(action) {
  try {
    yield call(updateArticleApi, action.payload);

    // yield put(getSingleArticleSuccess(singleArticle));
  } catch (e) {
    console.log(e.message, "erorr");
  }
}

function* fetchArticleListByCategorySlugSaga(action) {
  try {
    const category = yield call(getCategoryBySlugApi, action.payload);
    const payload2 = {
      categoryId: category.data[0].id,
      page: action.payload.page,
    };
    const articleList = yield call(getContentByCategoryIdApi, payload2);
    const data = [category, articleList];
    const setPaginationObj = {
      currentPage: parseInt(articleList.headers["x-pagination-current-page"]),
      pageCount: parseInt(articleList.headers["x-pagination-page-count"]),
      perPage: parseInt(articleList.headers["x-pagination-per-page"]),
      totlCount: parseInt(articleList.headers["x-pagination-total-count"]),
    };
    yield put(fetchArticleListByCategorySlugSuccess(data));
    yield put(setPagination(setPaginationObj));
  } catch (e) {
    console.log(e);
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* articleSaga() {
  yield all([
    takeLatest(constants.GET_SINGLE_ARTICLE, singleArticleSaga),
    takeLatest(constants.UPDATE_ARTICLE, updateArticleSaga),
    takeLatest(constants.FETCH_ARTICLES, fetchArticlesSaga),
    takeLatest(constants.GET_LICENCE_USER_ARTICLES, getLicenceUserArticlesSaga),
    takeLatest(
      constants.FETCH_ARTICLE_LIST_BY_CATEGORY_SLUG,
      fetchArticleListByCategorySlugSaga
    ),
  ]);
}
// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default articleSaga;
