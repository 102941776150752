import constants from "./actionTypes";

const initState = {
  blogs: [],
  blogCategory: [],
  error: "",
  loading: false,
  pagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
  setSelectedContentTypeCatBlogs: [],
  authUserBlogs: [],
  authUserBlogsPagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.ACTIVATE_BLOGS_LOADER:
      return {
        ...state,
        loading: true,
      };
    case constants.SET_PAGINATION_FOR_AUTH_USER_BLOGS:
      return {
        ...state,
        authUserBlogsPagination: payload,
      };
    // TRIGGER BOLG FETCHING
    case constants.FETCH_BLOGS:
      return {
        ...state,
        loading: true,
      };
    // TRIGGER BOLG FETCHING
    case constants.FETCH_BLOGS_BY_AUTH_USER:
      return {
        ...state,
        loading: true,
      };
    // TRIGGER BOLG FETCHING
    case constants.FETCH_BLOGS_BY_AUTH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        authUserBlogs: payload,
      };
    // SET ERROR MESSAGE RECIVED FROM THE API
    case constants.FETCH_BLOGS_FAIL:
      return {
        ...state,
        error: payload,
      };
    // SET BLOGS TO BE THE PAYLOAD RECIVED FROM API
    case constants.FETCH_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: payload,
      };
    // SET PAGINATION TO BE THE PAYLOAD RECIVED FROM API
    case constants.SET_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };

    //  SET BLOG CONTENT TYPE ORDER TO BE THE PAYLOAD
    case constants.SET_SELECTED_CONTENT_TYPE_CATEGORIES_BLOGS:
      return {
        ...state,
        setSelectedContentTypeCatBlogs: payload,
      };
    case constants.FETCH_BLOG_LIST_BY_CATEGORY_SLUG:
      return {
        ...state,
        loading: true,
      };
    case constants.FETCH_BLOG_LIST_BY_CATEGORY_SLUG_SUCCESS:
      return {
        ...state,
        blogCategory: payload[0].data[0],
        blogs: payload[1].data,
        loading: false,
      };
    default:
      return state;
  }
};
