import constants from "./actionTypes";

// ACTION FOR GETTING UNSPLASH COLLECTIONS
// RECIVE PAGINATION OBJECT AS A PAYLOAD
export const getUnsplashCollections = (payload) => ({
  type: constants.GET_UNSPLASH_COLLECTIONS,
  payload,
});
// SUCCESS ACTION FOR GETTING UNSPLASH COLLECTIONS
// RECIVE UNSPLASH COLLECTIONS WITH PAGINAGION AS A PAYLOAD
export const getUnsplashCollectionsSuccess = (payload) => ({
  type: constants.GET_UNSPLASH_COLLECTIONS_SUCCESS,
  payload,
});
// FAIL ACTION FOR GETTING UNSPLASH COLLECTIONS
// RECIVE FAIL STATUS CODE AND ERROR AS A PAYLOAD
export const getUnsplashCollectionsFail = (payload) => ({
  type: constants.GET_UNSPLASH_COLLECTIONS_FALI,
  payload,
});

// ACTION FOR GETTING UNSPLASH COLLECTION PHOTOS
// RECIVE PAGINATION OBJECT AND COLLECTION ID AS A PAYLOAD
export const getUnsplashCollectionPhotos = (payload) => ({
  type: constants.GET_UNSPLASH_COLLECTION_PHOTOS,
  payload,
});
// SUCCESS ACTION FOR GETTING UNSPLASH COLLECTION PHOTOS
// RECIVE UNSPLASH COLLECTION PHOTOS WITH PAGINAGION AS A PAYLOAD
export const getUnsplashCollectionPhotosSuccess = (payload) => ({
  type: constants.GET_UNSPLASH_COLLECTION_PHOTOS_SUCCESS,
  payload,
});
// FAIL ACTION FOR GETTING UNSPLASH COLLECTION PHOTOS
// RECIVE FAIL STATUS CODE AND ERROR AS A PAYLOAD
export const getUnsplashCollectionPhotosFail = (payload) => ({
  type: constants.GET_UNSPLASH_COLLECTION_PHOTOS_FALI,
  payload,
});

// ACTION FOR GETTING UNSPLASH COLLECTION PHOTOS
// RECIVE PAGINATION OBJECT AND COLLECTION ID AS A PAYLOAD
export const getUnsplashSearchTermPhotos = (payload) => ({
  type: constants.GET_SEARCH_TERM_PHOTOS,
  payload,
});
// SUCCESS ACTION FOR GETTING UNSPLASH COLLECTION PHOTOS
// RECIVE UNSPLASH COLLECTION PHOTOS WITH PAGINAGION AS A PAYLOAD
export const getUnsplashSearchTermPhotosSuccess = (payload) => ({
  type: constants.GET_SEARCH_TERM_PHOTOS_SUCCESS,
  payload,
});
// FAIL ACTION FOR GETTING UNSPLASH COLLECTION PHOTOS
// RECIVE FAIL STATUS CODE AND ERROR AS A PAYLOAD
export const getUnsplashSearchTermPhotosFail = (payload) => ({
  type: constants.GET_SEARCH_TERM_PHOTOS_FALI,
  payload,
});

// ACTION FOR GETTING UNSPLASH PAGINATION LINK PHOTOS
// RECIVE PAGINATION LINK URL AS A PAYLOAD
export const getUnsplashPaginationLink = (payload) => ({
  type: constants.GET_UNSPLASH_PAGINATION_LINK,
  payload,
});
// SUCCESS ACTION FOR GETTING UNSPLASH PAGINATION LINK PHOTOS
// RECIVE UNSPLASH PHOTOS WITH PAGINAGION AS A PAYLOAD
export const getUnsplashPaginationLinkSuccess = (payload) => ({
  type: constants.GET_UNSPLASH_PAGINATION_LINK_SUCCESS,
  payload,
});
// FAIL ACTION FOR GETTING UNSPLASH PAGINATION LINK PHOTOS
// RECIVE FAIL STATUS CODE AND ERROR AS A PAYLOAD
export const getUnsplashPaginationLinkFail = (payload) => ({
  type: constants.GET_UNSPLASH_PAGINATION_LINK_FALI,
  payload,
});

// ACTION FOR RESETING STOCK PHOTOS ARRAY
export const resetPhotos = () => ({
  type: constants.RESET_PHOTOS,
});

// ACTION FOR SETTING SELECTED COLLECTION
export const setSelectedCollection = (payload) => ({
  type: constants.SET_SELECTED_COLLECTION,
  payload,
});
// // ACTION FOR SETTING PAGINATION OBJECT
// // RECIVE STATUS CODE AND ERROR AS A PAYLOAD
// export const getUnsplashSearchTermPhotosFail = (payload) => ({
//   type: constants.GET_SEARCH_TERM_PHOTOS_FALI,
//   payload,
// });
