import store from "../../store";
import { setPriceSum } from "./actions";

export const addToPriceSum = (state, payload) => {
  const newPrice = { ...state };
  // console.log("newPrice", newPrice);
  newPrice[`${payload.currencyName}`] =
    newPrice[`${payload.currencyName}`] + parseInt(payload.amout);
  return newPrice;
};

export const reducePriceSum = (state, payload) => {
  const newPrice = { ...state };
  newPrice[`${payload.currencyName}`] =
    newPrice[`${payload.currencyName}`] - payload.amout;
  return newPrice;
};

export const calculatePrice = (planners) => {
  let priceSumRSD = 0;
  let priceSumEUR = 0;
  // eslint-disable-next-line array-callback-return
  planners.map((plannerCat) => {
    if (plannerCat.plannerItems.length > 0) {
      // eslint-disable-next-line array-callback-return
      plannerCat.plannerItems.map((item) => {
        if (item.price && item.calculate_price === "yes") {
          // console.log("item.price", item.price);

          if (item.currency.id === 1) {
            priceSumRSD = parseInt(item.price) + priceSumRSD;
            // console.log(parseInt(item.price));
            // console.log(item.price);
          } else {
            priceSumEUR = priceSumEUR + parseInt(item.price);
          }
        }
      });
    }
  });
  if (priceSumRSD > 0) {
    const payload = {
      priceName: "RSD",
      priceAmount: priceSumRSD,
    };
    store.dispatch(setPriceSum(payload));
  }
  if (priceSumEUR > 0) {
    const payload = {
      priceName: "EUR",
      // priceName: "€",
      priceAmount: priceSumEUR,
    };
    store.dispatch(setPriceSum(payload));
  }
};
