const FETCH_HOME_PAGE_POSITION_ONE = "FETCH_HOME_PAGE_POSITION_ONE";
const FETCH_HOME_PAGE_POSITION_ONE_SUCCESS = "FETCH_HOME_PAGE_POSITION_ONE_SUCCESS";
const FETCH_HOME_PAGE_POSITION_ONE_FAIL = "FETCH_HOME_PAGE_POSITION_ONE_FAIL";
const FETCH_HOME_PAGE_PRODUCT_LIST = "FETCH_HOME_PAGE_PRODUCT_LIST";
const FETCH_HOME_PAGE_PRODUCT_LIST_SUCCESS = "FETCH_HOME_PAGE_PRODUCT_LIST_SUCCESS";
const FETCH_HOME_PAGE_PRODUCT_LIST_FAIL = "FETCH_HOME_PAGE_PRODUCT_LIST_FAIL";

export default {
  FETCH_HOME_PAGE_POSITION_ONE,
  FETCH_HOME_PAGE_POSITION_ONE_SUCCESS,
  FETCH_HOME_PAGE_POSITION_ONE_FAIL,
  FETCH_HOME_PAGE_PRODUCT_LIST,
  FETCH_HOME_PAGE_PRODUCT_LIST_SUCCESS,
  FETCH_HOME_PAGE_PRODUCT_LIST_FAIL
};
