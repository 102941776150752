import { all } from 'redux-saga/effects'
import authSaga from './auth/saga'
import blogsSaga from './blogs/saga'
import categorieSaga from './categorie/saga'
import articleSaga from './article/saga'
import productSaga from './products/saga'
import homePageSaga from './homePage/saga'
import layoutSaga from './layout/saga'
import plannerSaga from './plannerList/saga'
import singlePlannerSaga from './singlePlanner/saga'
import singleBlogSaga from './singleBlog/saga'
import singleArticleSaga from './singleArticle/saga'
import singleProductSaga from './singleProduct/saga'
import commentsSaga from './comments/saga'
import catalogSaga from './catalog/saga'
import tagsSaga from './tags/saga'
import tagRelatedContentSaga from './tagRelatedContent/saga'
import profileSaga from './profile/saga'
import searchSaga from './search/saga'
import stockPhotosSaga from './stockPhotos/saga'
import usersSaga from './users/saga'
import siteManagementSaga from './siteManagement/saga'
import widgetsSaga from './widgets/saga'
import biddingSaga from './bidding/saga'
import staticPage from './staticPage/saga'
import licenceSaga from './licence/saga'
import templatesSaga from './templates/saga'
import checkoutSaga from './checkout/saga'
import showNotificationSaga from './notificationMessages/saga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    blogsSaga(),
    categorieSaga(),
    articleSaga(),
    homePageSaga(),
    layoutSaga(),
    productSaga(),
    plannerSaga(),
    singlePlannerSaga(),
    singleBlogSaga(),
    singleArticleSaga(),
    singleProductSaga(),
    commentsSaga(),
    catalogSaga(),
    tagsSaga(),
    tagRelatedContentSaga(),
    profileSaga(),
    searchSaga(),
    stockPhotosSaga(),
    usersSaga(),
    siteManagementSaga(),
    widgetsSaga(),
    biddingSaga(),
    staticPage(),
    licenceSaga(),
    templatesSaga(),
    checkoutSaga(),
    showNotificationSaga(),
  ])
}
