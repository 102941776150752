import constants from "./actionTypes";

const initState = {
  collections: [],
  images: [],
  paginationLinks: {},
  paginationCollectionLinks: {},
  selectedCollection: "",
  loader: false,
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.GET_UNSPLASH_COLLECTIONS:
      return {
        ...state,
        loader: true,
      };
    case constants.SET_SELECTED_COLLECTION:
      return {
        ...state,
        selectedCollection: payload,
      };
    case constants.GET_UNSPLASH_COLLECTIONS_SUCCESS:
      return {
        ...state,
        loader: false,
        collections: [...state.collections, ...payload.collections],
        paginationCollectionLinks: payload.pagination,
      };
    case constants.GET_UNSPLASH_COLLECTION_PHOTOS:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_UNSPLASH_COLLECTION_PHOTOS_SUCCESS:
      return {
        ...state,
        loader: false,
        images: [...state.images, ...payload.collections],
        paginationLinks: payload.pagination,
      };
    case constants.GET_UNSPLASH_PAGINATION_LINK:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_SEARCH_TERM_PHOTOS:
      return {
        ...state,
        loader: true,
      };
    case constants.RESET_PHOTOS:
      return {
        ...state,
        images: [],
      };
    case constants.GET_SEARCH_TERM_PHOTOS_SUCCESS:
      return {
        ...state,
        // loader: false,
        images: [...state.images, ...payload.collections],
        paginationLinks: payload.pagination,
      };
    default:
      return {
        ...state,
      };
  }
};
