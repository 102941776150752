import constants from "./actionTypes";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getHomePagePositionOneApi,
  getHomePageProductListApi,
} from "../../services/ApiCalls";
import {
  fetchHomePagePositionOneSuccess,
  fetchHomePageProductListSuccess,
} from "./actions";

// SAGA FOR FETCHING BLOGS
// RECIVES TYPE FROM ACTION
// TYPE : constants.FETCH_BLOGS
// function* fetchBlogsSaga(action) {
//   try {
//     const blogs = yield call(getBlogsApi);
//     const setPaginationObj = {
//       currentPage: parseInt(blogs.headers["x-pagination-current-page"]),
//       pageCount: parseInt(blogs.headers["x-pagination-page-count"]),
//       perPage: parseInt(blogs.headers["x-pagination-per-page"]),
//       totlCount: parseInt(blogs.headers["x-pagination-total-count"]),
//     };
//     yield put(fetchBlogsSuccess(blogs.data));
//     yield put(setPagination(setPaginationObj));
//   } catch (e) {
//     console.log(e.message);
//   }
// }

function* fetchHomePagePositionOneSaga(action) {
  try {
    const homePagePositionOne = yield call(getHomePagePositionOneApi);
    yield put(fetchHomePagePositionOneSuccess(homePagePositionOne));
  } catch (e) {
    console.log(e);
  }
}

function* fetchHomeProductListSaga(action) {
  try {
    const homePageProductList = yield call(getHomePageProductListApi);

    yield put(fetchHomePageProductListSuccess(homePageProductList));
  } catch (e) {
    console.log(e);
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* homePageSaga() {
  yield all([
    // takeLatest(constants.FETCH_BLOGS, fetchBlogsSaga),
    takeLatest(
      constants.FETCH_HOME_PAGE_POSITION_ONE,
      fetchHomePagePositionOneSaga
    ),
    takeLatest(
      constants.FETCH_HOME_PAGE_PRODUCT_LIST,
      fetchHomeProductListSaga
    ),
  ]);
}

// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default homePageSaga;
