import constants from "./actionTypes";

// ACTION FOR FETCHING USER PROFILE
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchProfile = (payload) => ({
  payload,
  type: constants.FETCH_PROFILE,
});

// SUCCESS ACTION FOR FETCHING USER PROFILE
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchProfileSuccess = (payload) => ({
  payload,
  type: constants.FETCH_PROFILE_SUCCESS,
});

// FAIL ACTION FOR FETCHING USER PROFILE
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchProfileFail = (payload) => ({
  payload,
  type: constants.FETCH_PROFILE_FAIL,
});

// ACTION FOR FETCHING USER PROFILE CATALOGS PRODUCTS
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchProfileCatalogsProducts = (payload) => ({
  payload,
  type: constants.FETCH_PROFILE_CATALOGS_PRODUCTS,
});

// SUCCESS ACTION FOR FETCHING USER PROFILE CATALOGS PRODUCTS
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchProfileCatalogsProductsSuccess = (payload) => ({
  payload,
  type: constants.FETCH_PROFILE_CATALOGS_PRODUCTS_SUCCESS,
});

// FAIL ACTION FOR FETCHING USER PROFILE CATALOGS PRODUCTS
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchProfileCatalogsProductsFail = (payload) => ({
  payload,
  type: constants.FETCH_PROFILE_CATALOGS_PRODUCTS_FAIL,
});

// ACTION FOR FETCHING USER PROFILE CATALOGS PRODUCTS
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchProfileBlogs = (payload) => ({
  payload,
  type: constants.FETCH_PROFILE_BLOGS,
});

// SUCCESS ACTION FOR FETCHING USER PROFILE CATALOGS PRODUCTS
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchProfileBlogsSuccess = (payload) => ({
  payload,
  type: constants.FETCH_PROFILE_BLOGS_SUCCESS,
});

// FAIL ACTION FOR FETCHING USER PROFILE CATALOGS PRODUCTS
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchProfileBlogsFail = (payload) => ({
  payload,
  type: constants.FETCH_PROFILE_BLOGS_FAIL,
});

export const setPagination = (payload) => ({
  payload,
  type: constants.SET_PAGINATION,
});

export const setPaginationBlogs = (payload) => ({
  payload,
  type: constants.SET_PAGINATION_BLOGS,
});

// ACTION FOR LICENCE CLIENT PROFILE UPDATE
// RECEVE LICENCE CLIENT PROFILE DATA AS A PAYLOAD
export const licenceClientUpdateProfile = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_UPDATE_PROFILE,
});

// SUCCESS FOR LICENCE CLIENT PROFILE UPDATE
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceClientUpdateProfileSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_UPDATE_PROFILE_SUCCESS,
});

// FAIL FOR LICENCE CLIENT PROFILE UPDATE
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceClientUpdateProfileFail = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_UPDATE_PROFILE_FAIL,
});

// ACTION FOR LICENCE USER PROFILE UPDATE
// RECEVE LICENCE USER PROFILE DATA AS A PAYLOAD
export const licenceUserUpdateProfile = (payload) => ({
  payload,
  type: constants.LICENCE_USER_UPDATE_PROFILE,
});

// SUCCESS FOR LICENCE USER PROFILE UPDATE
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserUpdateProfileSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_USER_UPDATE_PROFILE_SUCCESS,
});

// FAIL FOR LICENCE USER PROFILE UPDATE
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserUpdateProfileFail = (payload) => ({
  payload,
  type: constants.LICENCE_USER_UPDATE_PROFILE_FAIL,
});

// ACTION FOR LICENCE USER PASSWORD UPDATE
// RECEVE LICENCE USER PASSWORD DATA AS A PAYLOAD
export const licenceUserUpdatePassword = (payload) => ({
  payload,
  type: constants.LICENCE_USERS_UPDATE_PASSWORD,
});

// SUCCESS FOR LICENCE USER PASSWORD UPDATE
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserUpdatePasswordSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_USERS_UPDATE_PASSWORD_SUCCESS,
});

// FAIL FOR LICENCE USER PASSWORD UPDATE
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserUpdatePasswordFail = (payload) => ({
  payload,
  type: constants.LICENCE_USERS_UPDATE_PASSWORD_FAIL,
});

// ACTION TO UPDATE USER PROFILE NAME
// RCIVE NEW NAME TAHT USER ENTERED IN THE FIELD
export const updateUserProfileName = (payload) => ({
  type: constants.UPDATE_USER_PROFILE_NAME,
  payload,
});
// SUCCESS ACTION TO UPDATE USER PROFILE NAME
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const updateUserProfileNameSuccess = (payload) => ({
  type: constants.UPDATE_USER_PROFILE_NAME_SUCCESS,
  payload,
});

// FAIL ACTION TO UPDATE USER PROFILE NAME
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const updateUserProfileNameFail = (payload) => ({
  type: constants.UPDATE_USER_PROFILE_NAME_FAIL,
  payload,
});
