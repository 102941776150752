import constants from './actionTypes'

// ACTION FOR ACTIVATING EDIT MODE (DISPLAYING ALL EDIT BTNS)
// DO NOT CONTAIN PAYLOAD
export const activateEditMode = () => ({
  type: constants.ACTIVATE_EDIT_MODE,
})

// ACTION FOR DEACTIVATING EDIT MODE (REMOVING ALL EDIT BTNS FROM DOM)
// DO NOT CONTAIN PAYLOAD
export const deactivateEditMode = () => ({
  type: constants.DEACTIVATE_EDIT_MODE,
})

// ACTION FOR ACTIVATING ONE EDID MODAL
// RECIVES THE NAME OF THE EDID MODAL THAT NEEDS TO BE DISPLAYED AS A PAYLOAD
export const activateModal = (payload) => ({
  payload,
  type: constants.ACTIVATE_MODAL,
})

// ACTION FOR CLOSING ONE EDID MODAL
// RECIVES THE NAME OF THE EDID MODAL THAT NEEDS TO BE CLOSED AS A PAYLOAD
export const closeModal = (payload) => ({
  payload,
  type: constants.CLOSE_MODAL,
})
// ACTION FOR SETTING NAVIGATION MODAL STEP
// RECIVE NAVIGATION MODAL STEP AS A PAYLOAD
export const setNavigationModalStep = (payload) => ({
  payload,
  type: constants.SET_NAVIGATION_MODAL_STEP,
})

export const setUseNewCat = () => ({
  type: constants.SET_USE_NEW_CAT,
})

export const removeUseNewCat = () => ({
  type: constants.REMOVE_USE_NEW_CAT,
})
export const setContentTypeId = (payload) => ({
  payload,
  type: constants.SET_CONTENT_TYPE_ID,
})

export const setShowEditLandingScreen = (payload) => ({
  payload,
  type: constants.SET_SHOW_EDIT_CAT_LANDING_SCREEN,
})

export const setEditModalStep = (payload) => ({
  payload,
  type: constants.SET_EDIT_MODAL_STEP,
})

// ACTION FOR GETTING LICENCE DETAILS (LOGIN MESSAGE, REGISTRATION MESSAGE, TERMS AND CONDITION...)
// RECIVES LICENCE DETAILS THAT NEEDS TO BE FETCHED AS A PALYOAD
export const getLicenceDetails = (payload) => ({
  payload,
  type: constants.GET_LICENCE_DETAILS,
})
// ACTION FOR GETTING LICENCE DETAILS SUCCESS
// RECIVES FETCHED LICENCE DETAILS AS A PAYLOAD
export const getLicenceDetailsSuccess = (payload) => ({
  payload,
  type: constants.GET_LICENCE_DETAILS_SUCCESS,
})
// ACTION FOR GETTING LICENCE DETAILS FAIL
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PYLOAD
export const getLicenceDetailsFail = (payload) => ({
  payload,
  type: constants.GET_LICENCE_DETAILS_FAIL,
})

// ACTION FOR SETTING ACTIVE AUTH USER MODAL
// RECIVES MOADAL NAME THAT NEEDS TO BE ACTIVATED AS A PAYLOIAD
export const setActiveAuthUserModal = (payload) => ({
  type: constants.SET_ACTIVE_AUTH_USER_MODAL,
  payload,
})

// ACTION FOR SETTING ACTIVE MODERATION MODAL
// RECIVES MOADAL NAME THAT NEEDS TO BE ACTIVATED AS A PAYLOIAD
export const setActiveModerationMoal = (payload) => ({
  type: constants.SET_ACTIVE_MODERATION_MODAL,
  payload,
})

// ACTION FOR REDIRECTING AFTER PRO
// RECIVES MOADAL NAME THAT NEEDS TO BE ACTIVATED AS A PAYLOIAD
export const redirectToSinglePlanner = (payload) => ({
  type: constants.REDIRECT_TO_SINGLE_PLANNER,
  payload,
})

// ACTION SETTING HEADER HEIGHT
// RECIVES HEADER HEIGHT AS A PAYLOAD
export const setHeaderHeight = (payload) => ({
  type: constants.SET_HEADER_HEIGHT,
  payload,
})

export const closeAllModals = () => ({
  type: constants.CLOSE_ALL_MODALS,
})

// ACTION FOR GETTING ADMIN MODERATION NUMBERS
export const getModerationNumbers = () => ({
  type: constants.GET_MODERATION_NUMBERS,
})
// ACTION FOR GETTING ADMIN MODERATION NUMBERS SUCCESS
// RECIVES MODERATION NUMBERS FROM A SERVER AS A PAYLAOD
export const getModerationNumbersSuccess = (payload) => ({
  payload,
  type: constants.GET_MODERATION_NUMBERS_SUCCESS,
})
// ACTION FOR GETTING ADMIN MODERATION NUMBERS FAIL
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PYLOAD
export const getModerationNumbersFail = (payload) => ({
  payload,
  type: constants.GET_MODERATION_NUMBERS_FAIL,
})

// ACTION FOR SETTING ACTIVE USER MGM MODAL
// RECIVES MOADAL NAME THAT NEEDS TO BE ACTIVATED AS A PAYLOIAD
export const setActiveUserMgmModal = (payload) => ({
  type: constants.SET_ACTIVE_USER_MGM_MODAL,
  payload,
})

// ACTION FOR SETTING ACTIVE SITE MGM MODAL
// RECIVES MOADAL NAME THAT NEEDS TO BE ACTIVATED AS A PAYLOIAD
export const setActiveSiteMgmModal = (payload) => ({
  type: constants.SET_ACTIVE_SITE_MGM_MODAL,
  payload,
})

// ACTION FOR SETTING ACTIVE INFO BTN
// RECIVES ACTIVE INFO BTN NAME AS A PAYLOAD
export const setActiveInfoBtn = (payload) => ({
  type: constants.SET_ACTIVE_INFO_BTN,
  payload,
})

// ACTION FOR CHANGING LOGIN AND REGISTRATION STATUS
export const changleLoginRegistrationStatus = () => ({
  type: constants.CHANGE_LOGIN_REGISTRATION_STATUS,
})
// ACTION FOR CHANGING LOGIN AND REGISTRATION STATUS SUCCESS
// RECIVES SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AND PAYLOAD
export const changleLoginRegistrationStatusSuccess = (payload) => ({
  payload,
  type: constants.CHANGE_LOGIN_REGISTRATION_STATUS_SUCCESS,
})
// ACTION FOR CHANGING LOGIN AND REGISTRATION STATUS FAIL
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PYLOAD
export const changleLoginRegistrationStatusFail = (payload) => ({
  payload,
  type: constants.CHANGE_LOGIN_REGISTRATION_STATUS_FAIL,
})

// ACTION FOR CHANGING SEARCH STATUS
export const changeSearchStatus = () => ({
  type: constants.CHANGE_SEARCH_STATUS,
})
// ACTION FOR CHANGING SEARCH STATUS SUCCESS
// RECIVES SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AND PAYLOAD
export const changeSearchStatusSuccess = (payload) => ({
  payload,
  type: constants.CHANGE_SEARCH_STATUS_SUCCESS,
})
// ACTION FOR CHANGING SEARCH STATUS FAIL
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PYLOAD
export const changeSearchStatusFail = (payload) => ({
  payload,
  type: constants.CHANGE_SEARCH_STATUS_FAIL,
})

// ACTION FOR SETTING BACKEND USER MGM STEP
// RECIVES  BACKEND USER MGM STEP AS A PAYLOAD
export const setBackendUsersMgmStep = (payload) => ({
  payload,
  type: constants.SET_BACKEND_USERS_MGM_STEP,
})

// ACTION FOR NEW USER BOOL
// RECIVES  NEW USER BOOL AS A PAYLOAD
export const setUseNewUser = (payload) => ({
  payload,
  type: constants.SET_USE_NEW_USER,
})

// ACTION FOR NEW USER BOOL
// RECIVES  NEW USER BOOL AS A PAYLOAD
export const setBlurrBg = (payload) => ({
  payload,
  type: constants.SET_BLURR_BG,
})
