import constants from "./actionTypes";

// INITIAL STATE OF THIS REDUCER
const initState = {
  loader: false,
  profile: {},
  profileCatalogsProducts: [],
  contentLoader: false,
  pagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
  profileBlogs: [],
  paginationBlogs: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
};

// REDUCER FUNCTION
export default (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case constants.FETCH_PROFILE:
      return {
        ...state,
        loader: true,
      };

    case constants.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        profile: payload,
        loader: false,
      };

    case constants.FETCH_PROFILE_FAIL:
      return {
        ...state,
        loader: false,
      };

    case constants.FETCH_PROFILE_CATALOGS_PRODUCTS:
      return {
        ...state,
        contentLoader: true,
      };

    case constants.FETCH_PROFILE_CATALOGS_PRODUCTS_SUCCESS:
      return {
        ...state,
        profileCatalogsProducts: payload,
        contentLoader: false,
      };

    case constants.FETCH_PROFILE_CATALOGS_PRODUCTS_FAIL:
      return {
        ...state,
        contentLoader: false,
      };

    case constants.FETCH_PROFILE_BLOGS:
      return {
        ...state,
        contentLoader: true,
      };

    case constants.FETCH_PROFILE_BLOGS_SUCCESS:
      return {
        ...state,
        profileBlogs: payload,
        contentLoader: false,
      };

    case constants.FETCH_PROFILE_BLOGS_FAIL:
      return {
        ...state,
        contentLoader: false,
      };

    case constants.SET_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };

    case constants.SET_PAGINATION_BLOGS:
      return {
        ...state,
        paginationBlogs: payload,
      };

    //  IF NONE OF THE CASE ABOVE IS TRIGGERED RETURN DEFAULT STATE
    default:
      return {
        ...state,
      };
  }
};
