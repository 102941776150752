const FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG =
  'FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG'
const FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG_SUCCESS =
  'FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG_SUCCESS'
const FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG_FAIL =
  'FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG_FAIL'
const SET_PAGINATION = 'SET_PAGINATION'
const FETCH_PRODUCTS_BY_AUTH_CLIENT = 'FETCH_PRODUCTS_BY_AUTH_CLIENT'
const FETCH_PRODUCTS_BY_AUTH_CLIENT_SUCCESS =
  'FETCH_PRODUCTS_BY_AUTH_CLIENT_SUCCESS'
const FETCH_PRODUCTS_BY_AUTH_CLIENT_FAIL = 'FETCH_PRODUCTS_BY_AUTH_CLIENT_FAIL'
const SET_PAGINATION_FOR_PRODUCTS_BY_AUTH_CLIENT =
  'SET_PAGINATION_FOR_PRODUCTS_BY_AUTH_CLIENT'
const GET_PRODUCTS_BY_CATALOG_ID_NO_PAG = 'GET_PRODUCTS_BY_CATALOG_ID_NO_PAG'
const GET_PRODUCTS_BY_CATALOG_ID_NO_PAG_SUCCESS =
  'GET_PRODUCTS_BY_CATALOG_ID_NO_PAG_SUCCESS'
const GET_PRODUCTS_BY_CATALOG_ID_NO_PAG_FAIL =
  'GET_PRODUCTS_BY_CATALOG_ID_NO_PAG_FAIL'
const ACTIVATE_PRODUCT_LOADER = 'ACTIVATE_PRODUCT_LOADER'
const FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION =
  'FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION'
const FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION_SUCCESS =
  'FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION_SUCCESS'
const FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION_FAIL =
  'FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION_FAIL'
const FETCH_FEATURED_PRODUCTS = 'FETCH_FEATURED_PRODUCTS'
const FETCH_FEATURED_PRODUCTS_SUCCESS = 'FETCH_FEATURED_PRODUCTS_SUCCESS'
const FETCH_FEATURED_PRODUCTS_FAIL = 'FETCH_FEATURED_PRODUCTS_FAIL'
const SET_FILTERED_FEATURED_PRODUCTS = 'SET_FILTERED_FEATURED_PRODUCTS'
const FETCH_FEATURED_PRODUCTS_BY_BIDDING_IDS =
  'FETCH_FEATURED_PRODUCTS_BY_BIDDING_IDS'
const FETCH_FEATURED_PRODUCTS_BY_BIDDING_IDS_SUCCESS =
  'FETCH_FEATURED_PRODUCTS_BY_BIDDING_IDS_SUCCESS'
const FETCH_FEATURED_PRODUCTS_BY_BIDDING_IDS_FAIL =
  'FETCH_FEATURED_PRODUCTS_BY_BIDDING_IDS_FAIL'
const SEARCH_CAR = 'SEARCH_CAR'
const SEARCH_CAR_SUCCESS = 'SEARCH_CAR_SUCCESS'
const SEARCH_CAR_FAIL = 'SEARCH_CAR_FAIL'

export default {
  FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG,
  FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG_SUCCESS,
  FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG_FAIL,
  SET_PAGINATION,
  FETCH_PRODUCTS_BY_AUTH_CLIENT,
  FETCH_PRODUCTS_BY_AUTH_CLIENT_SUCCESS,
  FETCH_PRODUCTS_BY_AUTH_CLIENT_FAIL,
  SET_PAGINATION_FOR_PRODUCTS_BY_AUTH_CLIENT,
  GET_PRODUCTS_BY_CATALOG_ID_NO_PAG,
  GET_PRODUCTS_BY_CATALOG_ID_NO_PAG_SUCCESS,
  GET_PRODUCTS_BY_CATALOG_ID_NO_PAG_FAIL,
  FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION,
  FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION_SUCCESS,
  FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION_FAIL,
  ACTIVATE_PRODUCT_LOADER,
  FETCH_FEATURED_PRODUCTS,
  FETCH_FEATURED_PRODUCTS_SUCCESS,
  FETCH_FEATURED_PRODUCTS_FAIL,
  SET_FILTERED_FEATURED_PRODUCTS,
  FETCH_FEATURED_PRODUCTS_BY_BIDDING_IDS,
  FETCH_FEATURED_PRODUCTS_BY_BIDDING_IDS_SUCCESS,
  FETCH_FEATURED_PRODUCTS_BY_BIDDING_IDS_FAIL,
  SEARCH_CAR,
  SEARCH_CAR_SUCCESS,
  SEARCH_CAR_FAIL,
}
