const GET_LICENCE_USERS = "GET_LICENCE_USERS";
const GET_LICENCE_USERS_SUCCESS = "GET_LICENCE_USERS_SUCCESS";
const GET_LICENCE_USERS_FAIL = "GET_LICENCE_USERS_FAIL";
const GET_ALL_LICENCE_USERS = "GET_ALL_LICENCE_USERS";
const GET_ALL_LICENCE_USERS_SUCCESS = "GET_ALL_LICENCE_USERS_SUCCESS";
const RESET_LICENCE_USERS = "RESET_LICENCE_USERS";
const IS_BACKEND_USER = "IS_BACKEND_USER";
const GET_ALL_BACKEND_LICENCE_USERS = "GET_ALL_BACKEND_LICENCE_USERS";
const GET_ALL_BACKEND_LICENCE_USERS_SUCCESS =
  "GET_ALL_BACKEND_LICENCE_USERS_SUCCESS";
const GET_ALL_BACKEND_LICENCE_USERS_FAIL = "GET_ALL_BACKEND_LICENCE_USERS_FAIL";
const GET_ALL_FRONT_END_LICENCE_USERS = "GET_ALL_FRONT_END_LICENCE_USERS";
const GET_ALL_FRONT_END_LICENCE_USERS_SUCCESS =
  "GET_ALL_FRONT_END_LICENCE_USERS_SUCCESS";
const GET_ALL_FRONT_END_LICENCE_USERS_FAIL =
  "GET_ALL_FRONT_END_LICENCE_USERS_FAIL";
const SET_PAGINATION_FRONT_END = "SET_PAGINATION_FRONT_END";
const SET_PAGINATION_BACKEND = "SET_PAGINATION_BACKEND";
const SET_PAGINATION_ALL_LICENCE_USERS = "SET_PAGINATION_ALL_LICENCE_USERS";
const ADMIN_BLOCK_USER = "ADMIN_BLOCK_USER";
const ADMIN_BLOCK_USER_SUCCESS = "ADMIN_BLOCK_USER_SUCCESS";
const ADMIN_BLOCK_USER_FAIL = "ADMIN_BLOCK_USER_FAIL";
const RESET_BACKEND_USERS = "RESET_BACKEND_USERS";
const RESET_FRONT_END_USERS = "RESET_FRONT_END_USERS";
const ADMIN_CHANGE_USER_EMAIL = "ADMIN_CHANGE_USER_EMAIL";
const ADMIN_CHANGE_USER_EMAIL_SUCCESS = "ADMIN_CHANGE_USER_EMAIL_SUCCESS";
const ADMIN_CHANGE_USER_EMAIL_FAIL = "ADMIN_CHANGE_USER_EMAIL_FAIL";
const ADMIN_ACTIVATE_USER = "ADMIN_ACTIVATE_USER";
const ADMIN_ACTIVATE_USER_SUCCESS = "ADMIN_ACTIVATE_USER_SUCCESS";
const ADMIN_ACTIVATE_USER_FAIL = "ADMIN_ACTIVATE_USER_FAIL";
const ACTIVATE_COMMENT_LOADER = "ACTIVATE_COMMENT_LOADER";
const ADMIN_CHANGE_USER_USERNAME = "ADMIN_CHANGE_USER_USERNAME";
const ADMIN_CHANGE_USER_USERNAME_SUCCESS = "ADMIN_CHANGE_USER_USERNAME_SUCCESS";
const ADMIN_CHANGE_USER_USERNAME_FAIL = "ADMIN_CHANGE_USER_USERNAME_FAIL";
const ADMIN_UPDATE_USER_ROLE = "ADMIN_UPDATE_USER_ROLE";
const ADMIN_UPDATE_USER_ROLE_SUCCESS = "ADMIN_UPDATE_USER_ROLE_SUCCESS";
const ADMIN_UPDATE_USER_ROLE_FAIL = "ADMIN_UPDATE_USER_ROLE_FAIL";
const ADMIN_CREATE_USER = "ADMIN_CREATE_USER";
const ADMIN_CREATE_USER_SUCCESS = "ADMIN_CREATE_USER_SUCCESS";
const ADMIN_CREATE_USER_FAIL = "ADMIN_CREATE_USER_FAIL";
const ADMIN_UPDATE_USER_DATA = "ADMIN_UPDATE_USER_DATA";
const ADMIN_UPDATE_USER_DATA_SUCCESS = "ADMIN_UPDATE_USER_DATA_SUCCESS";
const ADMIN_UPDATE_USER_DATA_FAIL = "ADMIN_UPDATE_USER_DATA_FAIL";
const SET_NEW_USER = "SET_NEW_USER";

export default {
  GET_LICENCE_USERS,
  GET_LICENCE_USERS_SUCCESS,
  GET_LICENCE_USERS_FAIL,
  GET_ALL_LICENCE_USERS,
  GET_ALL_LICENCE_USERS_SUCCESS,
  RESET_LICENCE_USERS,
  IS_BACKEND_USER,
  ADMIN_BLOCK_USER,
  ADMIN_BLOCK_USER_SUCCESS,
  ADMIN_BLOCK_USER_FAIL,
  GET_ALL_BACKEND_LICENCE_USERS,
  GET_ALL_BACKEND_LICENCE_USERS_SUCCESS,
  GET_ALL_BACKEND_LICENCE_USERS_FAIL,
  GET_ALL_FRONT_END_LICENCE_USERS,
  GET_ALL_FRONT_END_LICENCE_USERS_SUCCESS,
  GET_ALL_FRONT_END_LICENCE_USERS_FAIL,
  SET_PAGINATION_BACKEND,
  SET_PAGINATION_FRONT_END,
  SET_PAGINATION_ALL_LICENCE_USERS,
  RESET_BACKEND_USERS,
  RESET_FRONT_END_USERS,
  ADMIN_CHANGE_USER_EMAIL,
  ADMIN_CHANGE_USER_EMAIL_SUCCESS,
  ADMIN_CHANGE_USER_EMAIL_FAIL,
  ADMIN_ACTIVATE_USER,
  ADMIN_ACTIVATE_USER_SUCCESS,
  ADMIN_ACTIVATE_USER_FAIL,
  ACTIVATE_COMMENT_LOADER,
  ADMIN_CHANGE_USER_USERNAME,
  ADMIN_CHANGE_USER_USERNAME_SUCCESS,
  ADMIN_CHANGE_USER_USERNAME_FAIL,
  ADMIN_UPDATE_USER_ROLE,
  ADMIN_UPDATE_USER_ROLE_SUCCESS,
  ADMIN_UPDATE_USER_ROLE_FAIL,
  ADMIN_CREATE_USER,
  ADMIN_CREATE_USER_SUCCESS,
  ADMIN_CREATE_USER_FAIL,
  ADMIN_UPDATE_USER_DATA,
  ADMIN_UPDATE_USER_DATA_SUCCESS,
  ADMIN_UPDATE_USER_DATA_FAIL,
  SET_NEW_USER,
};
