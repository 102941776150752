import constants from './actionTypes'

export const fetchProductListByCategorySlug = (payload) => ({
  payload,
  type: constants.FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG,
})

export const fetchProductListByCategorySlugSuccess = (payload) => ({
  payload,
  type: constants.FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG_SUCCESS,
})

export const fetchProductListByCategorySlugFail = (payload) => ({
  payload,
  type: constants.FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG_FAIL,
})

export const fetchFeaturedProducts = () => ({
  type: constants.FETCH_FEATURED_PRODUCTS,
})

export const fetchFeaturedProductsSuccess = (payload) => ({
  payload,
  type: constants.FETCH_FEATURED_PRODUCTS_SUCCESS,
})

export const fetchFeaturedProductsFail = () => ({
  type: constants.FETCH_FEATURED_PRODUCTS_FAIL,
})
export const fetchFeaturedProductsByBiddingIds = () => ({
  type: constants.FETCH_FEATURED_PRODUCTS_BY_BIDDING_IDS,
})

export const fetchFeaturedProductsByBiddingIdsSuccess = (payload) => ({
  payload,
  type: constants.FETCH_FEATURED_PRODUCTS_BY_BIDDING_IDS_SUCCESS,
})

export const fetchFeaturedProductsByBiddingIdsFail = () => ({
  type: constants.FETCH_FEATURED_PRODUCTS_BY_BIDDING_IDS_FAIL,
})

export const searchCar = (payload) => ({
  payload,
  type: constants.SEARCH_CAR,
})

export const searchCarSuccess = (payload) => ({
  payload,
  type: constants.SEARCH_CAR_SUCCESS,
})

export const searchCarFail = () => ({
  type: constants.SEARCH_CAR_FAIL,
})

export const setFilteredFeaturedProducts = (payload) => ({
  payload,
  type: constants.SET_FILTERED_FEATURED_PRODUCTS,
})

export const setPagination = (payload) => ({
  payload,
  type: constants.SET_PAGINATION,
})
// ACTION FOR FETCHING PRODUCTS BY THE AUTH CLIENT
// RECIVE OBJECT WITH FILTER DATA
export const fetchProductsByAuthClient = (payload) => ({
  payload,
  type: constants.FETCH_PRODUCTS_BY_AUTH_CLIENT,
})
// ACTION FOR FETCHING PRODUCTS BY THE AUTH CLIENT SUCCESS
// RECIVE PRODUCTS DATA FROM THE SERVER AS A PAYLOAD
export const fetchProductsByAuthClientSuccess = (payload) => ({
  payload,
  type: constants.FETCH_PRODUCTS_BY_AUTH_CLIENT_SUCCESS,
})
// ACTION FOR FETCHING PRODUCTS BY THE AUTH CLIENT FAIL
// RECIVE ERROR MESSAGE AND THE STATUS CODE AS A PAYLOAD
export const fetchProductsByAuthClientFail = (payload) => ({
  payload,
  type: constants.FETCH_PRODUCTS_BY_AUTH_CLIENT_FAIL,
})

// ACTION FOR SETTING PAGINATION OF AUTH USER BLOGS
// RECIVES HEDERS FROM THE SERVER RESPONSE TO FETCHING BLOGS THAT AUTH USER CREATED AS A PAYOAD
export const setPaginationForAuthUserProducts = (payload) => ({
  payload,
  type: constants.SET_PAGINATION_FOR_PRODUCTS_BY_AUTH_CLIENT,
})

// ACTION FOR FETCHING PRODUCTS BY THE CATALOG ID
// RECIVE CATALOG ID AS A PAYLOAD
export const getProductsByCatalogIdNoPag = (payload) => ({
  payload,
  type: constants.GET_PRODUCTS_BY_CATALOG_ID_NO_PAG,
})
// ACTION FOR FETCHING PRODUCTS BY THE AUTH CLIENT SUCCESS
// RECIVE PRODUCTS DATA FROM THE SERVER AS A PAYLOAD
export const getProductsByCatalogIdNoPagSuccess = (payload) => ({
  payload,
  type: constants.GET_PRODUCTS_BY_CATALOG_ID_NO_PAG_SUCCESS,
})
// ACTION FOR FETCHING PRODUCTS BY THE AUTH CLIENT FAIL
// RECIVE ERROR MESSAGE AND THE STATUS CODE AS A PAYLOAD
export const getProductsByCatalogIdNoPagFail = (payload) => ({
  payload,
  type: constants.GET_PRODUCTS_BY_CATALOG_ID_NO_PAG_FAIL,
})

// ACTION FOR FETCHING PRODUCTS WITH NO PAGINATION BY THE AUTH CLIENT
// RECIVE AUTH CLINET ID AND PRODUCT STATUS THAT NEEDS TO BE FETCHED
export const getAuthClientProductsNoPagination = (payload) => ({
  payload,
  type: constants.FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION,
})
// ACTION FOR FETCHING PRODUCTS WITH NO PAGINATION BY THE AUTH CLIENT SUCCESS
// RECIVE ALL AUTH CLIENT PRODUCTS WITH APROVED STATUS
export const getAuthClientProductsNoPaginationSuccess = (payload) => ({
  payload,
  type: constants.FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION_SUCCESS,
})
// ACTION FOR FETCHING PRODUCTS WITH NO PAGINATION BY THE AUTH CLIENT FAIL
// RECIVE ERROR MESSAGE AND THE STATUS CODE AS A PAYLOAD
export const getAuthClientProductsNoPaginationFail = (payload) => ({
  payload,
  type: constants.FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION_FAIL,
})
// ACTION FOR ACTIATION PRODUCT LOADER
export const activateProductLoader = () => ({
  type: constants.ACTIVATE_PRODUCT_LOADER,
})
