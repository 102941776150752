import constants from "./actionTypes";

const initState = {
  loader: false,
  transactionData: {},
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.INIT_CHECKOUT_SALES_SITE:
      return {
        ...state,
        loader: true,
      };
    case constants.INIT_CHECKOUT_SALES_SITE_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case constants.GET_TRANSACTION_DATA:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_TRANSACTION_DATA_SUCCESS:
      return {
        ...state,
        loader: false,
        transactionData: payload,
      };
    default:
      return state;
  }
};
