const GET_UNSPLASH_COLLECTIONS = "GET_UNSPLASH_COLLECTIONS";
const GET_UNSPLASH_COLLECTIONS_SUCCESS = "GET_UNSPLASH_COLLECTIONS_SUCCESS";
const GET_UNSPLASH_COLLECTIONS_FALI = "GET_UNSPLASH_COLLECTIONS_FALI";
const GET_UNSPLASH_COLLECTION_PHOTOS = "GET_UNSPLASH_COLLECTION_PHOTOS";
const GET_UNSPLASH_COLLECTION_PHOTOS_SUCCESS =
  "GET_UNSPLASH_COLLECTION_PHOTOS_SUCCESS";
const GET_UNSPLASH_COLLECTION_PHOTOS_FALI =
  "GET_UNSPLASH_COLLECTION_PHOTOS_FALI";
const GET_SEARCH_TERM_PHOTOS = "GET_SEARCH_TERM_PHOTOS";
const GET_SEARCH_TERM_PHOTOS_SUCCESS = "GET_SEARCH_TERM_PHOTOS_SUCCESS";
const GET_SEARCH_TERM_PHOTOS_FALI = "GET_SEARCH_TERM_PHOTOS_FALI";
const GET_UNSPLASH_PAGINATION_LINK = "GET_UNSPLASH_PAGINATION_LINK";
const GET_UNSPLASH_PAGINATION_LINK_SUCCESS =
  "GET_UNSPLASH_PAGINATION_LINK_SUCCESS";
const GET_UNSPLASH_PAGINATION_LINK_FALI = "GET_UNSPLASH_PAGINATION_LINK_FALI";
const SET_PAGINATION_OBJECT = "SET_PAGINATION_OBJECT";
const RESET_PHOTOS = "RESET_PHOTOS";
const SET_SELECTED_COLLECTION = "SET_SELECTED_COLLECTION";

export default {
  GET_UNSPLASH_COLLECTIONS,
  GET_UNSPLASH_COLLECTIONS_SUCCESS,
  GET_UNSPLASH_COLLECTIONS_FALI,
  GET_UNSPLASH_COLLECTION_PHOTOS,
  GET_UNSPLASH_COLLECTION_PHOTOS_SUCCESS,
  GET_UNSPLASH_COLLECTION_PHOTOS_FALI,
  GET_SEARCH_TERM_PHOTOS,
  GET_SEARCH_TERM_PHOTOS_SUCCESS,
  GET_SEARCH_TERM_PHOTOS_FALI,
  GET_UNSPLASH_PAGINATION_LINK,
  GET_UNSPLASH_PAGINATION_LINK_SUCCESS,
  GET_UNSPLASH_PAGINATION_LINK_FALI,
  SET_PAGINATION_OBJECT,
  RESET_PHOTOS,
  SET_SELECTED_COLLECTION,
};
