import constants from "./actionTypes";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  initCheckoutApi,
  getTransactionDataApi,
  sendPaymentSuccessMailApi,
} from "../../services/ApiCalls";
import { getTransactionDataSuccess, getPaymentMailSuccess } from "./actions";
import { NotificationManager } from "react-notifications";
// SAGA TO INITIALIZE CHECKOUT PROCESS
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.INIT_CHECKOUT
// PAYLOAD : USER AND OTHER NEEDED DATA FOR THIS PROCESS
function* initCheckoutSalesSiteSaga(action) {
  try {
    const purschaseStatus = yield call(initCheckoutApi, action.payload);
    // const transactionData = yield call(getTransactionDataApi, action.payload);
    // let MINUTE_MS = 15000;

    // yield put(getTransactionDataSuccess(purschaseStatus.transactionModel));
    console.log("purschaseStatus", purschaseStatus);
    // window.location.href = purschaseStatus;

    if (purschaseStatus.return_type === "FINISHED") {
      window.location.href = purschaseStatus.success_url;
    } else if (purschaseStatus.success_url) {
      window.location.href = purschaseStatus.success_url;
    } else if (purschaseStatus.error_url) {
      window.location.href = purschaseStatus.error_url;
    }
    // setInterval(() => {
    //   purschaseStatus.error_url
    //     ? (window.location.href = purschaseStatus.error_url)
    //     : purschaseStatus.success_url &&
    //       Object.keys(transactionData.result).length !== 0
    //     ? // transactionData.result.result == "OK"

    //       (window.location.href = purschaseStatus.success_url)
    //     : console.log("neuspeli uslov za setInterval()");
    // }, MINUTE_MS);

    // setInterval(() => {
    //   if (
    //     purschaseStatus.success_url &&
    //     // transactionData.result.result == "OK"
    //     Object.keys(transactionData.result).length !== 0
    //   ) {
    //     window.location.href = purschaseStatus.success_url;
    //   } else if (purschaseStatus.error_url) {
    //     window.location.href = purschaseStatus.error_url;
    //   }
    // }, MINUTE_MS);

    console.log("purschaseStatus", purschaseStatus);

    // console.log("purschaseStatus", purschaseStatus);
    // const initCheckout = yield call();
  } catch (e) {
    console.log("initCheckoutSaga error", e);
  }
}

// SAGA TO GET TRANSACTION STATUS
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_TRANSACTION_DATA
// PAYLOAD : TRANSACTION ID FROM THE URL
function* getTransactionDataSaga(action) {
  try {
    const transactionData = yield call(getTransactionDataApi, action.payload);
    // console.log("transactionData novBuild", transactionData);
    if (Object.keys(transactionData.result).length !== 0) {
      // if (transactionData.result) {
      yield put(getTransactionDataSuccess(transactionData));
    }
  } catch (e) {
    console.log("getTransactionDataSaga error", e);
  }
}

// SAGA FOR GETTING LICENCE SERVICE MAILS
// RECIVES TYPE  FROM ACTION
// TYPE : constants.GET_LICENCE_SERVICE_MAILS
function* sendPaymentSuccessMailSaga(action) {
  try {
    const paymentSuccessMail = yield call(
      sendPaymentSuccessMailApi,
      action.payload
    );
    console.log(paymentSuccessMail, "paymentSuccessMail IZ SAGE");
    console.log(paymentSuccessMail.result, "paymentSuccessMail Result IZ SAGE");
    // yield put(getPaymentMailSuccess(paymentSuccessMail));
    if (paymentSuccessMail.result === "success") {
      NotificationManager.success("Uspešno vam je prosledjen mail", "", 3000);
      // window.location.href = "/";

      // setTimeout(() => {
      //   window.location.href = "/";
      // }, 5000);
    }
    // NotificationManager.success("Prosao SUCCESS", "", 3000);
  } catch (e) {
    console.log("error", e);
    // NotificationManager.error(e.response.data.message, "", 3000);
  }
}

function* checkoutSaga() {
  yield all([
    takeLatest(constants.INIT_CHECKOUT_SALES_SITE, initCheckoutSalesSiteSaga),
    takeLatest(constants.GET_TRANSACTION_DATA, getTransactionDataSaga),
    takeLatest(
      constants.SEND_PAYMENT_CONFIRMATION_MAIL,
      sendPaymentSuccessMailSaga
    ),
  ]);
}

export default checkoutSaga;
