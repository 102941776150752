const ADMIN_CREATE_PAGE = "ADMIN_CREATE_PAGE";
const ADMIN_CREATE_PAGE_SUCCESS = "ADMIN_CREATE_PAGE_SUCCESS";
const ADMIN_CREATE_PAGE_FAIL = "ADMIN_CREATE_PAGE_FAIL";
const ADMIN_CREATE_PAGE_DRAFT = "ADMIN_CREATE_PAGE_DRAFT";
const ADMIN_CREATE_PAGE_DRAFT_SUCCESS = "ADMIN_CREATE_PAGE_DRAFT_SUCCESS";
const ADMIN_CREATE_PAGE_DRAFT_FAIL = "ADMIN_CREATE_PAGE_DRAFT_FAIL";
const GET_STATIC_PAGES_LIST = "GET_STATIC_PAGES_LIST";
const GET_STATIC_PAGES_LIST_SUCCESS = "GET_STATIC_PAGES_LIST_SUCCESS";
const GET_STATIC_PAGES_LIST_FAIL = "GET_STATIC_PAGES_LIST_FAIL";
const SET_STATIC_PAGES_PAGINATION = "SET_STATIC_PAGES_PAGINATION";
const ADMIN_UPDATE_STATIC_PAGE = "ADMIN_UPDATE_STATIC_PAGE";
const ADMIN_UPDATE_STATIC_PAGE_SUCCESS = "ADMIN_UPDATE_STATIC_PAGE_SUCCESS";
const ADMIN_UPDATE_STATIC_PAGE_FAIL = "ADMIN_UPDATE_STATIC_PAGE_FAIL";
const ADMIN_UPDATE_STATIC_PAGE_DRAFT = "ADMIN_UPDATE_STATIC_PAGE_DRAFT";
const ADMIN_UPDATE_STATIC_PAGE_DRAFT_SUCCESS =
  "ADMIN_UPDATE_STATIC_PAGE_DRAFT_SUCCESS";
const ADMIN_UPDATE_STATIC_PAGE_DRAFT_FAIL =
  "ADMIN_UPDATE_STATIC_PAGE_DRAFT_FAIL";
const ADMIN_PUBLISH_STATIC_PAGE_DRAFT = "ADMIN_PUBLISH_STATIC_PAGE_DRAFT";
const ADMIN_PUBLISH_STATIC_PAGE_DRAFT_SUCCESS =
  "ADMIN_PUBLISH_STATIC_PAGE_DRAFT_SUCCESS";
const ADMIN_PUBLISH_STATIC_PAGE_DRAFT_FAIL =
  "ADMIN_PUBLISH_STATIC_PAGE_DRAFT_FAIL";
const ACTIVATE_STATIC_CONTENT_LOADER = "ACTIVATE_STATIC_CONTENT_LOADER";
const ADMIN_DELETE_STATIC_PAGE = "ADMIN_DELETE_STATIC_PAGE";
const ADMIN_DELETE_STATIC_PAGE_SUCCESS = "ADMIN_DELETE_STATIC_PAGE_SUCCESS";
const ADMIN_DELETE_STATIC_PAGE_FAIL = "ADMIN_DELETE_STATIC_PAGE_FAIL";
const DELETE_STATIC_PAGE_DRAFT = "DELETE_STATIC_PAGE_DRAFT";
const DELETE_STATIC_PAGE_DRAFT_SUCCESS = "DELETE_STATIC_PAGE_DRAFT_SUCCESS";
const DELETE_STATIC_PAGE_DRAFT_FAIL = "DELETE_STATIC_PAGE_DRAFT_FAIL";
const GET_STATIC_PAGE_DETAILS = "GET_STATIC_PAGE_DETAILS";
const GET_STATIC_PAGE_DETAILS_SUCCESS = "GET_STATIC_PAGE_DETAILS_SUCCESS";
const GET_STATIC_PAGE_DETAILS_FAIL = "GET_STATIC_PAGE_DETAILS_FAIL";
export default {
  ADMIN_CREATE_PAGE,
  ADMIN_CREATE_PAGE_SUCCESS,
  ADMIN_CREATE_PAGE_FAIL,
  ADMIN_CREATE_PAGE_DRAFT,
  ADMIN_CREATE_PAGE_DRAFT_SUCCESS,
  ADMIN_CREATE_PAGE_DRAFT_FAIL,
  GET_STATIC_PAGES_LIST,
  GET_STATIC_PAGES_LIST_SUCCESS,
  GET_STATIC_PAGES_LIST_FAIL,
  SET_STATIC_PAGES_PAGINATION,
  ADMIN_UPDATE_STATIC_PAGE,
  ADMIN_UPDATE_STATIC_PAGE_SUCCESS,
  ADMIN_UPDATE_STATIC_PAGE_FAIL,
  ADMIN_UPDATE_STATIC_PAGE_DRAFT,
  ADMIN_UPDATE_STATIC_PAGE_DRAFT_SUCCESS,
  ADMIN_UPDATE_STATIC_PAGE_DRAFT_FAIL,
  ADMIN_PUBLISH_STATIC_PAGE_DRAFT,
  ADMIN_PUBLISH_STATIC_PAGE_DRAFT_SUCCESS,
  ADMIN_PUBLISH_STATIC_PAGE_DRAFT_FAIL,
  ACTIVATE_STATIC_CONTENT_LOADER,
  ADMIN_DELETE_STATIC_PAGE,
  ADMIN_DELETE_STATIC_PAGE_SUCCESS,
  ADMIN_DELETE_STATIC_PAGE_FAIL,
  DELETE_STATIC_PAGE_DRAFT,
  DELETE_STATIC_PAGE_DRAFT_SUCCESS,
  DELETE_STATIC_PAGE_DRAFT_FAIL,
  GET_STATIC_PAGE_DETAILS,
  GET_STATIC_PAGE_DETAILS_SUCCESS,
  GET_STATIC_PAGE_DETAILS_FAIL,
};
