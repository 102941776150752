import constants from "./actionTypes";
// ACTION FOR FETCHING HOME PAGE POSITION ONE
export const fetchHomePagePositionOne = () => ({
  type: constants.FETCH_HOME_PAGE_POSITION_ONE,
});
// SUCCESS ACTION FOR FETCHING HOME PAGE POSITION ONE
// RECIVES HOME PAGE POSITION ONE DATA FROM THE SERVER AS A PAYLOAD
export const fetchHomePagePositionOneSuccess = (payload) => ({
  payload,
  type: constants.FETCH_HOME_PAGE_POSITION_ONE_SUCCESS,
});
// FAIL ACTION FOR FETCHING HOME PAGE POSITION ONE
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const fetchHomePagePositionOneFail = (payload) => ({
  payload,
  type: constants.FETCH_HOME_PAGE_POSITION_ONE_FAIL,
});
// ACTION FOR FETCHING HOME PAGE PRODUCT LIST
export const fetchHomePageProductList = () => ({
  type: constants.FETCH_HOME_PAGE_PRODUCT_LIST,
});
// SUCCESS ACTION FOR FETCHING HOME PAGE PRODUCT LIST
// RECIVES HOME PAGE PRODUCT LIST DATA FROM THE SERVER AS A PAYLOAD
export const fetchHomePageProductListSuccess = (payload) => ({
  payload,
  type: constants.FETCH_HOME_PAGE_PRODUCT_LIST_SUCCESS,
});
// FAIL ACTION FOR FETCHING HOME PAGE PRODUCT LIST
// RECIVES ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const fetchHomePageProductListFail = (payload) => ({
  payload,
  type: constants.FETCH_HOME_PAGE_PRODUCT_LIST_FAIL,
});
