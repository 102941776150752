import constants from "./actionTypes";

// ACTION FOR GETTING LICENCE TAGS
export const getLicenceTags = (payload) => ({
  type: constants.GET_LICENCE_TAGS,
  payload,
});

// ACTION FOR GETTING LICENCE TAGS SUCCESS
// RECIVE ARRAY OF LICENCE TAGS FROM SERVER
export const getLicenceTagsSuccess = (payload) => ({
  type: constants.GET_LICENCE_TAGS_SUCCESS,
  payload,
});

// ACTION FOR GETTING LICENCE TAGS FAIL
// RECIVE ERROR CODE AND MESSAGE AS A PAYLOAD FROM SERVER
export const getLicenceTagsFail = (payload) => ({
  type: constants.GET_LICENCE_TAGS_FAIL,
  payload,
});
