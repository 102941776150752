const FETCH_CATALOGS_BY_AUTH_CLIENT = "FETCH_CATALOGS_BY_AUTH_CLIENT";
const FETCH_CATALOGS_BY_AUTH_CLIENT_SUCCESS =
  "FETCH_CATALOGS_BY_AUTH_CLIENT_SUCCESS";
const FETCH_CATALOGS_BY_AUTH_CLIENT_FAIL = "FETCH_CATALOGS_BY_AUTH_CLIENT_FAIL";
const SET_PAGINATION_FOR_AUTH_CLIENT_CATALOGS =
  "SET_PAGINATION_FOR_AUTH_CLIENT_CATALOGS";
const LICENCE_USER_CREATE_CATALOG = "LICENCE_USER_CREATE_CATALOG";
const LICENCE_USER_CREATE_CATALOG_SUCCESS =
  "LICENCE_USER_CREATE_CATALOG_SUCCESS";
const LICENCE_USER_CREATE_CATALOG_FAIL = "LICENCE_USER_CREATE_CATALOG_FAIL";
const LICENCE_USER_UPDATE_CATALOG = "LICENCE_USER_UPDATE_CATALOG";
const LICENCE_USER_UPDATE_CATALOG_SUCCESS =
  "LICENCE_USER_UPDATE_CATALOG_SUCCESS";
const LICENCE_USER_UPDATE_CATALOG_FAIL = "LICENCE_USER_UPDATE_CATALOG_FAIL";
const GET_USER_CATALOGS_NO_PAGINATION = "GET_USER_CATALOGS_NO_PAGINATION";
const GET_USER_CATALOGS_NO_PAGINATION_SUCCESS =
  "GET_USER_CATALOGS_NO_PAGINATION_SUCCESS";
const GET_USER_CATALOGS_NO_PAGINATION_FAIL =
  "GET_USER_CATALOGS_NO_PAGINATION_FAIL";
const SAVE_CATALOG_AS_DRAFT = "SAVE_CATALOG_AS_DRAFT";
const SAVE_CATALOG_AS_DRAFT_SUCCESS = "SAVE_CATALOG_AS_DRAFT_SUCCESS";
const SAVE_CATALOG_AS_DRAFT_FAIL = "SAVE_CATALOG_AS_DRAFT_FAIL";
const PUBLISH_CATALOG_DRAFT = "PUBLISH_CATALOG_DRAFT";
const PUBLISH_CATALOG_DRAFT_SUCCESS = "PUBLISH_CATALOG_DRAFT_SUCCESS";
const PUBLISH_CATALOG_DRAFT_FAIL = "PUBLISH_CATALOG_DRAFT_FAIL";
const DISABLE_CATALOG_BY_LICENCE_CLIENT = "DISABLE_CATALOG_BY_LICENCE_CLIENT";
const DISABLE_CATALOG_BY_LICENCE_CLIENT_SUCCESS =
  "DISABLE_CATALOG_BY_LICENCE_CLIENT_SUCCESS";
const DISABLE_CATALOG_BY_LICENCE_CLIENT_FAIL =
  "DISABLE_CATALOG_BY_LICENCE_CLIENT_FAIL";
const UPDATE_CATALOG_DRAFT = "UPDATE_CATALOG_DRAFT";
const UPDATE_CATALOG_DRAFT_SUCCESS = "UPDATE_CATALOG_DRAFT_SUCCESS";
const UPDATE_CATALOG_DRAFT_FAIL = "UPDATE_CATALOG_DRAFT_FAIL";
const FETCH_CATALOG_BY_SLUG = "FETCH_CATALOG_BY_SLUG";
const FETCH_CATALOG_BY_SLUG_SUCCESS = "FETCH_CATALOG_BY_SLUG_SUCCESS";
const FETCH_CATALOG_BY_SLUG_FAIL = "FETCH_CATALOG_BY_SLUG_FAIL";
const FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID =
  "FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID";
const FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID_SUCCESS =
  "FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID_SUCCESS";
const FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID_FAIL =
  "FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID_FAIL";
const SET_PAGINATION_FOR_CATALOG_PRODUCTS =
  "SET_PAGINATION_FOR_CATALOG_PRODUCTS";
const GET_LICENCE_CATALOGS = "GET_LICENCE_CATALOGS";
const GET_LICENCE_CATALOGS_SUCCESS = "GET_LICENCE_CATALOGS_SUCCESS";
const GET_LICENCE_CATALOGS_FAIL = "GET_LICENCE_CATALOGS_FAIL";
const ADMIN_CREATE_CATALOG = "ADMIN_CREATE_CATALOG";
const ADMIN_CREATE_CATALOG_SUCCESS = "ADMIN_CREATE_CATALOG_SUCCESS";
const ADMIN_CREATE_CATALOG_FAIL = "ADMIN_CREATE_CATALOG_FAIL";
const ADMIN_UPDATE_CATALOG = "ADMIN_UPDATE_CATALOG";
const ADMIN_UPDATE_CATALOG_SUCCESS = "ADMIN_UPDATE_CATALOG_SUCCESS";
const ADMIN_UPDATE_CATALOG_FAIL = "ADMIN_UPDATE_CATALOG_FAIL";
const ACTIVATE_CATALOG_LOADER = "ACTIVATE_CATALOG_LOADER";
const APPROVE_CATALOG_MODERATION = "APPROVE_CATALOG_MODERATION";
const APPROVE_CATALOG_MODERATION_SUCCESS = "APPROVE_CATALOG_MODERATION_SUCCESS";
const APPROVE_CATALOG_MODERATION_FAIL = "APPROVE_CATALOG_MODERATION_FAIL";
const ADMIN_DISAPPROVE_CATALOG_MODERATION =
  "ADMIN_DISAPPROVE_CATALOG_MODERATION";
const ADMIN_DISAPPROVE_CATALOG_MODERATION_SUCCESS =
  "ADMIN_DISAPPROVE_CATALOG_MODERATION_SUCCESS";
const ADMIN_DISAPPROVE_CATALOG_MODERATION_FAIL =
  "ADMIN_DISAPPROVE_CATALOG_MODERATION_FAIL";
const REQUEST_FOR_EDIT_CATALOG_MODERATION =
  "REQUEST_FOR_EDIT_CATALOG_MODERATION";
const REQUEST_FOR_EDIT_CATALOG_MODERATION_SUCCESS =
  "REQUEST_FOR_EDIT_CATALOG_MODERATION_SUCCESS";
const REQUEST_FOR_EDIT_CATALOG_MODERATION_FAIL =
  "REQUEST_FOR_EDIT_CATALOG_MODERATION_FAIL";

export default {
  FETCH_CATALOGS_BY_AUTH_CLIENT,
  FETCH_CATALOGS_BY_AUTH_CLIENT_SUCCESS,
  FETCH_CATALOGS_BY_AUTH_CLIENT_FAIL,
  SET_PAGINATION_FOR_AUTH_CLIENT_CATALOGS,
  LICENCE_USER_CREATE_CATALOG,
  LICENCE_USER_CREATE_CATALOG_SUCCESS,
  LICENCE_USER_CREATE_CATALOG_FAIL,
  LICENCE_USER_UPDATE_CATALOG,
  LICENCE_USER_UPDATE_CATALOG_SUCCESS,
  LICENCE_USER_UPDATE_CATALOG_FAIL,
  GET_USER_CATALOGS_NO_PAGINATION,
  GET_USER_CATALOGS_NO_PAGINATION_SUCCESS,
  GET_USER_CATALOGS_NO_PAGINATION_FAIL,
  SAVE_CATALOG_AS_DRAFT,
  SAVE_CATALOG_AS_DRAFT_SUCCESS,
  SAVE_CATALOG_AS_DRAFT_FAIL,
  PUBLISH_CATALOG_DRAFT,
  PUBLISH_CATALOG_DRAFT_SUCCESS,
  PUBLISH_CATALOG_DRAFT_FAIL,
  DISABLE_CATALOG_BY_LICENCE_CLIENT,
  DISABLE_CATALOG_BY_LICENCE_CLIENT_SUCCESS,
  DISABLE_CATALOG_BY_LICENCE_CLIENT_FAIL,
  UPDATE_CATALOG_DRAFT,
  UPDATE_CATALOG_DRAFT_SUCCESS,
  UPDATE_CATALOG_DRAFT_FAIL,
  FETCH_CATALOG_BY_SLUG,
  FETCH_CATALOG_BY_SLUG_SUCCESS,
  FETCH_CATALOG_BY_SLUG_FAIL,
  FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID,
  FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID_SUCCESS,
  FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID_FAIL,
  SET_PAGINATION_FOR_CATALOG_PRODUCTS,
  GET_LICENCE_CATALOGS,
  GET_LICENCE_CATALOGS_SUCCESS,
  GET_LICENCE_CATALOGS_FAIL,
  ADMIN_CREATE_CATALOG,
  ADMIN_CREATE_CATALOG_SUCCESS,
  ADMIN_CREATE_CATALOG_FAIL,
  ADMIN_UPDATE_CATALOG,
  ADMIN_UPDATE_CATALOG_SUCCESS,
  ADMIN_UPDATE_CATALOG_FAIL,
  APPROVE_CATALOG_MODERATION,
  APPROVE_CATALOG_MODERATION_SUCCESS,
  APPROVE_CATALOG_MODERATION_FAIL,
  ADMIN_DISAPPROVE_CATALOG_MODERATION,
  ADMIN_DISAPPROVE_CATALOG_MODERATION_SUCCESS,
  ADMIN_DISAPPROVE_CATALOG_MODERATION_FAIL,
  REQUEST_FOR_EDIT_CATALOG_MODERATION,
  REQUEST_FOR_EDIT_CATALOG_MODERATION_SUCCESS,
  REQUEST_FOR_EDIT_CATALOG_MODERATION_FAIL,
  ACTIVATE_CATALOG_LOADER,
};
