// reducer.js
const initialState = {
  notification: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      return {
        ...state,
        notification: action.payload,
      }
    case 'HIDE_NOTIFICATION':
      return {
        ...state,
        notification: null,
      }
    default:
      return state
  }
}

export default reducer
