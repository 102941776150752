import constants from './actionTypes'
import { put, call, takeLatest, all, select } from 'redux-saga/effects'
import {
  updateBiddingDurationApi,
  getBiddingItemsApi,
  disableBiddingItemApi,
  createBiddngItemApi,
} from '../../services/ApiCalls'
import { NotificationManager } from 'react-notifications'
import {
  getBiddingItemsSuccess,
  getBiddingItems,
  setBiddingPagination,
} from './actions'
import Swal from 'sweetalert2'

// SAGA FOR UPDATING LICENEC BIDDING DURATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.LICENCE_UPDATE_BIDDING_DURATION
// PAYLOAD : NEW BIDDING DURATION
function* updateBiddingDurationSaga(action) {
  try {
    const state = yield select()
    const newBiddingDuration = yield call(
      updateBiddingDurationApi,
      action.payload
    )
    // NotificationManager.success(newBiddingDuration.message, '', 3000)
    Swal.fire({
      title: newBiddingDuration.message,
      icon: 'success', // 'success', 'error', 'warning', 'info', itd.
      confirmButtonText: 'OK',
      timer: 5000,
      timerProgressBar: true,
    })
    // NotificationManager.success(
    //   state.translation.messages.successfullAddedTaskPriceToSum[1],
    //   "",
    //   3000
    // );
  } catch (e) {
    console.log('error', e)
    // NotificationManager.error(newBiddingDuration.message, "", 2000);
  }
}

// SAGA FOR GETTING BIDDING LICENCE
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_BIDDING_ITEMS
// PAYLOAD : BIDDING FILTERS
function* getBiddingItemsSaga(action) {
  try {
    const biddingItems = yield call(getBiddingItemsApi, action.payload)
    yield put(getBiddingItemsSuccess(biddingItems.data))
    const setPaginationObj = {
      currentPage: parseInt(biddingItems.headers['x-pagination-current-page']),
      pageCount: parseInt(biddingItems.headers['x-pagination-page-count']),
      perPage: parseInt(biddingItems.headers['x-pagination-per-page']),
      totlCount: parseInt(biddingItems.headers['x-pagination-total-count']),
    }
    yield put(setBiddingPagination(setPaginationObj))
  } catch (e) {
    console.log('error', e)
    // NotificationManager.error(newBiddingDuration.message, "", 2000);
  }
}

// SAGA TO DISABLE BIDDING ITEM
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.DISABLE_BIDDING_ITEM
// PAYLOAD : BIDDING ITEM ID
function* disableBiddingItemSaga(action) {
  try {
    const disableBidding = yield call(disableBiddingItemApi, action.payload)
    const payload = {
      ...action.payload.filters,
    }
    yield put(getBiddingItems(payload))
    NotificationManager.success(disableBidding.message, '', 2000)
  } catch (e) {
    console.log('error', e)
    // NotificationManager.error(newBiddingDuration.message, "", 2000);
  }
}

// SAGA FOR CREATING BIDDING ITEM
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.CREATE_BIDDING_ITEM
// PAYLOAD : CONTENT OR CATALOG ID
function* createBiddingItemSaga(action) {
  try {
    // const state = yield select();
    // state.translation.messages.successfullAddedTaskPriceToSum[1],
    const createBiddingItem = yield call(createBiddngItemApi, action.payload)

    // NotificationManager.success(createBiddingItem.message, '', 5000)
    Swal.fire({
      title: createBiddingItem.message,
      icon: 'success', // 'success', 'error', 'warning', 'info', itd.
      confirmButtonText: 'OK',
      timer: 5000,
      timerProgressBar: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      willClose: () => {
        window.location.reload()
      },
    })
  } catch (e) {
    console.log('error', e)
    // NotificationManager.error(newBiddingDuration.message, "", 2000);
  }
}

// // SAGA FOR GETTING LICENCE BIDDING DURATION
// // RECIVES TYPE FROM ACTION
// // TYPE : constants.GET_BIDDING_DURATION
// function* getBiddingDurationSaga(action) {
//   try {
//     const biddingDuration = yield call(disableBiddingItemApi, action.payload);
//     const payload = {
//       page: 1,
//     };
//     yield put(getBiddingDurationSuccess(biddingDuration));
//     NotificationManager.success(disableBidding.message, "", 2000);
//   } catch (e) {
//     console.log("error", e);
//     // NotificationManager.error(newBiddingDuration.message, "", 2000);
//   }
// }

function* biddingSaga() {
  yield all([
    takeLatest(
      constants.LICENCE_UPDATE_BIDDING_DURATION,
      updateBiddingDurationSaga
    ),
    takeLatest(constants.GET_BIDDING_ITEMS, getBiddingItemsSaga),
    takeLatest(constants.DISABLE_BIDDING_ITEM, disableBiddingItemSaga),
    takeLatest(constants.CREATE_BIDDING_ITEM, createBiddingItemSaga),
    // takeLatest(constants.GET_BIDDING_DURATION, getBiddingDurationSaga),
  ])
}

export default biddingSaga
