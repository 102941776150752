import { all, takeLatest, call, put } from "redux-saga/effects";
import constants from "./actionTypes";
import { getLicenceTagsApi } from "../../services/ApiCalls";
import { getLicenceTagsSuccess } from "./actions";

// SAGA FOR GETTING LICENCE TAGS
// RECIVES TYPE FROM ACTION
// TYPE : constants.GET_LICENCE_TAGS
function* getLicenceTagsSaga(action) {
  try {
    const licenceTags = yield call(getLicenceTagsApi, action.payload);
    yield put(getLicenceTagsSuccess(licenceTags));
  } catch (e) {}
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* tagsSaga() {
  yield all([takeLatest(constants.GET_LICENCE_TAGS, getLicenceTagsSaga)]);
}
// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default tagsSaga;
