import constants from "./actionTypes";

//ACTION THAT INITIALIZE PAYMENT MAIL
export const sendPaymentConfirmationMail = (payload) => ({
  type: constants.SEND_PAYMENT_CONFIRMATION_MAIL,
  payload,
});

//ACTION THAT HANDLE PAYMENT SUCCESS MAIL
export const getPaymentMailSuccess = () => ({
  type: constants.GET_PAYMENT_MAIL_SUCCESS,
});

// ACTION TO INITIALIZE CHACKOUT PROCESS
// PAYLAOD IS USER AND OTHER NEEDED DATA FOR THIS PROCESS
export const initCheckoutSalesSite = (payload) => ({
  payload,
  type: constants.INIT_CHECKOUT_SALES_SITE,
});

// ACTION TO HANDLE SUCCESS CHECKOUT PROCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const initCheckoutSalesSiteSuccess = (payload) => ({
  payload,
  type: constants.INIT_CHECKOUT_SALES_SITE_SUCCESS,
});

// ACTION TO HANDLE FAIL CHECKOUT PROCESS
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const initCheckoutSalesSiteFail = (payload) => ({
  payload,
  type: constants.INIT_CHECKOUT_SALES_SITE_FAIL,
});

// ACTION TO GET TRANSACTION STATUS
// PAYLAOD TRANSACTION ID FROM THE URL
export const getTransactionData = (payload) => ({
  payload,
  type: constants.GET_TRANSACTION_DATA,
});

// SUCCESS ACTION TO GET TRANSACTION STATUS
// PAYLAOD IS TRANSACTION DATA FETCHED FROM THE SERVER
export const getTransactionDataSuccess = (payload) => ({
  payload,
  type: constants.GET_TRANSACTION_DATA_SUCCESS,
});

// FAIL ACTION TO GET TRANSACTION STATUS
// PAYLAOD IS ERROR STATUS CODE AND MESSAGE FROM THE SERVER
export const getTransactionDataFail = (payload) => ({
  payload,
  type: constants.GET_TRANSACTION_DATA_FAIL,
});
