const GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES";
const GET_ALL_TEMPLATES_SUCCESS = "GET_ALL_TEMPLATES_SUCCESS";
const GET_ALL_TEMPLATES_FAIL = "GET_ALL_TEMPLATES_FAIL";
const SET_DISPLAY_TEMPLATE_ACTION_BTNS = "SET_DISPLAY_TEMPLATE_ACTION_BTNS";

export default {
  GET_ALL_TEMPLATES,
  GET_ALL_TEMPLATES_SUCCESS,
  GET_ALL_TEMPLATES_FAIL,
  SET_DISPLAY_TEMPLATE_ACTION_BTNS,
};
