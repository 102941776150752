const GET_uSER_SEARCH_TERM = "GET_uSER_SEARCH_TERM";
const GET_uSER_SEARCH_TERM_SUCCESS = "GET_uSER_SEARCH_TERM_SUCCESS";
const GET_uSER_SEARCH_TERM_FAIL = "GET_uSER_SEARCH_TERM_FAIL";
const SET_CONTENT_SEARCH_TERM_PAGINATION = "SET_CONTENT_SEARCH_TERM_PAGINATION";

export default {
  GET_uSER_SEARCH_TERM,
  GET_uSER_SEARCH_TERM_SUCCESS,
  GET_uSER_SEARCH_TERM_FAIL,
  SET_CONTENT_SEARCH_TERM_PAGINATION,
};
