const FETCH_BLOGS = "FETCH_BLOGS";
const FETCH_BLOGS_SUCCESS = "FETCH_BLOGS_SUCCESS";
const FETCH_BLOGS_FAIL = "FETCH_BLOGS_FAIL";
const SET_PAGINATION = "SET_PAGINATION";

const SET_SELECTED_CONTENT_TYPE_CATEGORIES_BLOGS =
  "SET_SELECTED_CONTENT_TYPE_CATEGORIES_BLOGS";

const FETCH_BLOG_LIST_BY_CATEGORY_SLUG = "FETCH_BLOG_LIST_BY_CATEGORY_SLUG";
const FETCH_BLOG_LIST_BY_CATEGORY_SLUG_SUCCESS =
  "FETCH_BLOG_LIST_BY_CATEGORY_SLUG_SUCCESS";
const FETCH_BLOG_LIST_BY_CATEGORY_SLUG_FAIL =
  "FETCH_BLOG_LIST_BY_CATEGORY_SLUG_FAIL";
const FETCH_BLOGS_BY_AUTH_USER = "FETCH_BLOGS_BY_AUTH_USER";
const FETCH_BLOGS_BY_AUTH_USER_SUCCESS = "FETCH_BLOGS_BY_AUTH_USER_SUCCESS";
const FETCH_BLOGS_BY_AUTH_USER_FAIL = "FETCH_BLOGS_BY_AUTH_USER_FAIL";
const SET_PAGINATION_FOR_AUTH_USER_BLOGS = "SET_PAGINATION_FOR_AUTH_USER_BLOGS";
const ACTIVATE_BLOGS_LOADER = "ACTIVATE_BLOGS_LOADER";
export default {
  FETCH_BLOGS,
  FETCH_BLOGS_FAIL,
  FETCH_BLOGS_SUCCESS,
  SET_PAGINATION,

  SET_SELECTED_CONTENT_TYPE_CATEGORIES_BLOGS,

  FETCH_BLOG_LIST_BY_CATEGORY_SLUG,
  FETCH_BLOG_LIST_BY_CATEGORY_SLUG_SUCCESS,
  FETCH_BLOG_LIST_BY_CATEGORY_SLUG_FAIL,
  FETCH_BLOGS_BY_AUTH_USER,
  FETCH_BLOGS_BY_AUTH_USER_SUCCESS,
  FETCH_BLOGS_BY_AUTH_USER_FAIL,
  SET_PAGINATION_FOR_AUTH_USER_BLOGS,
  ACTIVATE_BLOGS_LOADER,
};
