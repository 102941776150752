import constants from "./actionTypes";
import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  adminUpdateSiteInfoDataApi,
  adminUpdateAuthGreetinMessagesApi,
  adminUpdateTermsAndConditinosApi,
} from "../../services/ApiCalls";
import { getLicenceDetails } from "../layout/actions";
import { NotificationManager } from "react-notifications";

// SAGA FOR UPDATING SITE INFO DATA
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.UPDATE_SITE_INFO_DATA
// PAYLOAD : UPDATED SITE INFO DATA THAT ADMIN ENTERED IN THE FORM
function* updateSiteInfoDataSaga(action) {
  try {
    const updatedSiteData = yield call(
      adminUpdateSiteInfoDataApi,
      action.payload
    );

    NotificationManager.success(updatedSiteData.message, "", 3000);
    const payload = [
      "terms_and_conditions",
      "sign_in_msg",
      "register_msg",
      "client_register_msg",
      "bidding_duration",
      "search_status",
      "login_registration_status",
      "button_text",
      "link_text",
      "twitter_profile_url",
      "instagram_profile_url",
      "facebook_profile_url",
    ];
    yield put(getLicenceDetails(payload));
    // console.log("updatedSiteData", updatedSiteData);
  } catch (e) {
    NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// SAGA FOR UPDATING SITE INFO DATA
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.UPDATE_AUTH_GREETING_MESSAGES
// PAYLOAD : UPDATED SITE INFO DATA THAT ADMIN ENTERED IN THE FORM
function* updateAuthGreetingMessagesSaga(action) {
  try {
    const updatedGreetingMessages = yield call(
      adminUpdateAuthGreetinMessagesApi,
      action.payload
    );
    NotificationManager.success(updatedGreetingMessages.message, "", 3000);
    const payload = [
      "terms_and_conditions",
      "sign_in_msg",
      "register_msg",
      "client_register_msg",
    ];
    yield put(getLicenceDetails(payload));
    // console.log("updatedSiteData", updatedSiteData);
  } catch (e) {
    NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// SAGA FOR UPDATING SITE INFO DATA
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.UPDATE_SITE_TERMS_AND_CONDITIONS
// PAYLOAD : UPDATED TERMS AND CONDITIONS THAT ADMIN ENTERED IN THE FORM
function* updateSiteTermsAndConditionsSaga(action) {
  try {
    const termsAndConditions = yield call(
      adminUpdateTermsAndConditinosApi,
      action.payload
    );
    NotificationManager.success(termsAndConditions.message, "", 3000);
    const payload = [
      "terms_and_conditions",
      "sign_in_msg",
      "register_msg",
      "client_register_msg",
    ];
    yield put(getLicenceDetails(payload));
    // console.log("updatedSiteData", updatedSiteData);
  } catch (e) {
    NotificationManager.error(e.response.data.message, "", 3000);
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* siteManagementSaga() {
  yield all([
    takeLatest(constants.UPDATE_SITE_INFO_DATA, updateSiteInfoDataSaga),
    takeLatest(
      constants.UPDATE_SITE_TERMS_AND_CONDITIONS,
      updateSiteTermsAndConditionsSaga
    ),
    takeLatest(
      constants.UPDATE_AUTH_GREETING_MESSAGES,
      updateAuthGreetingMessagesSaga
    ),
  ]);
}

// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default siteManagementSaga;
