import { setBlurrBg } from './actions'
import constants from './actionTypes'

const initState = {
  editMode: true,
  activeModal: {
    menu: false,
    header: false,
    body: false,
    bodyLeftSectionSingle: false,
    bodyLeftSectionMultiple: false,
    bodyMainSection: false,
    bodyRightSectionSingle: false,
    bodyRightSectionMultiple: false,
    serchModal: false,
    registerModal: false,
    loginModal: false,
    authUserModal: false,
    stockPhotoModal: false,
    moderationModal: false,
    userMgmModal: false,
    siteMgmModal: false,
    widgetModal: false,
    toggleAdminNavigation: false,
    addLicenceModal: false,
    requestPassworResetdModal: false,
    authUserDropdown: false,
    responsiveMenuNav: false,
    resetPasswordModal: true,
    adminNavigation: true,
    checkoutForm: false,
    domainPricesModal: false,
  },
  navigationModalStep: 1,
  contentTypeId: '',
  useNewCat: false,
  useNewUser: false,
  showEditCatLandingScreen: true,
  editCatModalStep: 0,
  licenceDetails: {},
  activeAuthUserModal: 'blogsModals',
  activeModerationModal: 'productModeration',
  activateUserMgmModal: 'backendUsersMgm',
  backendUsersMgmStep: 'previewUsers',
  activateSiteMgmModal: 'siteMgm',
  redirectToSinglePlanner: false,
  navHeight: 150,
  loader: false,
  moderationNumbers: {},
  activeInfoBtn: '',
  setBlurrBg: false,
}

export default (state = initState, action) => {
  const { payload, type } = action
  switch (type) {
    case constants.SET_ACTIVE_INFO_BTN:
      return {
        ...state,
        activeInfoBtn: payload,
      }
    case constants.SET_HEADER_HEIGHT:
      return {
        ...state,
        navHeight: payload,
      }
    case constants.SET_ACTIVE_SITE_MGM_MODAL:
      return {
        ...state,
        activateSiteMgmModal: payload,
      }
    case constants.SET_BACKEND_USERS_MGM_STEP:
      return {
        ...state,
        backendUsersMgmStep: payload,
      }
    case constants.GET_MODERATION_NUMBERS:
      return {
        ...state,
        loader: true,
      }
    case constants.SET_USE_NEW_USER:
      return {
        ...state,
        useNewUser: payload,
      }
    case constants.GET_MODERATION_NUMBERS_SUCCESS:
      return {
        ...state,
        loader: false,
        moderationNumbers: payload,
      }
    case constants.CLOSE_ALL_MODALS:
      return {
        ...state,
        activeModal: {
          menu: false,
          header: false,
          body: false,
          bodyLeftSectionSingle: false,
          bodyLeftSectionMultiple: false,
          bodyMainSection: false,
          bodyRightSectionSingle: false,
          bodyRightSectionMultiple: false,
          serchModal: false,
          registerModal: false,
          loginModal: false,
          authUserModal: false,
        },
      }
    // SET ACTIVE AUTH USER MODAL
    case constants.SET_ACTIVE_AUTH_USER_MODAL:
      return {
        ...state,
        activeAuthUserModal: payload,
      }

    case constants.SET_ACTIVE_MODERATION_MODAL:
      return {
        ...state,
        activeModerationModal: payload,
      }
    case constants.SET_ACTIVE_USER_MGM_MODAL:
      return {
        ...state,
        activateUserMgmModal: payload,
      }
    case constants.REDIRECT_TO_SINGLE_PLANNER:
      return {
        ...state,
        redirectToSinglePlanner: payload,
      }
    // SET EDIT MODE TO BE TRUE
    case constants.ACTIVATE_EDIT_MODE:
      return {
        ...state,
        editMode: true,
      }
    // SET EDIT MODE TO BE FALSE
    case constants.DEACTIVATE_EDIT_MODE:
      return {
        ...state,
        editMode: false,
      }
    // SET EDIT MODAL RECEIVED FROM PAYLOAD TO BE TRUE(TO BE DISPLAYED ON THE DOM)
    case constants.ACTIVATE_MODAL:
      // console.log(
      //   "state.activeModal[`${payload}`",
      //   state.activeModal[`${payload}`]
      // );
      return {
        ...state,
        activeModal: {
          ...(state.activeModal[`${payload}`] = true),
          ...state.activeModal,
        },
      }
    // SET EDIT MODAL RECEIVED FROM PAYLOAD TO BE FALSE(TO BE REMOVED FROM THE DOM)
    case constants.CLOSE_MODAL:
      return {
        ...state,
        activeModal: {
          ...(state.activeModal[`${payload}`] = false),
          ...state.activeModal,
        },
      }
    // SET CONTENT TO BE THE DATA RECIVED FROM THE SERVER
    case constants.GET_LICENCE_DETAILS_SUCCESS:
      return {
        ...state,
        licenceDetails: payload,
      }
    case constants.SET_NAVIGATION_MODAL_STEP:
      return {
        ...state,
        navigationModalStep: payload,
      }
    case constants.SET_USE_NEW_CAT:
      return {
        ...state,
        useNewCat: true,
      }

    case constants.REMOVE_USE_NEW_CAT:
      return {
        ...state,
        useNewCat: false,
      }
    case constants.SET_CONTENT_TYPE_ID:
      return {
        ...state,
        contentTypeId: payload,
      }

    case constants.SET_SHOW_EDIT_CAT_LANDING_SCREEN:
      return {
        ...state,
        showEditCatLandingScreen: payload,
      }

    case constants.SET_BLURR_BG:
      return {
        ...state,
        setBlurrBg: payload,
      }
    default:
      return {
        ...state,
      }
  }
}
