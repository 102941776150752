import constants from "./actionTypes";

const initState = {
  loader: false,
  templates: [],
  activeTemplateActionBtns: "",
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.GET_ALL_TEMPLATES:
      return {
        ...state,
        loader: true,
      };
    case constants.SET_DISPLAY_TEMPLATE_ACTION_BTNS:
      return {
        ...state,
        activeTemplateActionBtns: payload,
      };
    case constants.GET_ALL_TEMPLATES_SUCCESS:
      return {
        ...state,
        loader: false,
        templates: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
