import constants from "./actionTypes";

// ACTION FOR FETCHING SINGLE ARTICLE
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchTagRelatedContent = (payload) => ({
  payload,
  type: constants.FETCH_TAG_RELATED_CONTENT,
});

// SUCCESS ACTION FOR FETCHING SINGLE ARTICLE
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchTagRelatedContentSuccess = (payload) => ({
  payload,
  type: constants.FETCH_TAG_RELATED_CONTENT_SUCCESS,
});

// FAIL ACTION FOR FETCHING SINGLE ARTICLE
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const fetchTagRelatedContentFail = (payload) => ({
  payload,
  type: constants.FETCH_TAG_RELATED_CONTENT_FAIL,
});
