import constants from "./actionTypes";
// ACTION FOR CREATING STATIC PAGE BY THE ADMIN
// RECEVE NEW STATIC PAGE DATA FROM THE FORM THAT ADMIN ENTERED AS A PAYLOAD
export const adminCreateStaticPage = (payload) => ({
  type: constants.ADMIN_CREATE_PAGE,
  payload,
});
// SUCCESS ACTION FOR CREATING STATIC PAGE BY THE ADMIN
// RECEVE NEW STATIC PAGE DATA FROM THE SERVER AS A PAYLOAD
export const adminCreateStaticPageSuccess = (payload) => ({
  type: constants.ADMIN_CREATE_PAGE_SUCCESS,
  payload,
});
// FAIL ACTION FOR CREATING STATIC PAGE BY THE ADMIN
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER
export const adminCreateStaticPageFail = (payload) => ({
  type: constants.ADMIN_CREATE_PAGE_FAIL,
  payload,
});
// ACTION FOR CREATING STATIC PAGE DRAFT BY THE ADMIN
// RECEVE NEW STATIC PAGE DRAFT DATA FROM THE FORM THAT ADMIN ENTERED AS A PAYLOAD
export const adminCreateStaticPageDraft = (payload) => ({
  type: constants.ADMIN_CREATE_PAGE_DRAFT,
  payload,
});
// SUCCESS ACTION FOR CREATING STATIC PAGE DRAFT BY THE ADMIN
// RECEVE NEW STATIC PAGE DRAFT DATA FROM THE SERVER AS A PAYLOAD
export const adminCreateStaticPageDraftSuccess = (payload) => ({
  type: constants.ADMIN_CREATE_PAGE_DRAFT_SUCCESS,
  payload,
});
// FAIL ACTION FOR CREATING STATIC PAGE DRAFT BY THE ADMIN
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER
export const adminCreateStaticPageDraftFail = (payload) => ({
  type: constants.ADMIN_CREATE_PAGE_DRAFT_FAIL,
  payload,
});
// ACTION FOR GETTING LICECE STATIC PAGES
// RECEVE CONTENT FILTER DATA AS A PAYLOAD
export const getStaticPageList = (payload) => ({
  type: constants.GET_STATIC_PAGES_LIST,
  payload,
});
// SUCCESS ACTION FOR GETTING LICECE STATIC PAGES
// RECEVE ALL LICENCE STATIC PAGES WITH PAGINATION AS A PAYLOAD
export const getStaticPageListSuccess = (payload) => ({
  type: constants.GET_STATIC_PAGES_LIST_SUCCESS,
  payload,
});
// FAIL ACTION FOR GETTING LICECE STATIC PAGES
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER
export const getStaticPageListFail = (payload) => ({
  type: constants.GET_STATIC_PAGES_LIST_FAIL,
  payload,
});
// ACTION FOR SETTING STATIC PAGES PAGINATION
// RECEVE STATIC PAGES PAGINATION OBJECT AS A PAYLOAD
export const setStaticPagesPagination = (payload) => ({
  type: constants.SET_STATIC_PAGES_PAGINATION,
  payload,
});

// ACTION FOR UPDATING SINGE STATIC PAGE
// RECEVE UPDATED CONTENT DATA THAT ADMIN EDITED IN THE FORM
export const adminUpdateStaticPage = (payload) => ({
  type: constants.ADMIN_UPDATE_STATIC_PAGE,
  payload,
});
// SUCCESS ACTION FOR UPDATING SINGE STATIC PAGE
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminUpdateStaticPageSuccess = (payload) => ({
  type: constants.ADMIN_UPDATE_STATIC_PAGE_SUCCESS,
  payload,
});
// FAIL ACTION FOR UPDATING SINGE STATIC PAGE
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const adminUpdateStaticPageFail = (payload) => ({
  type: constants.ADMIN_UPDATE_STATIC_PAGE_FAIL,
  payload,
});

// ACTION FOR UPDATING SINGE STATIC PAGE DRAFT
// RECEVE UPDATED CONTENT DATA THAT ADMIN EDITED IN THE FORM
export const adminUpdateStaticPageDraft = (payload) => ({
  type: constants.ADMIN_UPDATE_STATIC_PAGE_DRAFT,
  payload,
});
// SUCCESS ACTION FOR UPDATING SINGE STATIC PAGE DRAFT
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminUpdateStaticPageDraftSuccess = (payload) => ({
  type: constants.ADMIN_UPDATE_STATIC_PAGE_DRAFT_SUCCESS,
  payload,
});
// FAIL ACTION FOR UPDATING SINGE STATIC PAGE DRAFT
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const adminUpdateStaticPageDraftFail = (payload) => ({
  type: constants.ADMIN_UPDATE_STATIC_PAGE_DRAFT_FAIL,
  payload,
});

// ACTION FOR PUBLISHING SINGLE STATIC PAGE DRAFT
// RECEVE UPDATED CONTENT DATA THAT ADMIN EDITED IN THE FORM
export const adminPublishStaticPageDraft = (payload) => ({
  type: constants.ADMIN_PUBLISH_STATIC_PAGE_DRAFT,
  payload,
});
// SUCCESS ACTION FOR PUBLISHING SINGLE STATIC PAGE DRAFT
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminPublishStaticPageDraftSuccess = (payload) => ({
  type: constants.ADMIN_PUBLISH_STATIC_PAGE_DRAFT_SUCCESS,
  payload,
});
// FAIL ACTION FOR PUBLISHING SINGLE STATIC PAGE DRAFT
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const adminPublishStaticPageDraftFail = (payload) => ({
  type: constants.ADMIN_PUBLISH_STATIC_PAGE_DRAFT_FAIL,
  payload,
});
// ACTION FOR ACTIVATING STATIC PAGE DRAFT
export const activateStaticPageLoader = () => ({
  type: constants.ACTIVATE_STATIC_CONTENT_LOADER,
});

// ACTION FOR DELETING STATIC PAGE
// RECEVE STATIC PAGE ID THAT NEED TO BE DELETED
export const adminDeleteStaticPage = (payload) => ({
  type: constants.ADMIN_DELETE_STATIC_PAGE,
  payload,
});
// SUCCESS ACTION FOR DELETING STATIC PAGE
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminDeleteStaticPageSuccess = (payload) => ({
  type: constants.ADMIN_DELETE_STATIC_PAGE_SUCCESS,
  payload,
});
// FAIL ACTION FOR DELETING STATIC PAGE
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const adminDeleteStaticPageFail = (payload) => ({
  type: constants.ADMIN_DELETE_STATIC_PAGE_FAIL,
  payload,
});

// ACTION FOR DELETING STATIC PAGE DRAFT
// RECEVE STATIC PAGE ID THAT NEED TO BE DELETED
export const deleteStaticPageDraft = (payload) => ({
  type: constants.DELETE_STATIC_PAGE_DRAFT,
  payload,
});
// SUCCESS ACTION FOR DELETING STATIC PAGE DRAFT
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const deleteStaticPageDraftSuccess = (payload) => ({
  type: constants.DELETE_STATIC_PAGE_DRAFT_SUCCESS,
  payload,
});
// FAIL ACTION FOR DELETING STATIC PAGE DRAFT
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const deleteStaticPageDraftFail = (payload) => ({
  type: constants.DELETE_STATIC_PAGE_DRAFT_FAIL,
  payload,
});

// ACTION FOR GETTING STATIC PAGE DETAILS
// RECEVE STATIC PAGE CATEGORIE ID THAT NEEDS TO BE FETCHED
export const getStaticPageDetails = (payload) => ({
  type: constants.GET_STATIC_PAGE_DETAILS,
  payload,
});
// SUCCESS ACTION FOR GETTING STATIC PAGE DETAILS
// RECEVE SINGLE STATIC PAGE DATA FROM THE SERVER AS A PAYLOAD
export const getStaticPageDetailsSuccess = (payload) => ({
  type: constants.GET_STATIC_PAGE_DETAILS_SUCCESS,
  payload,
});
// FAIL ACTION FOR GETTING STATIC PAGE DETAILS
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLAOD
export const getStaticPageDetailsFail = (payload) => ({
  type: constants.GET_STATIC_PAGE_DETAILS_FAIL,
  payload,
});
