import constants from "./actionTypes";

const initState = {
  loader: false,
  contentSearchTerm: [],
  pagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.GET_uSER_SEARCH_TERM:
      return {
        ...state,
        loader: true,
      };
    case constants.GET_uSER_SEARCH_TERM_SUCCESS:
      return {
        ...state,
        contentSearchTerm: payload,
        loader: false,
      };
    case constants.SET_CONTENT_SEARCH_TERM_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
