import constants from "./actionTypes";

// INITIAL STATE OF THIS REDUCER
const initState = {
  loader: false,
  tagRelatedContent: [],
};

// REDUCER FUNCTION
export default (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case constants.FETCH_TAG_RELATED_CONTENT:
      return {
        ...state,
        loader: true,
      };

    case constants.FETCH_TAG_RELATED_CONTENT_SUCCESS:
      return {
        ...state,
        tagRelatedContent: payload,
        loader: false,
      };

    case constants.FETCH_TAG_RELATED_CONTENT_FAIL:
      return {
        ...state,
        loader: false,
      };

    //  IF NONE OF THE CASE ABOVE IS TRIGGERED RETURN DEFAULT STATE
    default:
      return {
        ...state,
      };
  }
};
