import constants from "./actionTypes";
import { addToPriceSum, reducePriceSum } from "./utils";

const initState = {
  items: [],
  error: "",
  showSinglePlannerOptions: {},
  loader: false,
  priceSum: {
    RSD: 0,
    EUR: 0,
  },
  useNewTimeAndDate: false,
  redirectToPlanner: "",
  changePlannerItemClacPrice: "",
};
// INITIALIZE REDUCER FUNCTION
export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    // RESET PLANNER ITEMS
    case constants.RESET_PLANNER_ITEMS:
      return {
        ...state,
        items: [],
      };
    case constants.CHANGE_CALCULATE_PRICE:
      return {
        ...state,
        // loader: true,
      };

    case constants.ADD_NEW_TASK_TO_PLANNER:
      return {
        ...state,
        loader: true,
      };
    case constants.UPDATE_PLANNER_TASK:
      return {
        ...state,
        loader: true,
      };
    // SET CHANGE PLANNER ITEM CALC PRICE TO BE THE PLANNER ITEM ID RECIVED FROM SERVER
    case constants.CHANGE_CALCULATE_PRICE_SUCCESS:
      return {
        ...state,
        loader: false,

        changePlannerItemClacPrice: payload,
      };
    // ADD VALUE THAT IS SEND AS PAYLOAD TO THE PRICE SUM
    case constants.ADD_TO_PRICE_SUM:
      return {
        ...state,
        priceSum: addToPriceSum(state.priceSum, payload),
      };
    // REDUCE VALUE THAT IS SEND AS PAYLOAD FROM THE PRICE SUM

    case constants.REDUCE_PRICE_SUM:
      return {
        ...state,
        priceSum: reducePriceSum(state.priceSum, payload),
      };
    case constants.REDIRECT_AFTER_NEW_TASK_CREATION:
      return {
        ...state,
        redirectToPlanner: payload,
      };
    case constants.RESET_USE_TIME_AND_DATE:
      return {
        ...state,
        useNewTimeAndDate: false,
      };

    case constants.SWITCH_TO_ANOTHER_PLANNER:
      return {
        ...state,
        loader: true,
      };
    // ACTION THAT ACTIVATES LOADER
    case constants.UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME:
      return {
        ...state,
        loader: true,
      };
    // OVO VEROVATNO NE TREBA PROVERI!!!!
    case constants.UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME_SUCCESS:
      return {
        ...state,
        useNewTimeAndDate: true,
      };

    // ACTION THAT DEACTIVATES LOADER

    case constants.ADD_NEW_TASK_TO_PLANNER_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    // ACTION THAT ACTIVATES LOADER

    case constants.FETCH_SINGLE_PLANNER_ITEMS:
      return {
        ...state,
        loader: true,
      };
    // ACTION THAT DEACTIVATE LOADER AND SET NEW PLANNER ITEMS TO BE THE PAYLOAD THAT IS RECIVED FROM THE SERVER
    case constants.FETCH_SINGLE_PLANNER_ITEMS_SUCCESS:
      return {
        ...state,
        items: payload,
        loader: false,
      };
    // SET THE OBJECT THAT CONTROLS THE SINGLE PLANNER OPTIONS DISPLAY
    case constants.SET_SHOW_SINGLE_PLANNER_OPTIONS:
      return {
        ...state,
        showSinglePlannerOptions: payload,
      };
    // ACTION THAT ACTIVATES LOADER

    case constants.DELETE_PLANNER_ITEM:
      return {
        ...state,
        loader: true,
      };
    // ACTION THAT DEACTIVATES LOADER AND REMOVE DELETED PLANNER ITEM ID FROM THE ITEMS ARRAY

    case constants.DELETE_PLANNER_ITEM_SUCCESS:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload),
        loader: false,
      };
    // ACTION THAT UPDATES PRICE SUM
    case constants.SET_PRICE_SUM:
      return {
        ...state,
        priceSum: {
          ...(state.priceSum[`${payload.priceName}`] = payload.priceAmount),
          ...state.priceSum,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
