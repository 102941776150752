import constants from "./actionTypes";
import { all, takeLatest, put, call, select } from "redux-saga/effects";
import {
  adminCreateStaticPageApi,
  adminCreateStaticPageDraftApi,
  getLicenceUserBlogsApi,
  adminUpdateStaticPageApi,
  adminUpdateStaticPageDraftApi,
  adminPublishStaticPageDraftApi,
  adminDeleteStaticPageApi,
  adminDeleteStaticPageDraftApi,
} from "../../services/ApiCalls";
import {
  // adminCreateStaticPageSuccess,
  getStaticPageListSuccess,
  setStaticPagesPagination,
  activateStaticPageLoader,
  getStaticPageList,
  getStaticPageDetailsSuccess,
  adminUpdateStaticPageSuccess,
} from "./actions";
import { getLicenceContentTypeInOrder } from "../categorie/actions";
import { NotificationManager } from "react-notifications";

// SAGA FOR CREATING SINGLE STATIC PAGE
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_CREATE_PAGE
// PAYLOAD : NEW STATIC PAGE DATA THAT ADMIN ENTERED IN THE FORM
function* adminCreateStaticPageSaga(action) {
  try {
    const state = yield select();
    yield put(activateStaticPageLoader());
    yield call(adminCreateStaticPageApi, action.payload);
    NotificationManager.success(
      state.translation.messages.createStaticPageSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 4,
      page: 1,
    };
    yield put(getStaticPageList(payload));
    yield put(getLicenceContentTypeInOrder());

    // console.log("newPage", newPage);
  } catch (e) {
    console.log("e", e);
  }
}

// SAGA FOR DISAPPROVING SINGLE PRODUCT MODERATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_CREATE_PAGE_DRAFT
// PAYLOAD : DRAFT STATIC PAGE DATA THAT ADMIN ENTERED IN THE FORM
function* adminCreateStaticPageDraftSaga(action) {
  try {
    const state = yield select();
    yield call(adminCreateStaticPageDraftApi, action.payload);
    NotificationManager.success(
      state.translation.messages.saveAsDraftStaticPageSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 4,
      page: 1,
    };
    yield put(getStaticPageList(payload));
  } catch (e) {
    console.log("e", e);
  }
}

// SAGA FOR DISAPPROVING SINGLE PRODUCT MODERATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_STATIC_PAGES_LIST
// PAYLOAD : CONTENT FILTER DATA
function* getStaticPageListSaga(action) {
  try {
    yield put(activateStaticPageLoader());

    // getLicenceUserBlogsApi is called becouse of bad naming of the function that dinamicly add filter data to contents endpoint
    const pageList = yield call(getLicenceUserBlogsApi, action.payload);
    const setPaginationObj = {
      currentPage: parseInt(pageList.headers["x-pagination-current-page"]),
      pageCount: parseInt(pageList.headers["x-pagination-page-count"]),
      perPage: parseInt(pageList.headers["x-pagination-per-page"]),
      totlCount: parseInt(pageList.headers["x-pagination-total-count"]),
    };
    yield put(getStaticPageListSuccess(pageList.data));
    yield put(setStaticPagesPagination(setPaginationObj));
    // console.log("pageList", pageList);
  } catch (e) {
    console.log("e", e);
  }
}

// SAGA FOR UPDATING STATIC PAGE
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_UPDATE_STATIC_PAGE
// PAYLOAD : UPDATED STATIC PAGE DATA
function* adminUpdateStaticPageSaga(action) {
  try {
    const state = yield select();
    const updateStaticPage = yield call(
      adminUpdateStaticPageApi,
      action.payload
    );
    NotificationManager.success(
      state.translation.messages.updateStaticPageSuccessMessage[1],
      "",
      3000
    );
    yield put(getLicenceContentTypeInOrder());

    if (action.payload.fetchSingleContent) {
      // yield put(activateSingleLoader());
      yield put(getStaticPageDetailsSuccess(updateStaticPage));
      yield put(adminUpdateStaticPageSuccess());
      NotificationManager.success(action.payload.message, "", 3000);
    } else {
      const payload = {
        content_type_id: 4,
        page: 1,
      };
      yield put(getStaticPageList(payload));
    }
  } catch (e) {
    console.log("e", e);
  }
}

// SAGA FOR UPDATING STATIC PAGE
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_UPDATE_STATIC_PAGE_DRAFT
// PAYLOAD : UPDATED DRAFT STATIC PAGE DATA
function* adminUpdateStaticPageDraftSaga(action) {
  try {
    yield call(adminUpdateStaticPageDraftApi, action.payload);
    const payload = {
      content_type_id: 4,
      page: 1,
    };
    yield put(getStaticPageList(payload));
  } catch (e) {
    console.log("e", e);
  }
}

// SAGA FOR UPDATING STATIC PAGE
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_PUBLISH_STATIC_PAGE_DRAFT
// PAYLOAD : UPDATED DRAFT STATIC PAGE DATA
function* adminPublishStaticPageDraftSaga(action) {
  try {
    // const publishedDraft = yield call(
    //   adminUpdateStaticPageDraftApi,
    //   action.payload
    // );
    yield call(adminUpdateStaticPageDraftApi, action.payload);
    yield call(adminPublishStaticPageDraftApi, action.payload);
    const payload = {
      content_type_id: 4,
      page: 1,
    };
    yield put(getStaticPageList(payload));
    yield put(getLicenceContentTypeInOrder());

    // console.log("publishedDraft", publishedDraft);
  } catch (e) {
    console.log("e", e);
  }
}

// SAGA FOR DELETING STATIC PAGE
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_DELETE_STATIC_PAGE
// PAYLOAD : STATIC PAGE ID THAT NEEDS TO BE DELETED
function* adminDeleteStaticPageSaga(action) {
  try {
    yield call(adminDeleteStaticPageApi, action.payload);
    yield put(getLicenceContentTypeInOrder());
    const state = yield select();
    NotificationManager.success(
      state.translation.messages.updateStaticPageSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 4,
      page: 1,
    };
    yield put(getStaticPageList(payload));
  } catch (e) {
    console.log("e", e);
  }
}

// SAGA FOR DELETING STATIC PAGE DRAFT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.DELETE_STATIC_PAGE_DRAFT
// PAYLOAD : STATIC PAGE ID THAT NEEDS TO BE DELETED
function* deleteStaticPageDraftSaga(action) {
  try {
    yield call(adminDeleteStaticPageDraftApi, action.payload);
    const state = yield select();
    NotificationManager.success(
      state.translation.messages.updateStaticPageSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 4,
      page: 1,
    };
    yield put(getStaticPageList(payload));
  } catch (e) {
    console.log("e", e);
  }
}

// SAGA FOR DELETING STATIC PAGE DRAFT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_STATIC_PAGE_DETAILS
// PAYLOAD : STATIC PAGE CATEGORIE ID
function* getStaticPageDetailsSaga(action) {
  try {
    // getLicenceUserBlogsApi is called becouse of bad naming of the function that dinamicly add filter data to contents endpoint
    const singlePageDetails = yield call(
      getLicenceUserBlogsApi,
      action.payload
    );
    yield put(getStaticPageDetailsSuccess(singlePageDetails.data[0]));
  } catch (e) {
    console.log("e", e);
  }
}

function* staticPageSaga() {
  yield all([
    takeLatest(constants.ADMIN_CREATE_PAGE, adminCreateStaticPageSaga),
    takeLatest(constants.GET_STATIC_PAGE_DETAILS, getStaticPageDetailsSaga),
    takeLatest(
      constants.ADMIN_PUBLISH_STATIC_PAGE_DRAFT,
      adminPublishStaticPageDraftSaga
    ),
    takeLatest(
      constants.ADMIN_UPDATE_STATIC_PAGE_DRAFT,
      adminUpdateStaticPageDraftSaga
    ),
    takeLatest(constants.ADMIN_UPDATE_STATIC_PAGE, adminUpdateStaticPageSaga),
    takeLatest(constants.GET_STATIC_PAGES_LIST, getStaticPageListSaga),
    takeLatest(constants.ADMIN_DELETE_STATIC_PAGE, adminDeleteStaticPageSaga),
    takeLatest(constants.DELETE_STATIC_PAGE_DRAFT, deleteStaticPageDraftSaga),
    takeLatest(
      constants.ADMIN_CREATE_PAGE_DRAFT,
      adminCreateStaticPageDraftSaga
    ),
  ]);
}

export default staticPageSaga;
