import axios from 'axios'
import store from '../store'
import { logoutUserSuccess } from '../store/auth/actions'
import { closeAllModals, activateModal } from '../store/layout/actions'
// import { NotificationManager } from 'react-notifications'
import Swal from 'sweetalert2'

const api = axios.create({
  // baseURL: 'http://api.guru-new.local/v1',
  baseURL: 'https://apiapi.autosverc.com/v1',

  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// Response interceptor for API calls
api.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    // console.log("error", error);
    if (error.response.status === 511) {
      const state = store.getState()
      localStorage.removeItem('token')
      await store.dispatch(logoutUserSuccess())
      await store.dispatch(closeAllModals())
      await store.dispatch(activateModal('loginModal'))
      // NotificationManager.info(
      //   state.translation.messages.expiredSession[1],
      //   '',
      //   3000
      // )
      Swal.fire({
        title: state.translation.messages.expiredSession[1],
        icon: 'info', // 'success', 'error', 'warning', 'info', itd.
        confirmButtonText: 'OK',
        timer: 10000,
        timerProgressBar: true,
      })
    }
    return Promise.reject(error)
  }
)

export default api
