const UPDATE_SITE_INFO_DATA = "UPDATE_SITE_INFO_DATA";
const UPDATE_SITE_INFO_DATA_SUCCESS = "UPDATE_SITE_INFO_DATA_SUCCESS";
const UPDATE_SITE_INFO_DATA_FAIL = "UPDATE_SITE_INFO_DATA_FAIL";
const UPDATE_AUTH_GREETING_MESSAGES = "UPDATE_AUTH_GREETING_MESSAGES";
const UPDATE_AUTH_GREETING_MESSAGES_SUCCESS =
  "UPDATE_AUTH_GREETING_MESSAGES_SUCCESS";
const UPDATE_AUTH_GREETING_MESSAGES_FAIL = "UPDATE_AUTH_GREETING_MESSAGES_FAIL";
const UPDATE_SITE_TERMS_AND_CONDITIONS = "UPDATE_SITE_TERMS_AND_CONDITIONS";
const UPDATE_SITE_TERMS_AND_CONDITIONS_SUCCESS =
  "UPDATE_SITE_TERMS_AND_CONDITIONS_SUCCESS";
const UPDATE_SITE_TERMS_AND_CONDITIONS_FAIL =
  "UPDATE_SITE_TERMS_AND_CONDITIONS_FAIL";

export default {
  UPDATE_SITE_INFO_DATA,
  UPDATE_SITE_INFO_DATA_SUCCESS,
  UPDATE_SITE_INFO_DATA_FAIL,
  UPDATE_AUTH_GREETING_MESSAGES,
  UPDATE_AUTH_GREETING_MESSAGES_SUCCESS,
  UPDATE_AUTH_GREETING_MESSAGES_FAIL,
  UPDATE_SITE_TERMS_AND_CONDITIONS,
  UPDATE_SITE_TERMS_AND_CONDITIONS_SUCCESS,
  UPDATE_SITE_TERMS_AND_CONDITIONS_FAIL,
};
