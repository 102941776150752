import constants from './actionTypes'

// actions.js
export const showNotification = (message, type, interval) => ({
  type: constants.SHOW_NOTIFICATION,
  payload: { message, type, interval },
})

export const hideNotification = () => ({
  type: constants.HIDE_NOTIFICATION,
})
