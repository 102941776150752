import constants from "./actionTypes";
import { all, takeLatest, put, call } from "redux-saga/effects";
import { getAllTemplatesSuccess } from "./actions";
import { getAllTemplatesApi } from "../../services/ApiCalls";

// SAGA FOR GETTING ALL GURU TEMPLATES
// RECIVES TYPE FROM ACTION
// TYPE : constants.GET_ALL_TEMPLATES
function* getAllTemplatesSaga(action) {
  try {
    const templates = yield call(getAllTemplatesApi);
    yield put(getAllTemplatesSuccess(templates));
  } catch (e) {
    console.log("error", e);
  }
}

function* templatesSaga() {
  yield all([takeLatest(constants.GET_ALL_TEMPLATES, getAllTemplatesSaga)]);
}

export default templatesSaga;
