import { combineReducers } from 'redux'
import auth from './auth/reducer'
import blogs from './blogs/reducer'
import layout from './layout/reducer'
import categorie from './categorie/reducer'
import createAndPreviewContent from './createAndPreviewContent/reducer'
import article from './article/reducer'
import homePage from './homePage/reducer'
import products from './products/reducer'
import translation from './translation/reducer'
import plannerList from './plannerList/reducer'
import singlePlanner from './singlePlanner/reducer'
import singleBlog from './singleBlog/reducer'
import singleArticle from './singleArticle/reducer'
import singleProduct from './singleProduct/reducer'
import comments from './comments/reducer'
import catalog from './catalog/reducer'
import tags from './tags/reducer'
import tagRelatedContent from './tagRelatedContent/reducer'
import profile from './profile/reducer'
import search from './search/reducer'
import stockPhotos from './stockPhotos/reducer'
import users from './users/reducer'
import siteManagement from './siteManagement/reducer'
import widgets from './widgets/reducer'
import bidding from './bidding/reducer'
import staticPage from './staticPage/reducer'
import licence from './licence/reducer'
import templates from './templates/reducer'
import pricing from './pricing/reducer'
import checkout from './checkout/reducer'
import notificationMessages from './notificationMessages/reducer'

export default combineReducers({
  auth,
  blogs,
  layout,
  createAndPreviewContent,
  categorie,
  article,
  homePage,
  translation,
  products,
  plannerList,
  singlePlanner,
  singleBlog,
  singleArticle,
  singleProduct,
  comments,
  catalog,
  tags,
  tagRelatedContent,
  profile,
  search,
  stockPhotos,
  users,
  siteManagement,
  widgets,
  bidding,
  staticPage,
  licence,
  templates,
  pricing,
  checkout,
  notificationMessages,
})
