import constants from './actionTypes'

// ACTION FOR FETCHING SINGLE PRODUCT
// RECEVE PRODUCT SLUG AS A PAYLOAD
export const fetchSingleProduct = (payload) => ({
  payload,
  type: constants.FETCH_SINGLE_PRODUCT,
})

// SUCCESS ACTION FOR FETCHING SINGLE PRODUCT
// RECEVE SINGLE PRODUCT DATA AS A PAYLOAD
export const fetchSingleProductSuccess = (payload) => ({
  payload,
  type: constants.FETCH_SINGLE_PRODUCT_SUCCESS,
})

// FAIL ACTION FOR FETCHING SINGLE PRODUCT
// RECEVE ERROR CODE AND MESSAGE AS A PAYLOAD FROM THE SERVER
export const fetchSingleProductFail = (payload) => ({
  payload,
  type: constants.FETCH_SINGLE_PRODUCT_FAIL,
})

// ACTION FOR CREATING PRODUCT BY LICENCE CLIENT
// RECEVE NEW PRODUCT DATA AS A PAYLOAD
export const licenceClientCreateProduct = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_CREATE_PRODUCT,
})

// ACTION FOR CREATING PRODUCT BY LICENCE CLIENT SUCCESS
// RECEVE NEW PRODUCT DATA AS A PAYLOAD
export const licenceClientCreateProductSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_CREATE_PRODUCT_SUCCESS,
})

// ACTION FOR CREATING PRODUCT BY LICENCE CLIENT FALI
// RECEVE NEW PRODUCT DATA AS A PAYLOAD
export const licenceClientCreateProductFail = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_CREATE_PRODUCT_FAIL,
})

// ACTION FOR CREATING PRODUCT DRAFT BY LICENCE CLIENT
// RECEVE DRAFT PRODUCT DATA AS A PAYLOAD
export const licenceClientCreateProductDraft = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_CREATE_PRODUCT_DRAFT,
})

// ACTION FOR CREATING PRODUCT DRAFT BY LICENCE CLIENT SUCCESS
// RECEVE DRAFT PRODUCT DATA AS A PAYLOAD
export const licenceClientCreateProductDraftSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_CREATE_PRODUCT_DRAFT_SUCCESS,
})

// ACTION FOR CREATING PRODUCT DRAFT BY LICENCE CLIENT FALI
// RECEVE DRAFT PRODUCT DATA AS A PAYLOAD
export const licenceClientCreateProductDraftFail = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_CREATE_PRODUCT_DRAFT_FAIL,
})

// ACTION FOR UPDATING PRODUCT DRAFT BY LICENCE CLIENT
// RECEVE DRAFT PRODUCT DATA AS A PAYLOAD
export const licenceClientUpdateProductDraft = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT,
})

// ACTION FOR UPDATING PRODUCT DRAFT BY LICENCE CLIENT SUCCESS
// RECEVE DRAFT PRODUCT DATA AS A PAYLOAD
export const licenceClientUpdateProductDraftSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT_SUCCESS,
})

// ACTION FOR UPDATING PRODUCT DRAFT BY LICENCE CLIENT FALI
// RECEVE DRAFT PRODUCT DATA AS A PAYLOAD
export const licenceClientUpdateProductDraftFail = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_UPDATE_PRODUCT_DRAFT_FAIL,
})

// ACTION FOR PUBLISHING PRODUCT DRAFT BY LICENCE CLIENT
// RECEVE PRODUCT ID AS A PAYLOAD
export const licenceClientPublishProductDraft = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_PUBLISH_PRODUCT_DRAFT,
})

// ACTION FOR PUBLISHING PRODUCT DRAFT BY LICENCE CLIENT SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceClientPublishProductDraftSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_PUBLISH_PRODUCT_DRAFT_SUCCESS,
})

// ACTION FOR PUBLISHING PRODUCT DRAFT BY LICENCE CLIENT FALI
// RECEVE FALI STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceClientPublishProductDraftFail = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_PUBLISH_PRODUCT_DRAFT_FAIL,
})

// ACTION FOR UPDATING PRODUCT BY LICENCE CLIENT
// RECEVE PRODUCT DATA AS A PYLOAD
export const licenceClientUpdateProduct = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_UPDATE_PRODUCT,
})

// ACTION FOR UPDATING PRODUCT BY LICENCE CLIENT SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceClientUpdateProductSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_UPDATE_PRODUCT_SUCCESS,
})

// ACTION FOR UPDATING PRODUCT BY LICENCE CLIENT FALI
// RECEVE FALI STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceClientUpdateProductFail = (payload) => ({
  payload,
  type: constants.LICENCE_CLIENT_UPDATE_PRODUCT_FAIL,
})

// ACTION FOR DELETING PRODUCT  BY LICENCE CLIENT
// RECEVE PRODUCT  ID
export const deleteProduct = (payload) => ({
  payload,
  type: constants.DELETE_PRODUCT,
})

// ACTION FOR DELETING PRODUCT  BY LICENCE CLIENT SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const deleteProductSuccess = (payload) => ({
  payload,
  type: constants.DELETE_PRODUCT_SUCCESS,
})

// ACTION FOR DELETING PRODUCT  BY LICENCE CLIENT FALI
// RECEVE FALI STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const deleteProductFail = (payload) => ({
  payload,
  type: constants.DELETE_PRODUCT_FAIL,
})
// ACTION FOR ADDING SINGLE PRODUCT TO PLANNER
// RECEVE SINGLE PRODUCT ID AND PLANNER ID
export const linkProductToPlanner = (payload) => ({
  type: constants.LINK_PRODUCT_TO_PLANNER,
  payload,
})
// SUCCESS ACTION FOR ADDING SINGLE PRODUCT TO PLANNER
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const linkProductToPlannerSuccess = (payload) => ({
  type: constants.LINK_PRODUCT_TO_PLANNER_SUCCESS,
  payload,
})
// FAIL ACTION FOR ADDING SINGLE PRODUCT TO PLANNER
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const linkProductToPlannerFail = (payload) => ({
  type: constants.LINK_PRODUCT_TO_PLANNER_FAIL,
  payload,
})

// ACTION FOR CREATING PRODUCT BY ADMIN
// RECEVE SINGLE PRODUCT DATA THAT ADMIN ENTERED IN THE FORM
export const adminCreateProduct = (payload) => ({
  type: constants.ADMIN_CREATE_PRODUCT,
  payload,
})
// SUCCESS ACTION FOR CREATING PRODUCT BY ADMIN
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminCreateProductSuccess = (payload) => ({
  type: constants.ADMIN_CREATE_PRODUCT_SUCCESS,
  payload,
})
// FAIL ACTION FOR CREATING PRODUCT BY ADMIN
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminCreateProductFail = (payload) => ({
  type: constants.ADMIN_CREATE_PRODUCT_FAIL,
  payload,
})

// ACTION FOR UPDATING PRODUCT BY ADMIN
// RECEVE UPDATED PRODUCT DATA THAT ADMIN ENTERED IN THE FORM
export const adminUpdateProduct = (payload) => ({
  type: constants.ADMIN_UPDATE_PRODUCT,
  payload,
})
// SUCCESS ACTION FOR UPDATING PRODUCT BY ADMIN
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminUpdateProductSuccess = (payload) => ({
  type: constants.ADMIN_UPDATE_PRODUCT_SUCCESS,
  payload,
})
// FAIL ACTION FOR UPDATING PRODUCT BY ADMIN
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminUpdateProductFail = (payload) => ({
  type: constants.ADMIN_UPDATE_PRODUCT_FAIL,
  payload,
})

// ACTION FOR APPROVING PRODUCT MODERATION BY ADMIN
// RECEVE PRODUCT ID THAT NEEDS TO BE APPROVED AS A PAYLOAD
export const approveProductModeration = (payload) => ({
  payload,
  type: constants.APPROVE_PRODUCT_MODERATION,
})
// SUCCESS FOR APPROVING PRODUCT MODERATION BY ADMIN
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const approveProductModerationSuccess = (payload) => ({
  payload,
  type: constants.APPROVE_PRODUCT_MODERATION_SUCCESS,
})
// FAIL FOR APPROVING PRODUCT MODERATION BY ADMIN
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const approveProductModerationFail = (payload) => ({
  payload,
  type: constants.APPROVE_PRODUCT_MODERATION_FAIL,
})

// ACTION FOR APPROVING PRODUCT MODERATION BY ADMIN
// RECEVE PRODUCT ID THAT NEEDS TO BE APPROVED AS A PAYLOAD
export const disapproveProductModeration = (payload) => ({
  payload,
  type: constants.ADMIN_DISAPPROVE_PRODUCT_MODERATION,
})
// SUCCESS FOR APPROVING PRODUCT MODERATION BY ADMIN
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const disapproveProductModerationSuccess = (payload) => ({
  payload,
  type: constants.ADMIN_DISAPPROVE_PRODUCT_MODERATION_SUCCESS,
})
// FAIL FOR APPROVING PRODUCT MODERATION BY ADMIN
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const disapproveProductModerationFail = (payload) => ({
  payload,
  type: constants.ADMIN_DISAPPROVE_PRODUCT_MODERATION_FAIL,
})

// ACTION FOR REQUESTING EDIT ON PRODUCT MODERATION
// RECEVE PRODUCT ID THAT NEEDS TO BE EDITED AD REMARK MESSAGE AS A PAYLOAD
export const requestForEditProductModeration = (payload) => ({
  payload,
  type: constants.REQUEST_FOR_EDIT_PRODUCT_MODERATION,
})
// SUCCESS FOR REQUESTING EDIT ON PRODUCT MODERATION
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const requestForEditProductModerationSuccess = (payload) => ({
  payload,
  type: constants.REQUEST_FOR_EDIT_PRODUCT_MODERATION_SUCCESS,
})
// FAIL FOR REQUESTING EDIT ON PRODUCT MODERATION
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const requestForEditProductModerationFail = (payload) => ({
  payload,
  type: constants.REQUEST_FOR_EDIT_PRODUCT_MODERATION_FAIL,
})

// ACTION FOR ACTIVATING SINGLE PRUDUCT LOADER
export const activateSingleProductLoader = () => ({
  type: constants.ACTIVATE_SINGLE_PRODUCT_LOADER,
})

// ACTION FOR DELETING PRODUCT  BY LICENCE CLIENT
// RECEVE PRODUCT  ID
export const clientRenewProduct = (payload) => ({
  payload,
  type: constants.CLIENT_RENEW_PRODUCT,
})

// ACTION FOR DELETING PRODUCT  BY LICENCE CLIENT SUCCESS
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const clientRenewProductSuccess = (payload) => ({
  payload,
  type: constants.CLIENT_RENEW_PRODUCT_SUCCESS,
})

// ACTION FOR DELETING PRODUCT  BY LICENCE CLIENT FALI
// RECEVE FALI STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const clientRenewProductFail = (payload) => ({
  payload,
  type: constants.CLIENT_RENEW_PRODUCT_FAIL,
})
