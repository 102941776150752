import constants from './actionTypes'
import { call, all, put, takeLatest, delay } from 'redux-saga/effects'

import { showNotification, hideNotification } from './actions'

function* showNotificationSaga(action) {
  const { message, type, interval } = action.payload
  yield put(showNotification(message, type))
  yield delay(interval)
  yield put(hideNotification())
}

function* watchShowNotification() {
  yield all([takeLatest(constants.SHOW_NOTIFICATION, showNotificationSaga)])
}
export default watchShowNotification
