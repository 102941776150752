import constants from "./actionTypes";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getAllPlannersApi,
  deletePlannerApi,
  createNewPlannerApi,
  updatePlannerApi,
  getPublicPlannerItemsApi,
} from "../../services/ApiCalls";
import {
  getAllPlannersSuccess,
  deletePlannerSuccess,
  // setShowPlannersOptions,
  addNewPlannerSuccess,
  getAllPlanners,
  getProfilePlannersSuccess,
} from "./actions";
// import { NotificationManager } from "react-notifications";

// SAGA FOR GETTING ALL PLANNERS
// RECIVES TYPE  FROM THE ACTION
// TYPE : constants.GET_ALL_PLANNERS
function* getAllPlannersSaga(action) {
  try {
    const planners = yield call(getAllPlannersApi, action.payload);
    // console.log("planners", planners);
    // let showPlannerOptions = {};
    // planners.map((planner) => {
    //   showPlannerOptions[planner.id] = false;
    // });
    yield put(getAllPlannersSuccess(planners));
    // yield put(setShowPlannersOptions(showPlannerOptions));
  } catch (e) {
    console.log(e.message);
  }
}
// SAGA FOR DELETING PLANNER
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.DELETE_PLANNER
// PAYLOAD : PLANNER ID THAT NEEDS TO BE DELETED
function* deletePlannerSaga(action) {
  try {
    const deletePlanner = yield call(deletePlannerApi, action.payload);
    const okStatus = 200;
    if (deletePlanner.status === okStatus) {
      yield put(deletePlannerSuccess(action.payload));
    }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR CREATING NEW PLANNER
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.ADD_NEW_PLANNER
// PAYLOAD : PLANNER DATA TAHT USE ENTER IN THE FORM
function* createNewPlannerSaga(action) {
  try {
    yield call(createNewPlannerApi, action.payload);
    yield put(addNewPlannerSuccess());
    if (action.payload.fetchPlanners) {
      yield put(getAllPlanners({ user_id: action.payload.user_id }));
    }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR DELETING PLANNER
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.DELETE_PLANNER
// PAYLOAD : PLANNER ID THAT NEEDS TO BE DELETED
function* updatePlannerSaga(action) {
  try {
    yield call(updatePlannerApi, action.payload);

    if (action.payload.fetchPlanners) {
      yield put(getAllPlanners({ user_id: action.payload.user_id }));
    }
  } catch (e) {
    console.log(e.message);
  }
}

// SAGA FOR GETTING USER PUBLIC PLANNERS
// RECIVES TYPE AND PAYLOAD FROM THE ACTION
// TYPE : constants.GET_PROFILE_PUBLIC_PLANNERS
// PAYLOAD : USER ID
function* getUserPublicPlannersSaga(action) {
  try {
    const publicPlanners = yield call(getPublicPlannerItemsApi, action.payload);
    yield put(getProfilePlannersSuccess(publicPlanners));
  } catch (e) {
    console.log(e.message);
  }
}

function* plannerSaga() {
  yield all([
    takeLatest(constants.GET_ALL_PLANNERS, getAllPlannersSaga),
    takeLatest(constants.DELETE_PLANNER, deletePlannerSaga),
    takeLatest(constants.ADD_NEW_PLANNER, createNewPlannerSaga),
    takeLatest(constants.UPDATE_PLANNER, updatePlannerSaga),
    takeLatest(
      constants.GET_PROFILE_PUBLIC_PLANNERS,
      getUserPublicPlannersSaga
    ),
  ]);
}

export default plannerSaga;
