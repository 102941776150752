import constants from "./actionTypes";
// ACTION FOR FETCHING SINGLE PLANNER ITEMS FROM SERVER
// RECIVES SINGLE ITEM ID AS A PAYLOAD
export const fetchSinglePlannerItems = (payload) => ({
  payload,
  type: constants.FETCH_SINGLE_PLANNER_ITEMS,
});
// ACTION FOR SUCCESS FETCHING SINGLE PLANNER ITEMS FROM SERVER
// RECIVES FETCHED ITEMS FROM SERVER AS A PAYLOAD
export const fetchSinglePlannerItemsSuccess = (payload) => ({
  payload,
  type: constants.FETCH_SINGLE_PLANNER_ITEMS_SUCCESS,
});
// ACTION FOR FAIL FETCHING SINGLE PLANNER ITEMS FROM SERVER
// RECIVES ERROR STATUS AND MESSAGE FROM SERVER AS A PYLOAD
export const fetchSinglePlannerItemsFail = (payload) => ({
  payload,
  type: constants.FETCH_SINGLE_PLANNER_ITEMS_FAIL,
});
// ACTION FOR RESETING SINGLE PLANNER ITEMS
export const resetPlannerItems = () => ({
  type: constants.RESET_PLANNER_ITEMS,
});
// // ACTION FOR SETTIG THE OBJECT THAT CONTROLES SHOWING PLANNER ITEM OPTIONS
// // RECIVES OBJECT WITH PARAMS OF PLANNER ITEM IDS AND VALUE FALSE
// export const setShowSinglePlannerOptions = (payload) => ({
//   type: constants.SET_SHOW_SINGLE_PLANNER_OPTIONS,
//   payload,
// });
// ACTION FOR DELETING PLANNER ITEM FROM SINGLE PLANNER LIST
// RECIVES PLANNER ITEM ID AS A PAYLOAD THAT NEEDS TO BE DELETED FROM SERVER
export const deletePlannerItem = (payload) => ({
  type: constants.DELETE_PLANNER_ITEM,
  payload,
});
// ACTION FOR SUCCESS REMOVE OF THE PLANNER ITEM FROM SERVER
// RECIVES SUCCESS STATUS AND MESSAGE FROM THE SERVER AS PAYLOAD
export const deletePlannerItemSuccess = (payload) => ({
  type: constants.DELETE_PLANNER_ITEM_SUCCESS,
  payload,
});
// ACTION FOR FAIL REMOVE OF THE PLANNER ITEM FROM SERVER
// RECIVES FAIL STATUS AND MESSAGE FROM THE SERVER AS PAYLOAD
export const deletePlannerItemFail = (payload) => ({
  type: constants.DELETE_PLANNER_ITEM_FAIL,
  payload,
});

export const switchToAnotherPlanner = (payload) => ({
  type: constants.SWITCH_TO_ANOTHER_PLANNER,
  payload,
});

export const switchToAnotherPlannerSuccess = (payload) => ({
  type: constants.SWITCH_TO_ANOTHER_PLANNER_SUCCESS,
  payload,
});

export const switchToAnotherPlannerFail = (payload) => ({
  type: constants.SWITCH_TO_ANOTHER_PLANNER_FAIL,
  payload,
});
// ACTION FOR SETTING PRICE SUM OF THE PLANNER ITEMS
// RECIVES OBJECT WHIT PROPERTY OF THE PRICE NAME AND VALUE IS AMMOUT FOR THAT PRICE
export const setPriceSum = (payload) => ({
  type: constants.SET_PRICE_SUM,
  payload,
});
// ACTION FOR ADDING NEW TASK TO PLANNER
// RECIVES TASK DATA AS PAYLOAD
export const addNewtaskToPlanner = (payload) => ({
  type: constants.ADD_NEW_TASK_TO_PLANNER,
  payload,
});
// ACTION FOR SUCCESSFULLY CREATING NEW PLANNER TASK
// RECIVES SUCCESS STATUS AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const addNewtaskToPlannerSuccess = (payload) => ({
  type: constants.ADD_NEW_TASK_TO_PLANNER_SUCCESS,
  payload,
});
// ACTION FOR UNSUCCESSFULLY CREATING NEW PLANNER TASK
// RECIVES FAIL STATUS AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const addNewtaskToPlannerFail = (payload) => ({
  type: constants.ADD_NEW_TASK_TO_PLANNER_FAI,
  payload,
}); // ACTION FOR UPDATING PLANNER TASK
// RECIVES TASK DATA AS PAYLOAD
export const updatePlannerTask = (payload) => ({
  type: constants.UPDATE_PLANNER_TASK,
  payload,
});
// ACTION FOR SUCCESSFULLY UPDATINGS PLANNER TASK
// RECIVES SUCCESS STATUS AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const updatePlannerTaskSuccess = (payload) => ({
  type: constants.UPDATE_PLANNER_TASK_SUCCESS,
  payload,
});
// ACTION FOR UNSUCCESSFULLY UPDATINGS  PLANNER TASK
// RECIVES FAIL STATUS AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const updatePlannerTaskFail = (payload) => ({
  type: constants.UPDATE_PLANNER_TASK_FAI,
  payload,
});
// ACTION FROM TRIGGERING PLANNER ITEM DATE AND TIME UPDATE
// RECIVES NEW TIME AND DATE AS A PAYLOAD
export const updatePlannerItemDateAndTime = (payload) => ({
  type: constants.UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME,
  payload,
});
// ACTION FOR SUCCESSFUL UPDATE OF THE PLANNER ITEM DATA AND TIME
// RECIVES SUCCESS STATUS AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const updatePlannerItemDateAndTimeSuccess = (payload) => ({
  type: constants.UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME_SUCCESS,
  payload,
});
// ACTION FOR UNSUCCESSFUL UPDATE OF THE PLANNER ITEM DATA AND TIME
// RECIVES ERROR STATUS AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const updatePlannerItemDateAndTimeFail = (payload) => ({
  type: constants.UPDATE_PLANNER_ITEM_SCHADULED_DATE_TIME_FAIL,
  payload,
});

// export const resetUseTimeAndDate = () => ({
//   type: constants.RESET_USE_TIME_AND_DATE,
// });
// ACTION FROM TRIGGERING PLANNER ITEM PRICE UPDATE
// RECIVES NEW TIME AND DATE AS A PAYLOAD
export const changePlannerItemPrice = (payload) => ({
  type: constants.CHANGE_PLANNER_ITEM_PRICE,
  payload,
});
// ACTION FOR SUCCESSFUL UPDATE OF THE PLANNER ITEM PRICE
// RECIVES SUCCESS STATUS AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const changePlannerItemPriceSuccess = (payload) => ({
  type: constants.CHANGE_PLANNER_ITEM_PRICE_SUCCESS,
  payload,
});
// ACTION FOR UNSUCCESSFUL UPDATE OF THE PLANNER ITEM PRICE
// RECIVES ERROR STATUS AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const changePlannerItemPriceFail = (payload) => ({
  type: constants.CHANGE_PLANNER_ITEM_PRICE_FAIL,
  payload,
});

// ACTION FROM TRIGGERING PLANNER ITEM REMARK UPDATE
// RECIVES NEW REMARK AND PLANNER ITEM ID AS A PAYLOAD
export const changePlannerItemRemark = (payload) => ({
  type: constants.SAVE_PLANNER_ITEM_REMARK,
  payload,
});
// ACTION FOR SUCCESSFUL UPDATE OF THE PLANNER ITEM REMARK
// RECIVES SUCCESS STATUS AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const changePlannerItemRemarkSuccess = (payload) => ({
  type: constants.SAVE_PLANNER_ITEM_REMARK_SUCCESS,
  payload,
});
// ACTION FOR UNSUCCESSFUL UPDATE OF THE PLANNER ITEM REMARK
// RECIVES ERROR STATUS AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const changePlannerItemRemarkFail = (payload) => ({
  type: constants.SAVE_PLANNER_ITEM_REMARK_FAIL,
  payload,
});

// // ACTION FROM SETTING REDIRECTION OBJECT IN STATE
// // RECIVES NEW TASK DATA AS A PAYLOAD
// export const redirectAfterNewTaskCreation = (payload) => ({
//   type: constants.REDIRECT_AFTER_NEW_TASK_CREATION,
//   payload,
// });
// ACTION THAT ADD TO PRICE SUM
// RECIVES PLANNER ITEM PRICE AMMOUNT THAT NEEDS TO BE ADDED TO THE PRICE SUM
export const addToPriceSum = (payload) => ({
  type: constants.ADD_TO_PRICE_SUM,
  payload,
});
// ACTION THAT REDUCE PRICE SUM
// RECIVES PLANNER ITEM PRICE AMMOUNT THAT NEEDS TO BE REDUCED FROM THE PRICE SUM
export const reducePriceSum = (payload) => ({
  type: constants.REDUCE_PRICE_SUM,
  payload,
});
// ACTION THAT TRIGGERS CHANGING OF THE CALCULATE PRICE STATUS
// RECIVES THE OBJECT WITH THE PLANNER ITEM DATA AND CALCULATE PRICE STATUS
export const changeCalculatePrice = (payload) => ({
  type: constants.CHANGE_CALCULATE_PRICE,
  payload,
});
// ACTION FOR CHANGE CALCULATE PRICE STATUS SUCCESS
// RECIVES PLANNER ITEM ID FROM THE SERVER AS A PAYLOAD
export const changeCalculatePriceSuccess = (payload) => ({
  type: constants.CHANGE_CALCULATE_PRICE_SUCCESS,
  payload,
});
// ACTION FOR CHANGE CALCULATE PRICE STATUS FAIL
// RECIVES STATUS CODE AND ERROR MESSAGE FROM THE SERVER
export const changeCalculatePriceFail = (payload) => ({
  type: constants.CHANGE_CALCULATE_PRICE_FAIL,
  payload,
});

// ACTION THAT TRIGGERS CHANGING OF THE PLANNER ITEM SHOW PRICE PUBLICLY STATUS
// RECIVES THE OBJECT WITH THE PLANNER ITEM DATA AND CALCULATE PRICE STATUS
export const showPlannerItemPricePublicly = (payload) => ({
  type: constants.SHOW_PLANNER_ITEM_PRICE_PUBLICLY,
  payload,
});
// SUCCESS ACTION THAT TRIGGERS CHANGING OF THE PLANNER ITEM SHOW PRICE PUBLICLY STATUS
// RECIVES PLANNER ITEM ID FROM THE SERVER AS A PAYLOAD
export const showPlannerItemPricePubliclySuccess = (payload) => ({
  type: constants.SHOW_PLANNER_ITEM_PRICE_PUBLICLY_SUCCESS,
  payload,
});
// FAIL ACTION THAT TRIGGERS CHANGING OF THE PLANNER ITEM SHOW PRICE PUBLICLY STATUS
// RECIVES STATUS CODE AND ERROR MESSAGE FROM THE SERVER
export const showPlannerItemPricePubliclyFail = (payload) => ({
  type: constants.SHOW_PLANNER_ITEM_PRICE_PUBLICLY_FAIL,
  payload,
});

// ACTION THAT TRIGGERS CHANGING OF THE SHOW PUBLICLY PLANNER ITEM STATUS
// RECIVES THE OBJECT WITH THE PLANNER ITEM DATA AND CALCULATE PRICE STATUS
export const showPlannerItemPublicly = (payload) => ({
  type: constants.SHOW_PLANNER_ITEM_PUBLICLY,
  payload,
});
// SUCCESS ACTION THAT TRIGGERS CHANGING OF THE SHOW PUBLICLY PLANNER ITEM STATUS
// RECIVES PLANNER ITEM ID FROM THE SERVER AS A PAYLOAD
export const showPlannerItemPubliclySuccess = (payload) => ({
  type: constants.SHOW_PLANNER_ITEM_PUBLICLY_SUCCESS,
  payload,
});
// FAIL ACTION THAT TRIGGERS CHANGING OF THE SHOW PUBLICLY PLANNER ITEM STATUS
// RECIVES STATUS CODE AND ERROR MESSAGE FROM THE SERVER
export const showPlannerItemPubliclyFail = (payload) => ({
  type: constants.SHOW_PLANNER_ITEM_PUBLICLY_FAIL,
  payload,
});

// ACTION THAT TOGGLES PLANNER PUBLIC STATUS
// RECIVES PLANNER ID THAT NEEDS TO BE TOGGLED AS A PAYLOAD
export const togglePublicPlanner = (payload) => ({
  type: constants.TOGGLE_PUBLIC_PLANNER,
  payload,
});
// SUCCESS ACTION THAT TOGGLES PLANNER PUBLIC STATUS
// RECIVES SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const togglePublicPlannerSuccess = (payload) => ({
  type: constants.TOGGLE_PUBLIC_PLANNER_SUCCESS,
  payload,
});
// FAIL ACTION THAT TRIGGERS CHANGING OF THE SHOW PUBLICLY PLANNER ITEM STATUS
// RECIVES STATUS CODE AND ERROR MESSAGE FROM THE SERVER
export const togglePublicPlannerFail = (payload) => ({
  type: constants.TOGGLE_PUBLIC_PLANNER_FAIL,
  payload,
});
