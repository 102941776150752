import { all, takeLatest, call, put } from 'redux-saga/effects'
import constants from './actionTypes'
import {
  fetchProductsAndCatalogsByCategorieIdApi,
  getCategoryBySlugApi,
  getLoginLicenceClientProductsApi,
  getProductsByCatalogNoPagApi,
  getAuthClientProductsNoPaginationApi,
  fetchFeaturedProductsApi,
  fetchFeaturedProductsByBiddingIdApi,
  searchCarApi,
} from '../../services/ApiCalls'
import {
  setPagination,
  fetchProductListByCategorySlugSuccess,
  fetchProductsByAuthClientSuccess,
  getProductsByCatalogIdNoPagSuccess,
  setPaginationForAuthUserProducts,
  getAuthClientProductsNoPaginationSuccess,
  fetchFeaturedProductsSuccess,
  fetchFeaturedProductsByBiddingIdsSuccess,
  searchCarSuccess,
} from './actions'

function* fetchProductListByCategorySlugSaga(action) {
  try {
    const category = yield call(getCategoryBySlugApi, action.payload)
    const payload2 = {
      categoryId: category.data[0].id,
      page: action.payload.page,
      page_count: action.payload.page_count,
    }
    const productList = yield call(
      fetchProductsAndCatalogsByCategorieIdApi,
      payload2
    )
    const data = [category, productList]
    const setPaginationObj = {
      currentPage: parseInt(productList.headers['x-pagination-current-page']),
      pageCount: parseInt(productList.headers['x-pagination-page-count']),
      perPage: parseInt(productList.headers['x-pagination-per-page']),
      totlCount: parseInt(productList.headers['x-pagination-total-count']),
    }
    yield put(fetchProductListByCategorySlugSuccess(data))
    yield put(setPagination(setPaginationObj))
  } catch (e) {
    console.log(e)
  }
}

// SAGA FOR FETCHING FEATURED PRODUCTS
// RECIVES TYPE FROM ACTION
// TYPE : constants.FETCH_FEATURED_PRODUCTS
function* fetchFeaturedProductsSaga() {
  try {
    const products = yield call(fetchFeaturedProductsApi)

    // console.log('IZ ZAGE PRODUTSSSSSSSSSSSSSSSSSSSSS--------', products)
    const setPaginationObj = {
      currentPage: parseInt(products.headers['x-pagination-current-page']),
      pageCount: parseInt(products.headers['x-pagination-page-count']),
      perPage: parseInt(products.headers['x-pagination-per-page']),
      totlCount: parseInt(products.headers['x-pagination-total-count']),
    }
    yield put(setPagination(setPaginationObj))
    yield put(setPaginationForAuthUserProducts(setPaginationObj))

    yield put(fetchFeaturedProductsSuccess(products.data))
  } catch (e) {
    console.log(e.message)
  }
}

// SAGA FOR FETCHING FEATURED PRODUCTS
// RECIVES TYPE FROM ACTION
// TYPE : constants.FETCH_FEATURED_PRODUCTS
// function* searchCarSaga(action) {
//   try {
//     const searchedCar = yield call(searchCarApi, action.payload)

//     console.log('IZ ZAGE PRODUTSSSSSSSSSSSSSSSSSSSSS--------', searchedCar)
//     const setPaginationObj = {
//       currentPage: parseInt(searchedCar.headers['x-pagination-current-page']),
//       pageCount: parseInt(searchedCar.headers['x-pagination-page-count']),
//       perPage: parseInt(searchedCar.headers['x-pagination-per-page']),
//       totlCount: parseInt(searchedCar.headers['x-pagination-total-count']),
//     }

//     console.log('setPaginationObj', setPaginationObj)

//     yield put(searchCarSuccess(searchedCar.data))
//     yield put(setPagination(setPaginationObj))
//   } catch (e) {
//     console.log(e.message)
//   }
// }

// OVO RADI ZA SERVIS SEARCHCAR ONO GORE KAD SE PREKO FILTERA VRACA IZ KONTROLERA
function* searchCarSaga(action) {
  try {
    const searchedCar = yield call(searchCarApi, action.payload)

    console.log('IZ ZAGE PRODUTSSSSSSSSSSSSSSSSSSSSS--------', searchedCar)
    const setPaginationObj = {
      currentPage: parseInt(searchedCar.data.pagination['currentPage']),
      pageCount: parseInt(searchedCar.data.pagination['pageCount']),
      perPage: parseInt(searchedCar.data.pagination['perPage']),
      totlCount: parseInt(searchedCar.data.pagination['totalCount']),
    }

    console.log('setPaginationObj', setPaginationObj)

    yield put(searchCarSuccess(searchedCar.data.contentModels))
    yield put(setPagination(setPaginationObj))
  } catch (e) {
    console.log(e.message)
  }
}

// SAGA FOR FETCHING FEATURED PRODUCTS
// RECIVES TYPE FROM ACTION
// TYPE : constants.FETCH_FEATURED_PRODUCTS
function* fetchFeaturedProductsByBiddingIdsApiSaga() {
  try {
    const featuredProducts = yield call(fetchFeaturedProductsByBiddingIdApi)

    const setPaginationObj = {
      currentPage: parseInt(
        featuredProducts.headers['x-pagination-current-page']
      ),
      pageCount: parseInt(featuredProducts.headers['x-pagination-page-count']),
      perPage: parseInt(featuredProducts.headers['x-pagination-per-page']),
      totlCount: parseInt(featuredProducts.headers['x-pagination-total-count']),
    }
    yield put(setPagination(setPaginationObj))
    yield put(setPaginationForAuthUserProducts(setPaginationObj))

    yield put(
      fetchFeaturedProductsByBiddingIdsSuccess(
        featuredProducts.data.contentModels
      )
    )
  } catch (e) {
    console.log(e.message)
  }
}

// SAGA FOR FETCHING PRODUCTS BY AUTH CLIENT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.FETCH_PRODUCTS_BY_AUTH_CLIENT
// PAYLOAD : OBJECT WITH FILTER DATA
function* fetchProductsByAuthClientSaga(action) {
  try {
    const authClientProducts = yield call(
      getLoginLicenceClientProductsApi,
      action.payload
    )
    const setPaginationObj = {
      currentPage: parseInt(
        authClientProducts.headers['x-pagination-current-page']
      ),
      pageCount: parseInt(
        authClientProducts.headers['x-pagination-page-count']
      ),
      perPage: parseInt(authClientProducts.headers['x-pagination-per-page']),
      totlCount: parseInt(
        authClientProducts.headers['x-pagination-total-count']
      ),
    }
    yield put(setPaginationForAuthUserProducts(setPaginationObj))

    const products = authClientProducts.data
    products.map((product) => (product.name = product.title))

    // console.log("authUserBlogs", authUserBlogs);
    yield put(setPaginationForAuthUserProducts(setPaginationObj))
    yield put(fetchProductsByAuthClientSuccess(products))
  } catch (e) {
    console.log(e.message)
  }
}

// SAGA FOR FETCHING PRODUCTS BY CATALOG ID
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_PRODUCTS_BY_CATALOG_ID
// PAYLOAD : CATALOG ID
function* getProductsByCatalogIdNoPaginationSaga(action) {
  try {
    const productsByCatalog = yield call(
      getProductsByCatalogNoPagApi,
      action.payload
    )
    yield put(getProductsByCatalogIdNoPagSuccess(productsByCatalog))
  } catch (e) {
    console.log(e.message)
  }
}

// SAGA FOR FETCHING AUTH CLIENT PRODUCTS WITH NO PAGINATION
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION
// PAYLOAD : CATALOG ID
function* fetchAuthClientProductsNoPaginationSaga(action) {
  try {
    const noPagProducts = yield call(
      getAuthClientProductsNoPaginationApi,
      action.payload
    )
    yield put(getAuthClientProductsNoPaginationSuccess(noPagProducts))
  } catch (e) {
    console.log(e.message)
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* productSaga() {
  yield all([
    takeLatest(
      constants.FETCH_PRODUCT_LIST_BY_CATEGORY_SLUG,
      fetchProductListByCategorySlugSaga
    ),
    takeLatest(
      constants.FETCH_PRODUCTS_BY_AUTH_CLIENT,
      fetchProductsByAuthClientSaga
    ),
    takeLatest(
      constants.GET_PRODUCTS_BY_CATALOG_ID_NO_PAG,
      getProductsByCatalogIdNoPaginationSaga
    ),
    takeLatest(
      constants.FETCH_AUTH_CLIENT_PRODUCTS_NO_PAGINATION,
      fetchAuthClientProductsNoPaginationSaga
    ),
    takeLatest(constants.FETCH_FEATURED_PRODUCTS, fetchFeaturedProductsSaga),
    takeLatest(
      constants.FETCH_FEATURED_PRODUCTS_BY_BIDDING_IDS,
      fetchFeaturedProductsByBiddingIdsApiSaga
    ),
    takeLatest(constants.SEARCH_CAR, searchCarSaga),
  ])
}
// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default productSaga
