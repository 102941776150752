import constants from "./actionTypes";

// ACTION FOR CREATING BLOG BY LICENCE USER
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const licenceUserCreateBlog = (payload) => ({
  payload,
  type: constants.LICENCE_USER_CREATE_BLOG,
});

// ACTION FOR CREATING BLOG BY LICENCE USER SUCCESS
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserCreateBlogSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_USER_CREATE_BLOG_SUCCESS,
});

// ACTION FOR CREATING BLOG BY LICENCE USER FAIL
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserCreateBlogFail = (payload) => ({
  payload,
  type: constants.LICENCE_USER_CREATE_BLOG_FAIL,
});

// ACTION FOR GETTING LICENCE TAGS
export const getLicenceTags = () => ({
  type: constants.GET_LICENCE_TAGS,
});
// ACTION FOR GETTING LICENCE TAGS SUCCESS
// RECEVE ARRAY OF TAGS AS A PAYLOAD FROM THE SERVER
export const getLicenceTagsSuccess = (payload) => ({
  payload,
  type: constants.GET_LICENCE_TAGS_SUCCESS,
});

// ACTION FOR GETTING LICENCE TAGS FAIL
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getLicenceTagsFail = (payload) => ({
  payload,
  type: constants.GET_LICENCE_TAGS_FAIL,
});

// ACTION FOR DELETING BLOG BY THE LICENCE USER
// RECIVE BLOG ID THAT NEEDS TO BE DELETED AS A PAYLOAD
export const licenceUserDeleteBlog = (payload) => ({
  type: constants.LICENCE_USER_DELETE_BLOG,
  payload,
});
// ACTION FOR DELETING BLOG BY THE LICENCE USER SUCCESS
// STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserDeleteBlogSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_USER_DELETE_BLOG_SUCCESS,
});

// ACTION FOR DELETING BLOG BY THE LICENCE USER FAIL
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserDeleteBlogFail = (payload) => ({
  payload,
  type: constants.LICENCE_USER_DELETE_BLOG_FAIL,
});

// ACTION FOR UPDATING BLOG BY LICENCE USER
// RECEVE NEW BLOG DATA AS A PAYLOAD
export const licenceUserUpdateBlog = (payload) => ({
  payload,
  type: constants.LICENCE_USER_UPDATE_BLOG,
});

// ACTION FOR UPDATING BLOG BY LICENCE USER SUCCESS
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserUpdateBlogSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_USER_UPDATE_BLOG_SUCCESS,
});

// ACTION FOR UPDATING BLOG BY LICENCE USER FAIL
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserUpdateBlogFail = (payload) => ({
  payload,
  type: constants.LICENCE_USER_UPDATE_BLOG_FAIL,
});

// ACTION FOR TRIGGERING BLOG UPDATE BY THE ADMIN
// RECIVE UPDATED BLOG DATA FROM THE FORM AS A PAYLOAD
export const adminUpdateBlog = (payload) => ({
  payload,
  type: constants.ADMIN_UPDATE_BLOG,
});
// ACTION FOR SUCCESSFYLLY UPDATING BLOG BY THE ADMIN
// RECIVE BLOG DATA FROM THE SERVER AS A PAYLOAD
export const adminUpdateBlogSuccess = (payload) => ({
  payload,
  type: constants.ADMIN_UPDATE_BLOG_SUCCESS,
});
// ACTION FOR  UPDATING BLOG BY THE ADMIN FAIL
// RECIVE ERROR MESSAGE AND THE STATUS CODE AS A PAYLOAD
export const adminUpdateBlogFail = (payload) => ({
  payload,
  type: constants.ADMIN_UPDATE_BLOG_FAIL,
});

// ACTION FOR GETTING SINGLE BLOG
// RECEVE BLOG ID FROM THE URL
export const getSingleBlog = (payload) => ({
  payload,
  type: constants.GET_SINGLE_BLOG,
});
// ACTION FOR GETTING SINGLE BLOG SUCCESS
// RECEVE BLOG DATA FROM THE SERVER AS A PAYLOAD
export const getSingleBlogSuccess = (payload) => ({
  payload,
  type: constants.GET_SINGLE_BLOG_SUCCESS,
});
// ACTION FOR GETTING SINGLE BLOG FAIL
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const getSingleBlogFail = (payload) => ({
  payload,
  type: constants.GET_SINGLE_BLOG_FAIL,
});

// ACTION FOR GETTING SINGLE BLOG
// RECEVE BLOG ID FROM THE URL
export const getSingleBlogBySlug = (payload) => ({
  payload,
  type: constants.GET_SINGLE_BLOG_BY_SLUG,
});
// ACTION FOR GETTING SINGLE BLOG SUCCESS
// RECEVE BLOG DATA FROM THE SERVER AS A PAYLOAD
export const getSingleBlogBySlugSuccess = (payload) => ({
  payload,
  type: constants.GET_SINGLE_BLOG_BY_SLUG_SUCCESS,
});
// ACTION FOR GETTING SINGLE BLOG FAIL
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const getSingleBlogBySlugFail = (payload) => ({
  payload,
  type: constants.GET_SINGLE_BLOG_BY_SLUG_FAIL,
});

// ACTION FOR PUBLISHING BLOG DRAFT
// RECEVE BLOG ID FROM AS A PAYLOAD
export const publishBlogDraft = (payload) => ({
  payload,
  type: constants.PUBLISH_BLOG_DRAFT,
});
// ACTION FOR PUBLISHING BLOG DRAFT SUCCESS
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const publishBlogDraftSuccess = (payload) => ({
  payload,
  type: constants.PUBLISH_BLOG_DRAFT_SUCCESS,
});
// ACTION FOR PUBLISHING BLOG DRAFT FAIL
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const publishBlogDraftFail = (payload) => ({
  payload,
  type: constants.PUBLISH_BLOG_DRAFT_FAIL,
});

// ACTION FOR CREATING BLOG DRAFT BY LICENCE USER
// RECEVE BLOG DATA FROM THE FORM AS A PAYLOAD
export const licenceUserCreateBlogDraft = (payload) => ({
  payload,
  type: constants.LICENCE_USER_CREATE_BLOG_DRAFT,
});
// SUCCESS ACTION FOR CREATING BLOG DRAFT BY LICENCE USER
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const licenceUserCreateBlogDraftSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_USER_CREATE_BLOG_DRAFT_SUCCESS,
});
// FAIL ACTION FOR CREATING BLOG DRAFT BY LICENCE USER
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const licenceUserCreateBlogDraftFail = (payload) => ({
  payload,
  type: constants.LICENCE_USER_CREATE_BLOG_DRAFT_FAIL,
});

// ACTION FOR CREATING BLOG BY ADMIN
// RECEVE BLOG DATA FROM THE FORM AS A PAYLOAD
export const adminCreateBlog = (payload) => ({
  payload,
  type: constants.ADMIN_CREATE_BLOG,
});
// SUCCESS ACTION FOR CREATING BLOG BY ADMIN
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const adminCreateBlogSuccess = (payload) => ({
  payload,
  type: constants.ADMIN_CREATE_BLOG_SUCCESS,
});
// FAIL ACTION FOR CREATING BLOG BY ADMIN
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const adminCreateBlogFail = (payload) => ({
  payload,
  type: constants.ADMIN_CREATE_BLOG_FAIL,
});

// ACTION FOR UPDATING BLOG DRAFT
// RECEVE BLOG DRAFT DATA AS A PAYLOAD
export const licenceUserUpdateBlogDraft = (payload) => ({
  payload,
  type: constants.LICENCE_USER_UPDATE_BLOG_DRAFT,
});

// SUCCESS ACTION FOR UPDATING BLOG DRAFT
// RECEVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserUpdateBlogDraftSuccess = (payload) => ({
  payload,
  type: constants.LICENCE_USER_UPDATE_BLOG_DRAFT_SUCCESS,
});

// FAIL ACTION FOR UPDATING BLOG DRAFT
// RECEVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const licenceUserUpdateBlogDraftFail = (payload) => ({
  payload,
  type: constants.LICENCE_USER_UPDATE_BLOG_DRAFT_FAIL,
});

// ACTION FOR APPROVING BLOG MODERATION BY ADMIN
// RECEVE BLOG ID THAT NEEDS TO BE APPROVED AS A PAYLOAD
export const approveBlogModeration = (payload) => ({
  payload,
  type: constants.APPROVE_BLOG_MODERATION,
});
// SUCCESS FOR APPROVING BLOG MODERATION BY ADMIN
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const approveBlogModerationSuccess = (payload) => ({
  payload,
  type: constants.APPROVE_BLOG_MODERATION_SUCCESS,
});
// FAIL FOR APPROVING BLOG MODERATION BY ADMIN
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const approveBlogModerationFail = (payload) => ({
  payload,
  type: constants.APPROVE_BLOG_MODERATION_FAIL,
});

// ACTION FOR APPROVING BLOG MODERATION BY ADMIN
// RECEVE BLOG ID THAT NEEDS TO BE APPROVED AS A PAYLOAD
export const disapproveBlogModeration = (payload) => ({
  payload,
  type: constants.ADMIN_DISAPPROVE_BLOG_MODERATION,
});
// SUCCESS FOR APPROVING BLOG MODERATION BY ADMIN
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const disapproveBlogModerationSuccess = (payload) => ({
  payload,
  type: constants.ADMIN_DISAPPROVE_BLOG_MODERATION_SUCCESS,
});
// FAIL FOR APPROVING BLOG MODERATION BY ADMIN
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const disapproveBlogModerationFail = (payload) => ({
  payload,
  type: constants.ADMIN_DISAPPROVE_BLOG_MODERATION_FAIL,
});

// ACTION FOR REQUESTING EDIT ON BLOG MODERATION
// RECEVE BLOG ID THAT NEEDS TO BE EDITED AD REMARK MESSAGE AS A PAYLOAD
export const requestForEditBlogModeration = (payload) => ({
  payload,
  type: constants.REQUEST_FOR_EDIT_BLOG_MODERATION,
});
// SUCCESS FOR REQUESTING EDIT ON BLOG MODERATION
// RECEVE SUCCESS STATUS AND THE MESSAGE AS A PAYLOAD
export const requestForEditBlogModerationSuccess = (payload) => ({
  payload,
  type: constants.REQUEST_FOR_EDIT_BLOG_MODERATION_SUCCESS,
});
// FAIL FOR REQUESTING EDIT ON BLOG MODERATION
// RECEVE ERROR STATUS AND THE MESSAGE AS A PAYLOAD
export const requestForEditBlogModerationFail = (payload) => ({
  payload,
  type: constants.REQUEST_FOR_EDIT_BLOG_MODERATION_FAIL,
});

// ACTION FOR ACTIVATING SINGLE BLOG LOADER
export const activateSingleLoader = (payload) => ({
  payload,
  type: constants.ACTIVATE_SINGLE_LOADER,
});
