import constants from "./actionTypes";

// ACTION FOR GETTING ALL LICENCE USERS
// RECIVE FITER DATA FOR THE USERS AS A PYLOAD
export const getLicenceUsers = (payload) => ({
  type: constants.GET_LICENCE_USERS,
  payload,
});

// SUCCESS ACTION FOR GETTING ALL LICENCE USERS
// RECIVE LIST OF ALL USERS FOR THE CURRENT LICENCE FROM THE SERVER AS A PAYLOAD
export const getLicenceUsersSuccess = (payload) => ({
  type: constants.GET_LICENCE_USERS_SUCCESS,
  payload,
});

// ACTION FOR GETTING ALL LICENCE USERS
// RECIVE FITER DATA FOR THE USERS AS A PAYLOAD
export const getAllLicenceUsers = (payload) => ({
  payload,
  type: constants.GET_ALL_LICENCE_USERS,
});

// SUCCESS ACTION FOR GETTING ALL LICENCE USERS
// RECIVE LIST OF ALL USERS FOR THE CURRENT LICENCE FROM THE SERVER AS A PAYLOAD
export const getAllLicenceUsersSuccess = (payload) => ({
  type: constants.GET_ALL_LICENCE_USERS_SUCCESS,
  payload,
});

// FAIL ACTION FOR GETTING ALL LICENCE USERS
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getLicenceUsersFail = (payload) => ({
  type: constants.GET_LICENCE_USERS_FAIL,
  payload,
});

// ACTION FOR GETTING ALL LICENCE USERS FOR ADMIN USER MGM
// RECIVE FITER DATA FOR THE USERS AS A PYLOAD
export const getAllBackendLicenceUsers = (payload) => ({
  type: constants.GET_ALL_BACKEND_LICENCE_USERS,
  payload,
});

// SUCCESS ACTION FOR GETTING ALL LICENCE USERS FOR ADMIN USER MGM
// RECIVE LIST OF ALL USERS FOR THE CURRENT LICENCE FROM THE SERVER AS A PAYLOAD
export const getAllBackendLicenceUsersSuccess = (payload) => ({
  type: constants.GET_ALL_BACKEND_LICENCE_USERS_SUCCESS,
  payload,
});

// FAIL ACTION FOR GETTING ALL LICENCE USERS FOR ADMIN USER MGM
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getAllBackendLicenceUsersFail = (payload) => ({
  type: constants.GET_ALL_BACKEND_LICENCE_USERS_FAIL,
  payload,
});

// ACTION FOR GETTING ALL LICENCE USERS FOR ADMIN USER MGM
// RECIVE FITER DATA FOR THE USERS AS A PYLOAD
export const getAllFrontEndLicenceUsers = (payload) => ({
  type: constants.GET_ALL_FRONT_END_LICENCE_USERS,
  payload,
});

// SUCCESS ACTION FOR GETTING ALL LICENCE USERS FOR ADMIN USER MGM
// RECIVE LIST OF ALL USERS FOR THE CURRENT LICENCE FROM THE SERVER AS A PAYLOAD
export const getAllFrontEndLicenceUsersSuccess = (payload) => ({
  type: constants.GET_ALL_FRONT_END_LICENCE_USERS_SUCCESS,
  payload,
});

// FAIL ACTION FOR GETTING ALL LICENCE USERS FOR ADMIN USER MGM
// RECIVE ERROR STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const getAllFrontEndLicenceUsersFail = (payload) => ({
  type: constants.GET_ALL_FRONT_END_LICENCE_USERS_FAIL,
  payload,
});

// ACTION FOR BLOCKING USER BY ADMIN
// RECIVE USER ID THAT NEEDS TO BE BLOCKED AS A PAYLOAD
export const adminBlockUser = (payload) => ({
  type: constants.ADMIN_BLOCK_USER,
  payload,
});

// SUCCESS ACTION FOR BLOCKING USER BY ADMIN
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminBlockUserSuccess = (payload) => ({
  type: constants.ADMIN_BLOCK_USER_SUCCESS,
  payload,
});

// FAIL ACTION FOR BLOCKING USER BY ADMIN
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminBlockUserFail = (payload) => ({
  type: constants.ADMIN_BLOCK_USER_FAIL,
  payload,
});

// ACTION FOR SETTING PAGINATION FOR ALL BACKEND USERS
// RECIVES HEDERS WITH PAGINATION DATA FROM THE SERVER RESPONSE AS A PAYOAD
export const setPaginationBackendUsers = (payload) => ({
  payload,
  type: constants.SET_PAGINATION_BACKEND,
});

// ACTION FOR SETTING PAGINATION FRO ALL FRONTEND USERS
// RECIVES HEDERS WITH PAGINATION DATA FROM THE SERVER RESPONSE AS A PAYOAD
export const setPaginationFrontEndUsers = (payload) => ({
  payload,
  type: constants.SET_PAGINATION_FRONT_END,
});

// ACTION FOR SETTING PAGINATION FOR ALL LICENCE USERS
// RECEIVES HEADERS WITH PAGINATION DATA FROM THE SERVER RESPONSE AS PAYLOAD
export const setPaginationAllLicenceUsers = (payload) => ({
  payload,
  type: constants.SET_PAGINATION_ALL_LICENCE_USERS,
});

// ACTION FOR RESETING BACKEND USERS
export const resetBackendUsers = (payload) => ({
  payload,
  type: constants.RESET_BACKEND_USERS,
});

// ACTION FOR SETTING PAGINATION FRO ALL FRONTEND USERS
// ACTION FOR RESETING FRONTEND USERS
export const resetFrontEndUsers = (payload) => ({
  payload,
  type: constants.RESET_FRONT_END_USERS,
});

// ACTION FOR RESETING LICENCE USERS
export const resetLicenceUsers = (payload) => ({
  payload,
  type: constants.RESET_LICENCE_USERS,
});

// ACTION FOR CHANGING USER EMAIL
// RECIVE USER EMAIL THAT NEEDS TO BE UPDATED
export const adminChangeUserEmail = (payload) => ({
  type: constants.ADMIN_CHANGE_USER_EMAIL,
  payload,
});

// SUCCESS ACTION FOR CHANGING USER EMAIL
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminChangeUserEmailSuccess = (payload) => ({
  type: constants.ADMIN_CHANGE_USER_EMAIL_SUCCESS,
  payload,
});

// FAIL ACTION FOR CHANGING USER EMAIL
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminChangeUserEmailFail = (payload) => ({
  type: constants.ADMIN_CHANGE_USER_EMAIL_FAIL,
  payload,
});

// ACTION FOR ACTIVATING USER BY THE ADMIN
// RECIVE USER ID THAT NEEDS TO BE ACTIVATED
export const adminActivateUser = (payload) => ({
  type: constants.ADMIN_ACTIVATE_USER,
  payload,
});

// SUCCESS ACTION FOR ACTIVATING USER BY THE ADMIN
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminActivateUserSuccess = (payload) => ({
  type: constants.ADMIN_ACTIVATE_USER_SUCCESS,
  payload,
});

// FAIL ACTION FOR ACTIVATING USER BY THE ADMIN
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminActivateUserFail = (payload) => ({
  type: constants.ADMIN_ACTIVATE_USER_FAIL,
  payload,
});

// ACTION FOR ACTIVATING COMMENT LOADER
export const activateCommentLoader = (payload) => ({
  type: constants.ACTIVATE_COMMENT_LOADER,
  payload,
});

// ACTION FOR CHANGING USER USERNAME
// RECIVE USER USERNAME THAT NEEDS TO BE UPDATED
export const adminChangeUserUsername = (payload) => ({
  type: constants.ADMIN_CHANGE_USER_USERNAME,
  payload,
});

// SUCCESS ACTION FOR CHANGING USER USERNAME
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminChangeUserUsernameSuccess = (payload) => ({
  type: constants.ADMIN_CHANGE_USER_USERNAME_SUCCESS,
  payload,
});

// FAIL ACTION FOR CHANGING USER USERNAME
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminChangeUserUsernameFail = (payload) => ({
  type: constants.ADMIN_CHANGE_USER_USERNAME_FAIL,
  payload,
});

// ACTION FOR UPDATING USER ROLE
// RECIVE USER USERNAME THAT NEEDS TO BE UPDATED
export const adminUpdateUserRole = (payload) => ({
  type: constants.ADMIN_UPDATE_USER_ROLE,
  payload,
});

// SUCCESS ACTION FOR UPDATING USER ROLE
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminUpdateUserRoleSuccess = (payload) => ({
  type: constants.ADMIN_UPDATE_USER_ROLE_SUCCESS,
  payload,
});

// FAIL ACTION FOR UPDATING USER ROLE
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminUpdateUserRoleFail = (payload) => ({
  type: constants.ADMIN_UPDATE_USER_ROLE_FAIL,
  payload,
});

// ACTION FOR CREATING USER BY ADMIN
// RECIVE NEW USER DATA THAT ADMIN ENTERED IN THE FORM
export const adminCreateUser = (payload) => ({
  type: constants.ADMIN_CREATE_USER,
  payload,
});

// SUCCESS ACTION FOR CREATING USER BY ADMIN
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminCreateUserSuccess = (payload) => ({
  type: constants.ADMIN_CREATE_USER_SUCCESS,
  payload,
});

// FAIL ACTION FOR CREATING USER BY ADMIN
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminCreateUserFail = (payload) => ({
  type: constants.ADMIN_CREATE_USER_FAIL,
  payload,
});

// ACTION FOR UPDATING USER DATA BY THE ADMIN
// RECIVE UPDATED USER DATA THAT ADMIN ENTERED IN THE FORM
export const adminUpdateUserData = (payload) => ({
  type: constants.ADMIN_UPDATE_USER_DATA,
  payload,
});

// SUCCESS ACTION FOR UPDATING USER DATA BY THE ADMIN
// RECIVE SUCCESS STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminUpdateUserDataSuccess = (payload) => ({
  type: constants.ADMIN_UPDATE_USER_DATA_SUCCESS,
  payload,
});

// FAIL ACTION FOR UPDATING USER DATA BY THE ADMIN
// RECIVE FAIL STATUS CODE AND MESSAGE FROM THE SERVER AS A PAYLOAD
export const adminUpdateUserDataFail = (payload) => ({
  type: constants.ADMIN_UPDATE_USER_DATA_FAIL,
  payload,
});

// ACTION FOR SETTING NEW USER
// RECIVE NEW USER DATA AS A PAYLOAD
export const setNewUser = (payload) => ({
  type: constants.SET_NEW_USER,
  payload,
});
