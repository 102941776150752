const ADMIN_CERATE_CATEGORIE = "ADMIN_CERATE_CATEGORIE";
const ADMIN_CERATE_CATEGORIE_SUCCESS = "ADMIN_CERATE_CATEGORIE_SUCCESS";
const ADMIN_CERATE_CATEGORIE_FAIL = "ADMIN_CERATE_CATEGORIE_FAIL";
const ADMIN_UPDATE_CATEGORIE = "ADMIN_UPDATE_CATEGORIE";
const ADMIN_UPDATE_CATEGORIE_SUCCESS = "ADMIN_UPDATE_CATEGORIE_SUCCESS";
const ADMIN_UPDATE_CATEGORIE_FAIL = "ADMIN_UPDATE_CATEGORIE_FAIL";
const ADMIN_UPDATE_ATTRIBUTE = "ADMIN_UPDATE_ATTRIBUTE";
const ADMIN_DELETE_CATEGORIE = "ADMIN_DELETE_CATEGORIE";
const ADMIN_DELETE_ATTRIBUTE = "ADMIN_DELETE_ATTRIBUTE";
const ADMIN_DELETE_ATTRIBUTE_CATEGORY_LINK =
  "ADMIN_DELETE_ATTRIBUTE_CATEGORY_LINK";
const GET_LICENCE_CATEGORIES = "GET_LICENCE_CATEGORIES";
const GET_LICENCE_CATEGORIES_SUCCESS = "GET_LICENCE_CATEGORIES_SUCCESS";
const GET_LICENCE_CATEGORIES_FAIL = "GET_LICENCE_CATEGORIES_FAIL";
const GET_LICENCE_ATTRIBUTES = "GET_LICENCE_ATTRIBUTES";
const GET_LICENCE_ATTRIBUTES_SUCCESS = "GET_LICENCE_ATTRIBUTES_SUCCESS";
const GET_LICENCE_ATTRIBUTES_FAIL = "GET_LICENCE_ATTRIBUTES_FAIL";
const GET_CURRENT_CAT_ATTIRBUTES = "GET_CURRENT_CAT_ATTIRBUTES";
const ADD_NEW_CURRENT_CAT_ATTIRBUTE = "ADD_NEW_CURRENT_CAT_ATTIRBUTE";
const UPDATE_CURRENT_CAT_ATTRIBUTE = "UPDATE_CURRENT_CAT_ATTRIBUTE";
const DELETE_CURRENT_CAT_ATTRIBUTE = "DELETE_CURRENT_CAT_ATTRIBUTE";
const RESET_CURRENT_CAT_ATTRIBUTES = "RESET_CURRENT_CAT_ATTRIBUTES";
const SELECTED_CATEGORIE = "SELECTED_CATEGORIE";
const GET_LICENCE_CONTENT_TYPE_IN_ORDER = "GET_LICENCE_CONTENT_TYPE_IN_ORDER";
const GET_LICENCE_CONTENT_TYPE_IN_ORDER_SUCCESS =
  "GET_LICENCE_CONTENT_TYPE_IN_ORDER_SUCCESS";
const GET_LICENCE_CONTENT_TYPE_IN_ORDER_FALI =
  "GET_LICENCE_CONTENT_TYPE_IN_ORDER_FALI";
const SET_SELECTED_CONTENT_TYPE_ORDER = "SET_SELECTED_CONTENT_TYPE_ORDER";
const SET_SELECTED_CONTENT_TYPE_ORDER_SUCCESS =
  "SET_SELECTED_CONTENT_TYPE_ORDER_SUCCESS";
// const SET_SELECTED_CONTENT_TYPE_ORDER_ARTICLE =
//   "SET_SELECTED_CONTENT_TYPE_ORDER_ARTICLE";
const UPDATE_CATEGORIE_ORDER = "UPDATE_CATEGORIE_ORDER";
const UPDATE_CATEGORIE_ORDER_SUCCESS = "UPDATE_CATEGORIE_ORDER_SUCCESS";
const UPDATE_CATEGORIE_ORDER_FAIL = "UPDATE_CATEGORIE_ORDER_FAIL";
const UPDATE_CONTENT_TYPE_ORDER = "UPDATE_CONTENT_TYPE_ORDER";
const UPDATE_CONTENT_TYPE_ORDER_SUCCESS = "UPDATE_CONTENT_TYPE_ORDER_SUCCESS";
const UPDATE_CONTENT_TYPE_ORDER_FALI = "UPDATE_CONTENT_TYPE_ORDER_FALI";
const SET_NEW_CAT = "SET_NEW_CAT";
// const FETCH_CATALOGS_BY_AUTH_CLIENT = "FETCH_CATALOGS_BY_AUTH_CLIENT";
// const FETCH_CATALOGS_BY_AUTH_CLIENT_SUCCESS =
//   "FETCH_CATALOGS_BY_AUTH_CLIENT_SUCCESS";
// const FETCH_CATALOGS_BY_AUTH_CLIENT_FAIL = "FETCH_CATALOGS_BY_AUTH_CLIENT_FAIL";
// const SET_PAGINATION_FOR_AUTH_CLIENT_CATALOGS =
//   "SET_PAGINATION_FOR_AUTH_CLIENT_CATALOGS";

export default {
  ADMIN_CERATE_CATEGORIE,
  ADMIN_CERATE_CATEGORIE_SUCCESS,
  ADMIN_CERATE_CATEGORIE_FAIL,
  ADMIN_UPDATE_CATEGORIE,
  ADMIN_UPDATE_CATEGORIE_SUCCESS,
  ADMIN_UPDATE_CATEGORIE_FAIL,
  ADMIN_UPDATE_ATTRIBUTE,
  ADMIN_DELETE_CATEGORIE,
  ADMIN_DELETE_ATTRIBUTE,
  ADMIN_DELETE_ATTRIBUTE_CATEGORY_LINK,
  RESET_CURRENT_CAT_ATTRIBUTES,
  GET_LICENCE_CATEGORIES,
  GET_LICENCE_CATEGORIES_SUCCESS,
  GET_LICENCE_CATEGORIES_FAIL,
  GET_LICENCE_ATTRIBUTES,
  GET_LICENCE_ATTRIBUTES_SUCCESS,
  GET_LICENCE_ATTRIBUTES_FAIL,
  GET_CURRENT_CAT_ATTIRBUTES,
  ADD_NEW_CURRENT_CAT_ATTIRBUTE,
  UPDATE_CURRENT_CAT_ATTRIBUTE,
  DELETE_CURRENT_CAT_ATTRIBUTE,
  SELECTED_CATEGORIE,
  GET_LICENCE_CONTENT_TYPE_IN_ORDER,
  GET_LICENCE_CONTENT_TYPE_IN_ORDER_SUCCESS,
  GET_LICENCE_CONTENT_TYPE_IN_ORDER_FALI,
  SET_SELECTED_CONTENT_TYPE_ORDER,
  SET_SELECTED_CONTENT_TYPE_ORDER_SUCCESS,
  UPDATE_CATEGORIE_ORDER,
  UPDATE_CATEGORIE_ORDER_SUCCESS,
  UPDATE_CATEGORIE_ORDER_FAIL,
  UPDATE_CONTENT_TYPE_ORDER,
  UPDATE_CONTENT_TYPE_ORDER_SUCCESS,
  UPDATE_CONTENT_TYPE_ORDER_FALI,
  SET_NEW_CAT,
};
