import constants from "./actionTypes";
import { all, takeLatest, put, call } from "redux-saga/effects";
import {
  getUnsplashCollectionPhotosSuccess,
  getUnsplashCollectionsSuccess,
  getUnsplashSearchTermPhotosSuccess,
} from "./actions";
import {
  getUnsplashCollectionPhotosApi,
  getUnsplashSearchParametarPhotosApi,
  getUnsplashCollectionsApi,
  getUnsplashPaginationLinkApi,
} from "../../services/ApiCalls";
import { parseLinkHeader } from "./utills";

// SAGA FOR GETTING UNSPLASH COLLECTIONS
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_UNSPLASH_COLLECTIONS
// PAYLOAD : PAGINATION OBJECT
function* getUnsplashCollectionsSaga(action) {
  try {
    console.log("dosao do getUnsplashCollectionsSaga");

    const unsplashCollections = yield call(
      getUnsplashCollectionsApi,
      action.payload
    );
    // const headerLinks = parseLinkHeader(unsplashCollections.headers.link);
    // const headerLinks = parseLinkHeader(unsplashCollections.data[0].links);
    console.log(unsplashCollections, "UNSPLASHCOLECTION");
    const payload = {
      collections: unsplashCollections.data,
      // pagination: headerLinks,
    };

    yield put(getUnsplashCollectionsSuccess(payload));
    // console.log(payload, "payload iz successa");
    // console.log("headerLinks", headerLinks);
  } catch (e) {
    console.log("error", e);
  }
}
// SAGA FOR GETTING UNSPLASH COLLECTION PHOTOS
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_UNSPLASH_COLLECTION_PHOTOS
// PAYLOAD : PAGINATION OBJECT AND COLLECTION ID
function* getUnsplashCollectionPhotosSaga(action) {
  try {
    const unsplashCollectionPhotos = yield call(
      getUnsplashCollectionPhotosApi,
      action.payload
    );
    const headerLinks = parseLinkHeader(unsplashCollectionPhotos.headers.link);

    const payload = {
      collections: unsplashCollectionPhotos.data,
      pagination: headerLinks,
    };
    yield put(getUnsplashCollectionPhotosSuccess(payload));

    // console.log("unsplashCollectionPhotos", unsplashCollectionPhotos);
  } catch (e) {
    console.log("error", e);
  }
}
// SAGA FOR GETTING UNSPLASH COLLECTION PHOTOS
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_SEARCH_TERM_PHOTOS
// PAYLOAD : PAGINATION OBJECT AND SEARCHED TERM
function* getUnsplashSearchTermPhotosSaga(action) {
  try {
    const searchTermPhotos = yield call(
      getUnsplashSearchParametarPhotosApi,
      action.payload
    );
    const headerLinks = parseLinkHeader(searchTermPhotos.headers.link);

    const payload = {
      collections: searchTermPhotos.data.results,
      pagination: headerLinks,
    };
    yield put(getUnsplashSearchTermPhotosSuccess(payload));

    // console.log("payload", payload);
    // console.log("searchTermPhotos.data", searchTermPhotos.data);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA FOR GETTING UNSPLASH COLLECTION PHOTOS
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.GET_UNSPLASH_PAGINATION_LINK
// PAYLOAD : PAGINATION LINK AND CONTENT(COLLECTION OR IMAGE) NAME
function* getUnsplashPaginationLinkContentSaga(action) {
  try {
    const paginationLink = yield call(
      getUnsplashPaginationLinkApi,
      action.payload
    );
    const headerLinks = parseLinkHeader(paginationLink.headers.link);

    const payload = {
      collections: paginationLink.data.results
        ? paginationLink.data.results
        : paginationLink.data,
      pagination: headerLinks,
    };
    if (action.payload.contentName === "collections") {
      yield put(getUnsplashCollectionsSuccess(payload));
    } else {
      yield put(getUnsplashCollectionPhotosSuccess(payload));
    }
    // console.log("paginationLink", paginationLink);
  } catch (e) {
    console.log("error", e);
  }
}

function* stockPhotosSaga() {
  yield all([
    takeLatest(constants.GET_UNSPLASH_COLLECTIONS, getUnsplashCollectionsSaga),
    takeLatest(
      constants.GET_UNSPLASH_PAGINATION_LINK,
      getUnsplashPaginationLinkContentSaga
    ),
    takeLatest(
      constants.GET_UNSPLASH_COLLECTION_PHOTOS,
      getUnsplashCollectionPhotosSaga
    ),
    takeLatest(
      constants.GET_SEARCH_TERM_PHOTOS,
      getUnsplashSearchTermPhotosSaga
    ),
  ]);
}

export default stockPhotosSaga;
