const FETCH_PROFILE = "FETCH_PROFILE";
const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
const FETCH_PROFILE_FAIL = "FETCH_PROFILE_FAIL";
const FETCH_PROFILE_CATALOGS_PRODUCTS = "FETCH_PROFILE_CATALOGS_PRODUCTS";
const FETCH_PROFILE_CATALOGS_PRODUCTS_SUCCESS =
  "FETCH_PROFILE_CATALOGS_PRODUCTS_SUCCESS";
const FETCH_PROFILE_CATALOGS_PRODUCTS_FAIL =
  "FETCH_PROFILE_CATALOGS_PRODUCTS_FAIL";
const SET_PAGINATION = "SET_PAGINATION";
const FETCH_PROFILE_BLOGS = "FETCH_PROFILE_BLOGS";
const FETCH_PROFILE_BLOGS_SUCCESS = "FETCH_PROFILE_BLOGS_SUCCESS";
const FETCH_PROFILE_BLOGS_FAIL = "FETCH_PROFILE_BLOGS_FAIL";
const SET_PAGINATION_BLOGS = "SET_PAGINATION_BLOGS";
const LICENCE_CLIENT_UPDATE_PROFILE = "LICENCE_CLIENT_UPDATE_PROFILE";
const LICENCE_CLIENT_UPDATE_PROFILE_SUCCESS =
  "LICENCE_CLIENT_UPDATE_PROFILE_SUCCESS";
const LICENCE_CLIENT_UPDATE_PROFILE_FAIL = "LICENCE_CLIENT_UPDATE_PROFILE_FAIL";
const LICENCE_USER_UPDATE_PROFILE = "LICENCE_USER_UPDATE_PROFILE";
const LICENCE_USER_UPDATE_PROFILE_SUCCESS =
  "LICENCE_USER_UPDATE_PROFILE_SUCCESS";
const LICENCE_USER_UPDATE_PROFILE_FAIL = "LICENCE_USER_UPDATE_PROFILE_FAIL";
const LICENCE_USERS_UPDATE_PASSWORD = "LICENCE_USERS_UPDATE_PASSWORD";
const LICENCE_USERS_UPDATE_PASSWORD_SUCCESS =
  "LICENCE_USERS_UPDATE_PASSWORD_SUCCESS";
const LICENCE_USERS_UPDATE_PASSWORD_FAIL = "LICENCE_USERS_UPDATE_PASSWORD_FAIL";
const UPDATE_USER_PROFILE_NAME = "UPDATE_USER_PROFILE_NAME";
const UPDATE_USER_PROFILE_NAME_SUCCESS = "UPDATE_USER_PROFILE_NAME_SUCCESS";
const UPDATE_USER_PROFILE_NAME_FAIL = "UPDATE_USER_PROFILE_NAME_FAIL";
export default {
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAIL,
  FETCH_PROFILE_CATALOGS_PRODUCTS,
  FETCH_PROFILE_CATALOGS_PRODUCTS_SUCCESS,
  FETCH_PROFILE_CATALOGS_PRODUCTS_FAIL,
  SET_PAGINATION,
  FETCH_PROFILE_BLOGS,
  FETCH_PROFILE_BLOGS_SUCCESS,
  FETCH_PROFILE_BLOGS_FAIL,
  LICENCE_CLIENT_UPDATE_PROFILE,
  LICENCE_CLIENT_UPDATE_PROFILE_SUCCESS,
  LICENCE_CLIENT_UPDATE_PROFILE_FAIL,
  LICENCE_USER_UPDATE_PROFILE,
  LICENCE_USER_UPDATE_PROFILE_SUCCESS,
  LICENCE_USER_UPDATE_PROFILE_FAIL,
  LICENCE_USERS_UPDATE_PASSWORD,
  LICENCE_USERS_UPDATE_PASSWORD_SUCCESS,
  LICENCE_USERS_UPDATE_PASSWORD_FAIL,
  SET_PAGINATION_BLOGS,
  UPDATE_USER_PROFILE_NAME,
  UPDATE_USER_PROFILE_NAME_SUCCESS,
  UPDATE_USER_PROFILE_NAME_FAIL,
};
