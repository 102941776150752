const INIT_CHECKOUT_SALES_SITE = "INIT_CHECKOUT_SALES_SITE";
const INIT_CHECKOUT_SALES_SITE_SUCCESS = "INIT_CHECKOUT_SALES_SITE_SUCCESS";
const INIT_CHECKOUT_SALES_SITE_FAIL = "INIT_CHECKOUT_SALES_SITE_FAIL";
const GET_TRANSACTION_DATA = "GET_TRANSACTION_DATA";
const GET_TRANSACTION_DATA_SUCCESS = "GET_TRANSACTION_DATA_SUCCESS";
const GET_TRANSACTION_DATA_FAIL = "GET_TRANSACTION_DATA_FAIL";
const SEND_PAYMENT_CONFIRMATION_MAIL = "SEND_PAYMENT_CONFIRMATION_MAIL";
const GET_PAYMENT_MAIL_SUCCESS = "GET_PAYMENT_MAIL_SUCCESS";

export default {
  INIT_CHECKOUT_SALES_SITE,
  INIT_CHECKOUT_SALES_SITE_SUCCESS,
  INIT_CHECKOUT_SALES_SITE_FAIL,
  GET_TRANSACTION_DATA,
  GET_TRANSACTION_DATA_SUCCESS,
  GET_TRANSACTION_DATA_FAIL,
  SEND_PAYMENT_CONFIRMATION_MAIL,
  GET_PAYMENT_MAIL_SUCCESS,
};
