import constants from "./actionTypes";

const initState = {
  loading: false,
  authClientCatalogs: [],
  authClientCatalogsPagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
  licenceCatalogsNoPag: [],
  catalog: [],
  catalogProducts: [],
  catalogLoader: false,
  catalogProductsLoader: false,
  catalogProductsPagination: {
    currentPage: "",
    pageCount: "",
    perPage: "",
    totlCount: "",
  },
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case constants.LICENCE_USER_CREATE_CATALOG:
      return {
        ...state,
        loading: true,
      };
    case constants.ACTIVATE_CATALOG_LOADER:
      return {
        ...state,
        loading: true,
      };
    case constants.SAVE_CATALOG_AS_DRAFT:
      return {
        ...state,
        loading: true,
      };
    case constants.LICENCE_USER_UPDATE_CATALOG:
      return {
        ...state,
        loading: true,
      };
    case constants.DISABLE_CATALOG_BY_LICENCE_CLIENT:
      return {
        ...state,
        loading: true,
      };
    case constants.DISABLE_CATALOG_BY_LICENCE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case constants.SET_PAGINATION_FOR_AUTH_CLIENT_CATALOGS:
      return {
        ...state,
        authClientCatalogsPagination: payload,
      };
    case constants.GET_USER_CATALOGS_NO_PAGINATION_SUCCESS:
      return {
        ...state,
        licenceCatalogsNoPag: payload,
      };
    // TRIGGER PRODUCT FETCHING BY AUTH CLIENT
    case constants.FETCH_CATALOGS_BY_AUTH_CLIENT:
      return {
        ...state,
        loading: true,
      };
    // SET AUTH CLIENT PRODUCTS
    case constants.FETCH_CATALOGS_BY_AUTH_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        authClientCatalogs: payload,
      };

    case constants.FETCH_CATALOG_BY_SLUG:
      return {
        ...state,
        catalogLoader: true,
      };

    case constants.FETCH_CATALOG_BY_SLUG_SUCCESS:
      return {
        ...state,
        catalogLoader: false,
        catalog: payload,
      };

    case constants.FETCH_CATALOG_BY_SLUG_FAIL:
      return {
        ...state,
        catalogLoader: false,
      };

    case constants.FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID:
      return {
        ...state,
        catalogProductsLoader: true,
      };

    case constants.FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID_SUCCESS:
      return {
        ...state,
        catalogProductsLoader: false,
        catalogProducts: payload,
      };

    case constants.FETCH_CATALOG_PRODUCTS_BY_CATALOG_ID_FAIL:
      return {
        ...state,
        catalogProductsLoader: false,
      };

    case constants.SET_PAGINATION_FOR_CATALOG_PRODUCTS:
      return {
        ...state,
        catalogProductsPagination: payload,
      };

    default:
      return state;
  }
};
