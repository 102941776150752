import constants from './actionTypes'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  getLicenceDetailsApi,
  getModerationNumbersApi,
  changleLoginRegistrationStatusApi,
  changeSearchStatusApi,
} from '../../services/ApiCalls'
import {
  getLicenceDetailsSuccess,
  getModerationNumbersSuccess,
  getLicenceDetails,
} from './actions'
import { NotificationManager } from 'react-notifications'

// SAGA FOR HANDLING LICENCE DETAILS API CALLS
// RECIVES TYPE AND PAYLOAD  FROM ACTION
// TYPE : constants.GET_LICENCE_DETAILS
// PAYLOAD : LICENCE DETAILS THAT NEEDS TO BE FETCHED
function* getLIcenceDetailsSaga(action) {
  try {
    const licenceDetails = yield call(getLicenceDetailsApi, action.payload)
    // console.log(licenceDetails, "licenceDetails");
    yield put(getLicenceDetailsSuccess(licenceDetails))
  } catch (e) {
    console.log(e.message)
  }
}

// SAGA FOR HANDLING GETTINGS ADMIN MODERATION NUMBERS
// RECIVES TYPE FROM ACTION
// TYPE : constants.GET_MODERATION_NUMBERS
function* getModerationNumbersSaga(action) {
  try {
    const moderationNumbers = yield call(getModerationNumbersApi)
    // console.log(licenceDetails, "licenceDetails");
    yield put(getModerationNumbersSuccess(moderationNumbers))
  } catch (e) {
    console.log(e.message)
  }
}

// SAGA FOR CHANGING LOGIN AND REGISTRATION STATUS
// RECIVES TYPE AND   FROM ACTION
// TYPE : constants.CHANGE_LOGIN_REGISTRATION_STATUS
function* changleLoginRegistrationStatusSaga(action) {
  try {
    const status = yield call(changleLoginRegistrationStatusApi)
    const payload = [
      'terms_and_conditions',
      'sign_in_msg',
      'register_msg',
      'client_register_msg',
      'bidding_duration',
      'search_status',
      'login_registration_status',
    ]
    // console.log(licenceDetails, "licenceDetails");
    yield put(getLicenceDetails(payload))
    NotificationManager.success(status.message, '', 3000)
  } catch (e) {
    console.log(e.message)
  }
}

// SAGA FOR CHANGING SEARCH STATUS
// RECIVES TYPE AND   FROM ACTION
// TYPE : constants.CHANGE_SEARCH_STATUS
function* changeSearchStatusSaga(action) {
  try {
    const status = yield call(changeSearchStatusApi)
    const payload = [
      'terms_and_conditions',
      'sign_in_msg',
      'register_msg',
      'client_register_msg',
      'bidding_duration',
      'search_status',
      'login_registration_status',
    ]
    // console.log(licenceDetails, "licenceDetails");
    yield put(getLicenceDetails(payload))
    NotificationManager.success(status.message, '', 3000)
  } catch (e) {
    console.log(e.message)
  }
}

function* layoutSaga() {
  yield all([
    takeLatest(constants.GET_LICENCE_DETAILS, getLIcenceDetailsSaga),
    takeLatest(constants.GET_MODERATION_NUMBERS, getModerationNumbersSaga),
    takeLatest(constants.CHANGE_SEARCH_STATUS, changeSearchStatusSaga),
    takeLatest(
      constants.CHANGE_LOGIN_REGISTRATION_STATUS,
      changleLoginRegistrationStatusSaga
    ),
  ])
}

export default layoutSaga
