import constants from "./actionTypes";

// INITIAL STATE OF THIS REDUCER
const initState = {
  loader: false,
  singleArticle: {},
};

// REDUCER FUNCTION
export default (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case constants.FETCH_SINGLE_ARTICLE:
      return {
        ...state,
        loader: true,
      };

    case constants.FETCH_SINGLE_ARTICLE_SUCCESS:
      return {
        ...state,
        singleArticle: payload,
        loader: false,
      };
    case constants.ACTIVATE_SINGLE_ARTICLE_LOADER:
      return {
        ...state,
        loader: true,
      };
    case constants.FETCH_SINGLE_ARTICLE_FAIL:
      return {
        ...state,
        singleArticle: {},
        loader: false,
      };

    //  IF NONE OF THE CASE ABOVE IS TRIGGERED RETURN DEFAULT STATE
    default:
      return {
        ...state,
      };
  }
};
