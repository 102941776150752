import constants from "./actionTypes";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  getSingleArticleSlugApi,
  createArticleDraftApi,
  updateArticleDraftApi,
  adminCreateArticleApi,
  adnimUpdateArticleApi,
  adminDeleteArticleApi,
  publishArticleDraftApi,
} from "../../services/ApiCalls";
import { fetchSingleArticleSuccess } from "./actions";
import {
  getAuthUserArticles,
  activateArticlesLoader,
  // getSingleArticleFail,
  // getSingleArticleSuccess,
} from "../article/actions";
import { NotificationManager } from "react-notifications";

function* fetchSingleArticleSaga(action) {
  try {
    const singleArticle = yield call(getSingleArticleSlugApi, action.payload);
    yield put(fetchSingleArticleSuccess(singleArticle.data[0]));
  } catch (e) {
    console.log(e);
  }
}

// SAGA FOR CREATING ARTICLE DRAFT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.CREATE_ARTICLE_DRAFT
// PAYLOAD : NEW ARTICLE DRAFT DATA
function* createArticleDraftSaga(action) {
  try {
    const state = yield select();
    // console.log("dosao", action.payload);
    yield put(activateArticlesLoader());

    yield call(createArticleDraftApi, action.payload);
    NotificationManager.success(
      state.translation.messages.saveAsDraftArticleSuccessMessage[1],
      "",
      3000
    );
    // const payload = {
    //   author_id: action.payload.userId,
    //   content_type_id: 3,
    //   page: 1,
    // };
    // yield put(fetchBlogsByAuthUser(payload));
    const payload = {
      content_type_id: 2,
      page: 1,
    };
    yield put(getAuthUserArticles(payload));

    // console.log("articleDraft", articleDraft);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA FOR UPDATING ARTICLE DRAFT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.UPDATE_ARTICLE_DRAFT
// PAYLOAD : ARTICLE DRAFT DATA THAT NEEDS TO BE UPDATED
function* updateArticleDraftSaga(action) {
  try {
    const state = yield select();
    // console.log("dosao", action.payload);
    yield put(activateArticlesLoader());

    yield call(updateArticleDraftApi, action.payload);
    NotificationManager.success(
      state.translation.messages.updateArticleSuccessMessage[1],
      "",
      3000
    );
    // const payload = {
    //   author_id: action.payload.userId,
    //   content_type_id: 3,
    //   page: 1,
    // };
    // yield put(fetchBlogsByAuthUser(payload));
    const payload = {
      content_type_id: 2,
      page: 1,
    };
    yield put(getAuthUserArticles(payload));
    // console.log("updateDraft", updateDraft);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA FOR HANDLING ARTICLE CREATION BY ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_CREATE_ARTICLE
// PAYLOAD : NEW ARTICLE DATA THAT ADMIN ENTERED IN THE FORM
function* adminCreateArticleSaga(action) {
  try {
    const state = yield select();
    yield put(activateArticlesLoader());

    yield call(adminCreateArticleApi, action.payload);
    NotificationManager.success(
      state.translation.messages.createArticleSuccessMessage[1],
      "",
      3000
    );
    const payload = {
      content_type_id: 2,
      page: 1,
    };
    yield put(getAuthUserArticles(payload));

    // console.log("licenceUserBlog", licenceUserBlog);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA TO UPDATE ARTICLE BY ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_UPDATE_ARTICLE
// PAYLOAD : UPDATED ARTICLE DATA THAT ADMIN ENTERED IN THE FORM
function* adminUpdateArticleSaga(action) {
  try {
    // console.log("action.payload", action.payload);
    // yield put(activateArticlesLoader());
    const state = yield select();
    const updatedBlog = yield call(adnimUpdateArticleApi, action.payload);
    NotificationManager.success(
      state.translation.messages.updateArticleSuccessMessage[1],
      "",
      3000
    );
    if (action.payload.fetchSingleContent) {
      // yield put(activateSingleLoader());
      yield put(fetchSingleArticleSuccess(updatedBlog));
      NotificationManager.success(action.payload.message, "", 3000);
    } else {
      yield put(activateArticlesLoader());

      const payload = {
        content_type_id: 2,
        page: 1,
      };
      yield put(getAuthUserArticles(payload));
    }
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA FOR DELETING ARTICLE BY ADMIN
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.ADMIN_DELETE_ARTICLE
// PAYLOAD : ARTICLE ID THAT NEEDS TO BE DELETED
function* adminDeleteArticleSaga(action) {
  try {
    yield put(activateArticlesLoader());
    // console.log("action.payload", action.payload);
    yield call(adminDeleteArticleApi, action.payload);
    const payload = {
      content_type_id: 2,
      page: 1,
    };
    yield put(getAuthUserArticles(payload));

    // console.log("deletedArticle", deletedArticle);
  } catch (e) {
    console.log("error", e);
  }
}

// SAGA FOR PUBLISHING ARTICLE DRAFT
// RECIVES TYPE AND PAYLOAD FROM ACTION
// TYPE : constants.PUBLISH_ARTICLE_DRAFT
// PAYLOAD : ARTICLE DATA THAT LICENCE USER WHAT TO PUBLISH
function* publishArticleDraftSaga(action) {
  try {
    yield put(activateArticlesLoader());

    // console.log("Dosao ovde");
    yield call(updateArticleDraftApi, action.payload);
    yield call(publishArticleDraftApi, action.payload);
    const payload = {
      content_type_id: 2,
      page: 1,
    };
    yield put(getAuthUserArticles(payload));
  } catch (e) {
    console.log("error", e);
  }
}

// COMBINE ALL OF THE FUNCTION ABOVE IN ONE FUNCTION
function* singleArticleSaga() {
  yield all([
    takeLatest(constants.FETCH_SINGLE_ARTICLE, fetchSingleArticleSaga),
    takeLatest(constants.CREATE_ARTICLE_DRAFT, createArticleDraftSaga),
    takeLatest(constants.UPDATE_ARTICLE_DRAFT, updateArticleDraftSaga),
    takeLatest(constants.ADMIN_UPDATE_ARTICLE, adminUpdateArticleSaga),
    takeLatest(constants.ADMIN_CREATE_ARTICLE, adminCreateArticleSaga),
    takeLatest(constants.ADMIN_DELETE_ARTICLE, adminDeleteArticleSaga),
    takeLatest(constants.PUBLISH_ARTICLE_DRAFT, publishArticleDraftSaga),
  ]);
}

// EXPORT THAT FUNCTION AS A DEFAULT EXPORT
export default singleArticleSaga;
