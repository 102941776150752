import constants from "./actionTypes";

const initState = {
  useLink: true,
};

export default (state = initState, action) => {
  const { payload, type } = action;
  switch (type) {
    case constants.SET_USE_LINK:
      return {
        ...state,
        useLink: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
