import constants from "./actionTypes";

const initState = {
  planners: [],
  publicPlannerItems: [],
  error: "",
  showPlannerOptions: {},
  loading: false,
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    // TRIGGR PLANNER UPDATE AND ACTIVATE LOADER
    case constants.UPDATE_PLANNER:
      return {
        ...state,
        loading: true,
      };
    // TRIGGR FETCHING ALL PLANNERS FROM SERVER AND ACTIVATING LOADER
    case constants.GET_ALL_PLANNERS:
      return {
        ...state,
        loading: true,
      };
    // SETTING PLANNERS TO BE THE DATA THAT IS RECIVED FROM THE PAYLOAD AND CLOSING THE LOADER
    case constants.GET_ALL_PLANNERS_SUCCESS:
      return {
        ...state,
        planners: payload,
        loading: false,
      };
    // SETTING ERROR TO BE THE ERROR MESSAGE THAT IS RECIVED FROM THE SERVER AND CLOSING THE LOADER
    case constants.GET_ALL_PLANNERS_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    // // SETTING THE DEFAULT OBJECT FOR CONTROLING THE PLANNER OPTIONS DISPLAY THAT IS CONSTRUCT IN SAGA
    // case constants.SET_SHOW_PLANNER_OPTIONS:
    //   return {
    //     ...state,
    //     showPlannerOptions: payload,
    //   };
    // TRIGGER PLANNER DELETE AND ACTIVATE THE LOADER
    case constants.DELETE_PLANNER:
      return {
        ...state,
        loading: true,
      };
    // // REMOVING THE DELETE PLANNER FROM THE STATE AND CLOSING THE LOADER
    case constants.DELETE_PLANNER_SUCCESS:
      return {
        ...state,
        planners: state.planners.filter((planner) => planner.id !== payload),
        loading: false,
      };
    // TRIGGR ADDING NEW PLANNER  AND ACTIVATING LOADER
    case constants.ADD_NEW_PLANNER:
      return {
        ...state,

        loading: true,
      };
    // DEACTIVATE LOADER
    case constants.ADD_NEW_PLANNER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case constants.GET_PROFILE_PUBLIC_PLANNERS_SUCCESS:
      return {
        ...state,
        publicPlannerItems: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
