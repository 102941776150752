import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// const config = {
//   apiKey: "AIzaSyCzZzvPEOpliZbSlyH9lPODebzPlBXX-v0",
//   authDomain: "szs-firebase-test.firebaseapp.com",
//   databaseURL: "https://szs-firebase-test.firebaseio.com",
//   projectId: "szs-firebase-test",
//   storageBucket: "szs-firebase-test.appspot.com",
//   messagingSenderId: "5848604480",
//   appId: "1:5848604480:web:ff3f739044dbe4fa54e4a5",
//   measurementId: "G-FFGR8PMC1R",
// };

// const config = {
//   apiKey: "AIzaSyALt5p8558YaCIK7Hn-8dS0j4Ns8IIMcxQ",
//   authDomain: "mobileappwebsite-a9042.firebaseapp.com",
//   projectId: "mobileappwebsite-a9042",
//   storageBucket: "mobileappwebsite-a9042.appspot.com",
//   messagingSenderId: "624737695441",
//   appId: "1:624737695441:web:c0fdd44f95e110c940f7f0",
//   measurementId: "G-GZL8LGLJ2Y",
// };

const config = {
  apiKey: 'AIzaSyDkgo96cd-OwtNV-QXLXOh6__h-dVGhvUg',
  authDomain: 'autosverc-1a273.firebaseapp.com',
  projectId: 'autosverc-1a273',
  storageBucket: 'autosverc-1a273.appspot.com',
  messagingSenderId: '760972141665',
  appId: '1:760972141665:web:e83df536f043772fc83620',
  measurementId: 'G-V74ESV2CFY',
}

firebase.initializeApp(config)

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return

  const userRef = firestore.doc(`users/${userAuth.uid}`)

  const snapShot = await userRef.get()

  if (!snapShot.exists) {
    const { displayName, email } = userAuth
    const createdAt = new Date()
    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData,
      })
    } catch (error) {
      console.log('error creating user', error.message)
    }
  }

  return userRef
}

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd
) => {
  const collectionRef = firestore.collection(collectionKey)

  const batch = firestore.batch()
  objectsToAdd.forEach((obj) => {
    const newDocRef = collectionRef.doc()
    batch.set(newDocRef, obj)
  })

  return await batch.commit()
}

export const convertCollectionsSnapshotToMap = (collections) => {
  const transformedCollection = collections.docs.map((doc) => {
    const { title, items } = doc.data()

    return {
      routeName: encodeURI(title.toLowerCase()),
      id: doc.id,
      title,
      items,
    }
  })

  return transformedCollection.reduce((accumulator, collection) => {
    accumulator[collection.title.toLowerCase()] = collection
    return accumulator
  }, {})
}

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe()
      resolve(userAuth)
    }, reject)
  })
}

export const auth = firebase.auth()

export const firestore = firebase.firestore()

export const googleProvider = new firebase.auth.GoogleAuthProvider()
export const facebookProvider = new firebase.auth.FacebookAuthProvider()
googleProvider.setCustomParameters({ prompt: 'select_account' })
facebookProvider.setCustomParameters({ prompt: 'select_account' })
export const signInWithFacebook = () => auth.signInWithPopup(facebookProvider)
export const signInWithGoogle = () => auth.signInWithPopup(googleProvider)

export default firebase
