const LOGIN_USER = "LOGIN_USER";
const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
const LOGIN_LICENCE_USER = "LOGIN_LICENCE_USER";
const LOGIN_LICENCE_USER_SUCCESS = "LOGIN_LICENCE_USER_SUCCESS";
const LOGIN_LICENCE_USER_FAIL = "LOGIN_LICENCE_USER_FAIL";
const LOGIN_SALES_USER = "LOGIN_SALES_USER";
const LOGIN_SALES_USER_SUCCESS = "LOGIN_SALES_USER_SUCCESS";
const LOGIN_SALES_USER_FAIL = "LOGIN_SALES_USER_FAIL";
const REGISTER_LICENCE_USER = "REGISTER_LICENCE_USER";
const REGISTER_LICENCE_USER_SUCCESS = "REGISTER_LICENCE_USER_SUCCESS";
const REGISTER_LICENCE_USER_FAIL = "REGISTER_LICENCE_USER_FAIL";
const REGISTER_LICENCE_CLIENT = "REGISTER_LICENCE_CLIENT";
const REGISTER_LICENCE_CLIENT_SUCCESS = "REGISTER_LICENCE_CLIENT_SUCCESS";
const REGISTER_LICENCE_CLIENT_FAIL = "REGISTER_LICENCE_CLIENT_FAIL";
const REGISTER_SALES_USER = "REGISTER_SALES_USER";
const REGISTER_SALES_USER_SUCCESS = "REGISTER_SALES_USER_SUCCESS";
const REGISTER_SALES_USER_FAIL = "REGISTER_SALES_USER_FAIL";
const GET_AUTH_USER = "GET_AUTH_USER";
const GET_AUTH_USER_SUCCESS = "GET_AUTH_USER_SUCCESS";
const GET_AUTH_USER_FAIL = "GET_AUTH_USER_FAIL";
const GET_LICENCE_TEMPLATE = "GET_LICENCE_TEMPLATE";
const GET_LICENCE_TEMPLATE_SUCCESS = "GET_LICENCE_TEMPLATE_SUCCESS";
const GET_LICENCE_TEMPLATE_FAIL = "GET_LICENCE_TEMPLATE_FAIL";
const GET_LICENCE_LANGUAGE = "GET_LICENCE_LANGUAGE";
const GET_LICENCE_LANGUAGE_SUCCESS = "GET_LICENCE_LANGUAGE_SUCCESS";
const GET_LICENCE_LANGUAGE_FAIL = "GET_LICENCE_LANGUAGE_FAIL";
const LOGOUT_USER = "LOGOUT_USER";
const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
const LOGOUT_USER_FAIL = "LOGOUT_USER_FAIL";
const SIGN_IN_WITH_GOOGLE = "SIGN_IN_WITH_GOOGLE";
const SIGN_IN_WITH_FACEBOOK = "SIGN_IN_WITH_FACEBOOK";
const SOCIAL_AUTH_LOGIN_USER = "SOCIAL_AUTH_LOGIN_USER";
const SOCIAL_AUTH_LOGIN_USER_SUCCESS = "SOCIAL_AUTH_LOGIN_USER_SUCCESS";
const SOCIAL_AUTH_LOGIN_USER_FAIL = "SOCIAL_AUTH_LOGIN_USER_FAIL";
const REQUESET_PASSWORD_RESET = "REQUESET_PASSWORD_RESET";
const REQUESET_PASSWORD_RESET_SUCCESS = "REQUESET_PASSWORD_RESET_SUCCESS";
const REQUESET_PASSWORD_RESET_FAIL = "REQUESET_PASSWORD_RESET_FAIL";
const RESET_PASSWORD = "RESET_PASSWORD";
const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
const VALIDATE_USER_ACCOUNT = "VALIDATE_USER_ACCOUNT";
const VALIDATE_USER_ACCOUNT_SUCCESS = "VALIDATE_USER_ACCOUNT_SUCCESS";
const VALIDATE_USER_ACCOUNT_FAIL = "VALIDATE_USER_ACCOUNT_FAIL";

export default {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  REGISTER_LICENCE_USER,
  REGISTER_LICENCE_USER_SUCCESS,
  REGISTER_LICENCE_USER_FAIL,
  REGISTER_LICENCE_CLIENT,
  REGISTER_LICENCE_CLIENT_SUCCESS,
  REGISTER_LICENCE_CLIENT_FAIL,
  LOGIN_LICENCE_USER,
  LOGIN_LICENCE_USER_SUCCESS,
  LOGIN_LICENCE_USER_FAIL,
  GET_AUTH_USER,
  GET_AUTH_USER_SUCCESS,
  GET_AUTH_USER_FAIL,
  GET_LICENCE_TEMPLATE,
  GET_LICENCE_TEMPLATE_SUCCESS,
  GET_LICENCE_TEMPLATE_FAIL,
  GET_LICENCE_LANGUAGE,
  GET_LICENCE_LANGUAGE_SUCCESS,
  GET_LICENCE_LANGUAGE_FAIL,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAIL,
  SIGN_IN_WITH_GOOGLE,
  SIGN_IN_WITH_FACEBOOK,
  SOCIAL_AUTH_LOGIN_USER,
  SOCIAL_AUTH_LOGIN_USER_SUCCESS,
  SOCIAL_AUTH_LOGIN_USER_FAIL,
  LOGIN_SALES_USER,
  LOGIN_SALES_USER_SUCCESS,
  LOGIN_SALES_USER_FAIL,
  REGISTER_SALES_USER,
  REGISTER_SALES_USER_SUCCESS,
  REGISTER_SALES_USER_FAIL,
  REQUESET_PASSWORD_RESET,
  REQUESET_PASSWORD_RESET_SUCCESS,
  REQUESET_PASSWORD_RESET_FAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  VALIDATE_USER_ACCOUNT,
  VALIDATE_USER_ACCOUNT_SUCCESS,
  VALIDATE_USER_ACCOUNT_FAIL,
};
